var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WelcomeHeader',{attrs:{"title":_vm.title}},[_vm._v("\n    Isso nos ajudará a entender melhor o seu perfil.\n  ")]),_vm._v(" "),(_vm.exam === 'CONCURSO')?_c('VTextField',{staticClass:"mt-10",attrs:{"rounded":"","outlined":"","hide-details":"","autofocus":"","label":"Nome do concurso","placeholder":"Insira o nome do concurso"},model:{value:(_vm.concursoName),callback:function ($$v) {_vm.concursoName=$$v},expression:"concursoName"}}):_vm._e(),_vm._v(" "),(_vm.exam === 'OUTRO_VESTIBULAR')?_c('VTextField',{staticClass:"mt-10",attrs:{"rounded":"","outlined":"","hide-details":"","autofocus":"","label":"Vestibular","placeholder":"Insira o vestibular"},model:{value:(_vm.vestibularName),callback:function ($$v) {_vm.vestibularName=$$v},expression:"vestibularName"}}):_vm._e(),_vm._v(" "),(_vm.exam === 'ENEM')?_c('Chooser',{staticClass:"mt-4",attrs:{"options":[
      {
        value: 'Menos de 500',
        name: 'Menos de 500'
      },
      {
        value: 'De 500 a 600',
        name: 'De 500 a 600'
      },
      {
        value: 'De 600 a 700',
        name: 'De 600 a 700'
      },
      {
        value: 'De 700 a 800',
        name: 'De 700 a 800'
      },
      {
        value: 'Mais de 800',
        name: 'Mais de 800'
      },
      {
        value: 'Prefiro não informar',
        name: 'Prefiro não informar'
      }
    ]},model:{value:(_vm.lastEnemGrade),callback:function ($$v) {_vm.lastEnemGrade=$$v},expression:"lastEnemGrade"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }