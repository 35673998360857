<template>
  <VDialog
    v-model="dialog"
    fullscreen
    max-width="800"
    no-click-animation
    persistent
    scrollable
    transition="dialog-bottom-transition"
  >
    <div v-if="dialog" class="checkout-modal">
      <div class="checkout-modal__close" @click="closeModal">
        <VBtn outlined small class="white" icon>
          <VIcon size="20">{{ $icons.close }}</VIcon>
        </VBtn>
      </div>

      <div class="checkout-modal-container">
        <CheckoutPage @success="dialog = false" />
      </div>
    </div>
  </VDialog>
</template>

<script>
import CheckoutPage from './Page.vue'
import { sendPlausibleEvent } from '../../utils/plausible'

export default {
  components: {
    CheckoutPage,
  },

  data() {
    return {
      dialog: false,
      loading: true,
      activePlan: 0,
      couponOpen: false,
      couponApplied: false,
      couponLoading: false,
      coupon: '',
      plans: [],
      swiperOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },

  watch: {
    dialog(v) {
      if (v) {
        sendPlausibleEvent('Checkout - Aberto')
      }
    },
  },

  mounted() {
    this.$root.$on('openCheckout', () => {
      this.dialog = true
    })
  },

  destroyed() {
    this.$root.$off('openCheckout')
  },

  methods: {
    closeModal() {
      this.dialog = false
      sendPlausibleEvent('Checkout - Fechado')
    },
  },
}
</script>

<style lang="sass">
.checkout-modal
  --swiper-theme-color: #00B87A
  background-color: #FEF6F0
  position: relative
  overflow: auto
  border-radius: 20px
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  .coupon-field
    max-width: 300px
    .coupon-btn
      position: relative
      top: -2px
  .checkout-modal-container
    overflow-y: auto
  .checkout-modal__close
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    border-radius: 100%
    position: absolute
    right: 2%
    top: 12px
    z-index: 10
  .checkout-head
    border-bottom: thin solid #9e9e9e !important
    .swiper-pagination
      bottom: 10px
    .checkout-perk
      display: flex
      height: 140px
      flex-direction: column
      align-items: center
      padding: 20px
      padding-bottom: 40px
      h2
        font-size: 18px
      p
        max-width: 400px
        font-size: 14px
      img
        position: absolute
        bottom: 40px
        height: 40px
  .checkout-modal__internal
    padding: 20px
    .checkout-plans
      .checkout-plans__container
        display: grid
        grid-gap: 12px
        grid-template-columns: repeat(2, minmax(0, 1fr))
      .checkout-plan
        background-color: white
        padding: 12px
        width: 100%
        border-radius: 10px
        border: 3px solid white
        box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%)
        transition: all 150ms ease-in-out
        user-select: none
        cursor: pointer
        span
          font-weight: bold
        .plan-number
          font-size: 15px
          font-weight: 900
          line-height: 16px
          font-family: Arial, Helvetica, sans-serif
        .plan-small
          font-size: 10px
          line-height: 14px
          text-transform: uppercase
        .plan-price
          opacity: 0.8
          margin: 2px 0
          line-height: 20px
          font-size: 20px
          .price-tag
            font-size: 12px
        .plan-price__old
          color: rgba(0,0,0,0.5) !important
          text-decoration: line-through
        .plan-essay
          font-size: 10px
          font-weight: normal
      .checkout-plan--active
        border: 3px solid #00EF9F
@media screen and (max-width: 900px)
  .checkout-modal
    border-radius: 0

    .checkout-modal__close
      top: 4px

    .checkout-modal__internal
      padding: 20px
      .checkout-plans
        .checkout-plans__container
          grid-gap: 6px
        .checkout-plan
          padding: 6px
          .plan-price
            font-size: 15px
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
