<template>
  <VDialog v-model="open" persistent max-width="500">
    <div v-if="open" class="limit-blocked d-flex items-center">
      <div
        class="blocked-content d-flex flex-column align-center justify-center"
      >
        <img src="/images/nofunds.png" class="mb-2" />
        <h2 class="text-center mb-5">
          Você não possui mais créditos de correção disponíveis.
        </h2>

        <p>
          Assine para ter mais créditos de correção por mês, obter comentários
          detalhados sobre suas redações e ter acesso ao curso
          <b>Redação de Sucesso</b>.
        </p>

        <h2>coRedação <span class="new--text">PREMIUM</span></h2>
        <p>a partir de 12 x {{ price }}/mês</p>

        <Benefits class="mb-4" />

        <VBtn depressed large class="new" dark @click="openCheckout">
          <b>⭐ Destravar minha redação</b>
        </VBtn>
        <VBtn class="mt-4" depressed @click="closeWall"> Não, obrigado. </VBtn>
      </div>
    </div>
  </VDialog>
</template>

<script>
import { sendPlausibleEvent } from '../../utils/plausible'
import Benefits from './Benefits.vue'

export default {
  components: {
    Benefits,
  },
  props: {
    value: Boolean,
  },

  data() {
    return {
      open: false,
    }
  },

  computed: {
    price() {
      return this.$store.getters['auth/priceFrom']
    },
  },

  watch: {
    value(val) {
      this.open = !!val
    },

    open(val) {
      this.$emit('input', val)
    },
  },

  created() {
    this.open = !!this.value
  },

  methods: {
    closeWall() {
      sendPlausibleEvent('Wall - Limite - Checkout Fechado')
      this.$emit('input', false)
    },
    openCheckout() {
      sendPlausibleEvent('Wall - Limite - Checkout Aberto')
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.limit-blocked
  position: relative
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #fff

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(255,255,255,0.8)
    filter: blur(10px)
  .blocked-content
    position: relative
    z-index: 10
    padding: 40px 20px
    max-width: 460px
    margin: 0 auto

    img
      width: 100px
    h2, p
      text-align: center
</style>
