<template>
  <div class="navuser">
    <div class="navuser__btn d-flex align-center">
      <NuxtLink to="/">
        <VBtn
          outlined
          rounded
          class="navbuttons__cta"
          min-width="125"
          depressed
          color="primary"
          >Painel Aluno</VBtn
        >
      </NuxtLink>
    </div>

    <v-menu elevation="0" open-on-hover left bottom offset-y>
      <template v-slot:activator="{ on }">
        <div v-on="on" class="py-2 d-flex navuser__avatar ml-4">
          <v-avatar
            v-if="currentUser.profilePicture === null || true"
            color="#17173D"
            size="38"
          >
            <span class="white--text">{{ initials }}</span>
          </v-avatar>
          <div class="d-flex align-center" v-else>
            <img :src="currentUser.profilePicture" />
          </div>
          <div class="d-flex align-center ml-1">
            <VIcon color="#17173D" size="18">
              {{ $icons.menuDown }}
            </VIcon>
          </div>
        </div>
      </template>

      <div>
        <div class="px-5 py-4 d-flex align-center menu__avatar">
          <v-avatar
            v-if="currentUser.profilePicture === null || true"
            class="mr-2"
            color="#17173D"
            size="32"
          >
            <span class="white--text">{{ initials }}</span>
          </v-avatar>
          <div class="d-flex align-center mr-2" v-else>
            <img :src="currentUser.profilePicture" />
          </div>
          <div>
            <h4 class="mb-0">{{ currentUser.fullname }}</h4>
            <v-layout class="mt-0">
              <img class="user-coins" src="/images/icons/coins.svg" />
              <span
                >{{ totalCredits }} crédito{{
                  totalCredits !== 1 ? 's' : ''
                }}</span
              >
            </v-layout>
          </div>
        </div>
        <VDivider />
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="$router.push(item.to)"
            class="px-5"
          >
            <v-list-item-title>
              <VIcon size="20" class="mr-2">
                {{ item.icon }}
              </VIcon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      items: [
        {
          title: 'Minha conta',
          to: '/conta',
          icon: this.$icons.account,
        },
        {
          title: 'Painel aluno',
          to: '/',
          icon: this.$icons.dashboard,
        },
        {
          title: 'Enviar redação',
          to: '/temas-de-redacao',
          icon: this.$icons.write,
        },
        {
          title: 'Sair',
          to: '/sair',
          icon: this.$icons.logout,
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      isSubscribed: 'auth/isSubscribed',
      currentUser: 'auth/currentUser',
    }),

    totalCredits() {
      return !!this.currentUser.credits ? this.currentUser.credits : 0
    },

    initials() {
      const names = this.currentUser?.fullname?.split(' ')

      if (names?.length == 1) {
        return this.currentUser.fullname.substring(0, 2).toUpperCase()
      } else if (names?.length > 1) {
        return names[0].charAt(0) + names.pop().charAt(0).toUpperCase()
      }
    },
  },
}
</script>

<style lang="sass">
.menu__avatar
  img
    width: 32px
    height: 32px
    border-radius: 100%
.navuser
  display: flex

  .navuser__avatar
    cursor: pointer
    img
      width: 38px
      height: 38px
      border-radius: 100%
.v-menu__content
  box-shadow: none !important
  background-color: white
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08), 0 0 0 transparent
  border-radius: 8px
</style>
