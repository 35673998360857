<template>
  <v-row>
    <v-col
      cols="12"
      class="checkout__top d-flex flex-column justify-center align-center"
    >
      <h1>{{ title }}</h1>
      <div v-if="step == 0">
        <p class="text-center checkout__top__subtitle">
          Escolha o plano que mais combina com você. Você terá 1 ano para enviar
          as redações em qualquer um dos planos escolhidos.
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    step: Number,
    title: String,
  },
}
</script>

<style lang="sass" scoped>
.checkout__top__subtitle
  max-width: 700px
  font-size: 16px
</style>
