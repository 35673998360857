import Vue from 'vue'
import GhostContentAPI from '@tryghost/content-api'

const ContentAPI = new GhostContentAPI({
  url: 'https://conteudo.coredacao.com',
  key: 'd001b1478d2bbfd9d9bf3c5823',
  version: 'v3',
})

Vue.use({
  install: function (Vue, options) {
    Vue.mixin({
      computed: {
        $api() {
          return api
        },
      },
    })
  },
})

export default ContentAPI
