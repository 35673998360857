<template>
  <div>
    <WelcomeHeader :title="title">
      Isso nos ajudará a entender melhor o seu perfil.
    </WelcomeHeader>

    <VTextField
      v-if="exam === 'CONCURSO'"
      v-model="concursoName"
      rounded
      outlined
      hide-details
      class="mt-10"
      autofocus
      label="Nome do concurso"
      placeholder="Insira o nome do concurso"
    ></VTextField>

    <VTextField
      v-if="exam === 'OUTRO_VESTIBULAR'"
      v-model="vestibularName"
      rounded
      outlined
      hide-details
      class="mt-10"
      autofocus
      label="Vestibular"
      placeholder="Insira o vestibular"
    ></VTextField>

    <Chooser
      v-if="exam === 'ENEM'"
      v-model="lastEnemGrade"
      class="mt-4"
      :options="[
        {
          value: 'Menos de 500',
          name: 'Menos de 500'
        },
        {
          value: 'De 500 a 600',
          name: 'De 500 a 600'
        },
        {
          value: 'De 600 a 700',
          name: 'De 600 a 700'
        },
        {
          value: 'De 700 a 800',
          name: 'De 700 a 800'
        },
        {
          value: 'Mais de 800',
          name: 'Mais de 800'
        },
        {
          value: 'Prefiro não informar',
          name: 'Prefiro não informar'
        }
      ]"
    />
  </div>
</template>

<script>
import WelcomeHeader from './WelcomeHeader.vue'
import Chooser from './Chooser.vue'

export default {
  components: {
    WelcomeHeader,
    Chooser
  },

  computed: {
    exam() {
      return this.$store.state.welcome.exam
    },
    lastEnemGrade: {
      get() {
        return this.$store.state.welcome.lastEnemGrade
      },
      set(value) {
        this.$store.commit('welcome/setLastEnemGrade', value)
      }
    },
    concursoName: {
      get() {
        return this.$store.state.welcome.concursoName
      },
      set(value) {
        this.$store.commit('welcome/setConcursoName', value)
      }
    },
    vestibularName: {
      get() {
        return this.$store.state.welcome.vestibularName
      },
      set(value) {
        this.$store.commit('welcome/setVestibularName', value)
      }
    },
    title() {
      switch (this.exam) {
        case 'ENEM':
          return 'Qual foi sua nota da redação no ultimo ENEM?'
        case 'CONCURSO':
          return 'Para qual concurso você está se preparando?'
        case 'OUTRO_VESTIBULAR':
          return 'Para qual vestibular você está se preparando?'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="sass"></style>
