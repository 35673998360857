const perks = (n) => {
  return [
    {
      title:
        '<b>Aulas, temas, apostila, banco de redações</b> e tudo incluso no plano gratuito',
      text: false,
      enabled: true,
    },
    {
      title: '<b>4 correções</b> por mês',
      text: false,
      enabled: true,
    },
    {
      title: 'Correções <b>detalhadas</b> entregues em <b>48 horas</b>',
      text: false,
      enabled: true,
    },
    {
      title: 'Gráfico de desempenho detalhado <b>por competência</b>',
      text: false,
      enabled: true,
    },
    {
      title:
        '<b>Repertórios</b>, <b>citações</b> e <b>argumentos prontos</b> para usar nos temas',
      text: false,
      enabled: true,
    },
    {
      title:
        'Lives quinzenais <b>Corrigindo a Redação do ENEM ao vivo</b> com sessão de dúvidas',
      text: false,
      enabled: true,
    },
    {
      title: 'Acesso <b>completo</b> ao banco de redações corrigidas',
      text: false,
      enabled: true,
    },
    {
      title: '<b>Mentoria individual</b> pelo Chat ou WhatsApp',
      text: false,
      enabled: true,
    },
  ]
}

export default perks

// old plans
const plans = [
  {
    planId: 'basico',
    title: 'ENEM Básico',
    price: 68.9,
    months: 6,
    quantity: 12,
    perks: perks(12),
    theme: 'Iniciando',
  },
  {
    planId: 'foco',
    styled: true,
    title: 'ENEM Foco',
    price: 113.8,
    months: 6,
    quantity: 24,
    perks: perks(24),
    theme: 'Mais escolhido',
  },
  {
    planId: 'intensivo',
    title: 'ENEM Intensivo',
    price: 179.4,
    months: 6,
    quantity: 48,
    perks: perks(48),
    theme: 'Cursos concorridos',
  },
]
