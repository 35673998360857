import dayjs from 'dayjs'
import Vue from 'vue'

export default {
  signIn(state, data) {
    state.user = data
    state.token = data.token

    localStorage.setItem('cored_token', data.token)

    this.app.$axios.setToken(data.token)
  },

  signOut(state) {
    state.user = null
    state.token = null

    localStorage.removeItem('cored_token')
    this.app.$axios.setToken(false)
  },

  addCredits(state, amount) {
    if (state.user !== null) {
      state.user.credits += parseInt(amount)
    }
  },

  addSubscription(state, months) {
    if (state.user !== null) {
      if (
        state.user.subscriptionDate !== null &&
        dayjs(state.user.subscriptionDate).isAfter(dayjs())
      ) {
        state.user.subscriptionDate = dayjs(state.user.subscriptionDate).add(
          months,
          'month',
        )
      } else {
        state.user.subscriptionDate = dayjs().add(months, 'month')
      }
    }
  },

  setRedirect(state, redirectUrl) {
    console.log('setting redirect', redirectUrl)
    if (redirectUrl !== null) {
      this.app.$cookie.set('cored_redirect', redirectUrl, 7)
    } else {
      this.app.$cookie.del('cored_redirect')
    }

    state.redirectUrl = redirectUrl
  },

  setFirstTime(state, value) {
    state.isFirstTime = value
  },

  setDeviceInfo(state, info) {
    state.deviceInfo = info
  },

  setConfigs(state, configs) {
    state.configs = {
      ...state.configs,
      ...configs,
    }
  },

  setExpired(state, value) {
    state.expired = value
  },

  setSelectedPlanId(state, planId) {
    state.selectedPlanId = planId
  },

  setPreference(state, payload) {
    if (state.user !== null && state.user.preferences !== null) {
      Vue.set(state.user.preferences, payload.key, payload.value)
    }
  },

  contabilizeCorrection(state) {
    if (state.user?.correctionsInMonth) {
      state.user.correctionsInMonth += 1
    }
  },
}
