<template>
  <div class="">
    <div>
      <div class="mt-2">
        <VTextField
          v-model="title"
          placeholder="Título (opcional)"
          outlined
          hide-details
          class="mb-2 bg-white"
          @input="handleChanges"
          @blur="saveEssay"
        ></VTextField>
        <WriteArea
          v-model="text"
          class="elevation-2"
          @blur="saveEssay"
          @input="handleChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WriteArea from './WriteArea.vue'

export default {
  components: {
    WriteArea,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      title: '',
      text: '',
      lastSavedWordCount: 0,
      words: 0,
      mounted: false,
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  watch: {
    theme(newTheme, old) {
      if (old !== null) {
        this.saveEssay()
      }
    },
  },

  mounted() {
    this.mounted = true
  },

  created() {
    this.title = this.value.title
    this.text = this.value.text ?? ''
    this.lastSavedWordCount =
      this.text.split(' ').filter((w) => w.length > 0)?.length ?? 0

    this.emitChanges()
  },

  methods: {
    handleChanges() {
      this.emitChanges()

      if (Math.abs(this.words - this.lastSavedWordCount) > 4) {
        this.saveEssay()
      }
    },
    emitChanges() {
      const paragraphs =
        this.text.split('\n').filter((p) => p.length > 0)?.length ?? 0
      this.words = this.text.split(' ').filter((w) => w.length > 0)?.length ?? 0

      this.$emit('input', {
        title: this.title,
        text: this.text,
        words: this.words,
        phrases: this.text.split('.').length ?? 0,
        paragraphs,
      })
    },

    async saveEssay() {
      const content = this.text?.trimEnd() || ''
      if (content < 20) return
      this.lastSavedWordCount = this.value.words

      await this.$store.dispatch('essays/saveEssay', {
        content,
        title: this.title,
        themeId: this.theme.id,
      })
    },
  },
}
</script>

<style lang="sass"></style>
