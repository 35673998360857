import Vue from 'vue'

export default {
  setModules(state, modules) {
    state.modules = modules
  },

  setLessonProgress(state, { lessonId, progress }) {
    Vue.set(state.lessonsProgress, lessonId, progress)
  },
}
