<template>
  <div>
    <AppNav
      sub
      image="/images/dashboard/icons/mindmap.svg"
      title="Mapas Mentais"
      subtitle="Relembre conceitos da Redação ENEM"
    />

    <div class="mapas">
      <NuxtLink
        :to="m.src"
        target="_blank"
        v-for="(m, i) in maps"
        :key="i"
        class="mapa white"
      >
        <VImg :src="m.src" />
        <div class="pa-4">
          <h3 class="accent--text">{{ m.title }}</h3>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import AppNav from '@/components/app/AppNav'

export default {
  layout: 'app',

  components: {
    AppNav,
  },

  data: function () {
    return {
      maps: [
        {
          title: 'Redação do ENEM - Estrutura do Texto',
          src: '/mapas/estrutura_texto.png',
        },
        {
          title: 'Como a redação é corrigida',
          src: '/mapas/como_e_corrigido.png',
        },
        {
          title: 'Características do texto dissertativo-argumentativo',
          src: '/mapas/caracteristicas_dissertativo_argumentativo.png',
        },
        {
          title: 'Introdução da Redação ENEM',
          src: '/mapas/introducao.png',
        },
        {
          title: 'Desenvolvimento da Redação ENEM',
          src: '/mapas/desenvolvimento.png',
        },
        {
          title: 'Conclusão da Redação ENEM',
          src: '/mapas/conclusao.png',
        },
        {
          title: 'Conectivos na redação',
          src: '/mapas/conectivos.png',
        },
        {
          title: 'Competência 1',
          src: '/mapas/c1.png',
        },
        {
          title: 'Competência 2',
          src: '/mapas/c2.png',
        },
        {
          title: 'Competência 3',
          src: '/mapas/c3.png',
        },
        {
          title: 'Competência 4',
          src: '/mapas/c4.png',
        },
        {
          title: 'Competência 5',
          src: '/mapas/c5.png',
        },
      ],
    }
  },
}
</script>

<style lang="sass" scoped>
.mapas
  padding: 0 60px
  padding-bottom: 120px
  display: grid
  grid-gap: 1.4em
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))

  .mapa
    box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%)
    border-radius: 20px
    cursor: pointer
    transition: all .25s cubic-bezier(.02,.01,.47,1)
    transform: scale(0.96)

    &:hover
      transform: scale(1)
      box-shadow: 0px 10px 40px 20px rgb(0 0 0 / 10%)
@media screen and (max-width: 960px)
  .mapas
    padding: 0 20px
</style>
