<template>
  <div class="firststep">
    <VDialog
      :value="shouldShow"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      no-spacer
      centered-title
      no-padding
      color="#FF6338"
      :max-width="900"
      scrollable
    >
      <VContainer class="firststep-content ma-0">
        <WelcomeHeader title="Como deseja começar?">
          Tudo pronto. Já pode aproveitar os benefícios do coRedação.
        </WelcomeHeader>
        <div class="firststep-choices">
          <div
            v-for="flow in flows"
            :key="flow.title"
            class="firststep-choice"
            @click="goTo(flow.to)"
          >
            <h3 class="mb-2">{{ flow.title }}</h3>
            <p>
              {{ flow.description }}
            </p>
          </div>
        </div>
      </VContainer>
    </VDialog>
  </div>
</template>

<script>
import WelcomeHeader from './Welcome/WelcomeHeader'

export default {
  components: {
    WelcomeHeader
  },

  data() {
    return {
      flows: [
        {
          title: '✏️ Corrigir uma redação',
          description:
            'Escolha um tema, escreva e receba sua correção em segundos.',
          to: '/temas',
          img: ''
        },
        {
          title: '📹 Ver o curso Redação de Sucesso',
          description:
            'Videoaulas com tudo que você precisa saber para escrever uma boa redação.',
          to: '/aprenda/curso-enem',
          img: '#FF6338'
        },
        {
          title: '📖 Ler a apostila',
          description: 'Apostila interativa para Redação do ENEM.',
          to: '/aprenda/apostila',
          img: 'mdi-pencil'
        }
      ]
    }
  },

  computed: {
    shouldShow() {
      return this.$store.state.welcome.showFirstSteps
    }
  },

  methods: {
    goTo(to) {
      this.$store.commit('welcome/setShowFirstSteps', false)
      this.$router.push(to)
    }
  }
}
</script>

<style lang="sass">
.firststep-choices
  max-width: 500px
  margin: 0 auto
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 14px
  margin-top: 20px
.firststep-choice
  border-radius: 20px
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%)
  padding: 14px
  border: thin solid #9e9e9e
  width: 100%
  background-color: white
  p
    font-size: 16px
  &:hover
    cursor: pointer
    background-color: #f5f5f5
    transition: 0.3s
.confetti
  width: 30px
  height: 30px

.firststep
  ul
    list-style-type: none

.firststep-content
  font-size: 18px
  background-color: #FFF9F4
  padding: 40px 10px

  .firststep-progress
    display: flex
    width: 100%
    height: 10px
    margin-bottom: 20px

@media screen and (max-width: 600px)
  .firststep-content
    font-size: 16px
    overflow-y: scroll
  .confetti
    width: 20px
    height: 20px
</style>
