export default (context) => {
  const { app } = context

  if (process.client) {
    app.$gtag.event = (eventData) => {
      let action = eventData.eventAction
        ? eventData.eventAction
        : eventData['event_action']
      app.$gtag('event', action, eventData)
    }
  }
}
