<template>
  <div class="hidden-md-and-up">
    <v-slide-y-reverse-transition absolute>
      <div
        v-if="mounted"
        :class="{
          'app-bar--ios': $store.getters['auth/device'].platform === 'ios'
        }"
        class="app-bar"
      >
        <v-slide-y-reverse-transition>
          <div v-if="menuOpen" class="moremenu">
            <div
              class="menu-items d-flex flex-column"
              @click="menuOpen = false"
            >
              <NuxtLink
                v-ripple="{ class: `new--text` }"
                class="d-flex items-center"
                to="/conteudo/artigos/modelos-de-redacao/"
              >
                <img
                  class="moremenu-icon"
                  src="/images/icons/essay_models.png"
                />
                Modelos de Redação
              </NuxtLink>
              <NuxtLink
                v-ripple="{ class: `new--text` }"
                class="d-flex items-center"
                to="/conteudo/artigos/repertorio-sociocultural/"
              >
                <img class="moremenu-icon" src="/images/icons/book.png" />
                Repertórios Socioculturais
              </NuxtLink>
              <NuxtLink
                v-ripple="{ class: `new--text` }"
                class="d-flex items-center"
                to="/aprenda/apostila"
              >
                <img
                  class="moremenu-icon"
                  src="/images/dashboard/icons/theme_on.svg"
                />
                Apostila Redação ENEM
              </NuxtLink>
              <a
                v-ripple="{ class: `new--text` }"
                class="d-flex items-center"
                target="_blank"
                href="https://respostacerta.com?utm_source=cored_app"
              >
                <img
                  class="moremenu-icon"
                  src="/images/dashboard/icons/resposta-certa_on.svg"
                />
                Questões ENEM ↗
              </a>
            </div>
          </div>
        </v-slide-y-reverse-transition>
        <ul class="app-bar__menu">
          <li
            v-for="(m, i) in menu"
            :key="i"
            v-ripple="{ class: `new--text` }"
            :class="{
              'app-bar__btn--inactive': active != i,
              'app-bar--mobileoff': m.mobile_off
            }"
            class="app-bar__btn"
            @click="$router.push(m.to)"
          >
            <div class="app-bar__icon">
              <img :src="`/images/dashboard/icons/${m.icon}.svg`" />
              <img
                :class="{ 'app-bar__icon__on--active': active == i }"
                class="app-bar__icon__on"
                :src="`/images/dashboard/icons/${m.icon}_on.svg`"
              />
            </div>
            <span>
              {{ m.title }}
            </span>
          </li>
          <li
            v-ripple="{ class: `new--text` }"
            class="app-bar__btn app-bar__btn--inactive"
            @click="menuOpen = !menuOpen"
          >
            <div class="AppNav__icon">
              <VIcon size="22" color="#989898">
                {{ $icons.menuHorizontal }}
              </VIcon>
            </div>
            <span class=""> Mais </span>
          </li>
        </ul>
      </div>
    </v-slide-y-reverse-transition>
    <VFadeTransition>
      <div v-if="menuOpen" class="menu-overlay" @click="menuOpen = false">
        <VOverlay color="white" absolute />
      </div>
    </VFadeTransition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      mounted: false,
      menuOpen: false
    }
  },

  mounted() {
    this.mounted = true
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isSubscribed: 'auth/isSubscribed',
      hasEssaysEnabled: 'auth/hasEssaysEnabled'
    }),

    menu() {
      return [
        {
          title: 'Redações',
          to: '/',
          icon: 'essays',
          icon_on: 'essays_on'
        },
        {
          title: 'Curso',
          to: '/aprenda/curso-enem',
          icon: 'panel',
          icon_on: 'panel_on'
        }
      ]
    },

    active() {
      const p = this.$route.path

      for (let i = 0; i < this.menu.length; i++) {
        const m = this.menu[i]

        if ((p.includes(m.to) && m.to !== '/') || p === m.to) return i
      }

      return -1
    }
  },

  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    }
  }
}
</script>

<style lang="sass" scoped>
.app-bar
  &::after
    position: absolute
    content: url('/images/gradients/blue.png')
    left: 0
    top: 0
    width: 700px
    height: 700px
    opacity: 0.6
    z-index: 0
  .app-bar__menu
    margin-top: 48px
    list-style: none
    padding: 0
    position: relative
    z-index: 2
    .app-bar__btn
      border-radius: 20px
      background-color: #FEF6F0
      padding: 12px 24px
      width: 105px
      border: 1px solid #9e9e9e

      text-transform: uppercase
      letter-spacing: 0.4px
      font-size: 14px
      transition: all 300ms ease
      display: flex
      font-weight: bold
      align-items: center
      .app-bar__icon
        position: relative
        display: flex
        align-items: center
        .app-bar__icon__on
          position: absolute
          top: 0
          opacity: 0
          transform: all 150ms ease
        .app-bar__icon__on--active
          opacity: 1
      span
        color: #FF6237
        opacity: 1
        display: inline-flex
        align-items: center
      img
        width: 22px
        height: 22px
        margin-right: 24px
    .app-bar__btn--inactive
      cursor: pointer
      background-color: transparent
      span
        color: #888
      img
        opacity: 0.5
    li
      margin-top: 12px
  .app-bar__menu--logout
    margin-top: auto
  .app-bar__title
    font-weight: bold
    font-size: 18px
    color: #333
    margin-left: 8px
  .logo
    width: 28px
    height: 28px

.menu-overlay
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 1000
  background-color: rgba(0,0,0,0.5)

@media screen and (max-width: 960px)
  .app-bar
    width: auto
    min-width: auto !important
    max-width: 100%
    position: fixed
    bottom: 20px
    z-index: 1001
    top: auto
    left: 50%
    transform: translateX(-50%)
    padding: 4px
    height: auto
    border-radius: 20px
    border: 1px solid rgba(51, 51, 51, 0.7)
    backdrop-filter: blur(10px)
    background-color: white
    display: flex

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 5px 0px #9e9e9e !important
    .app-bar__menu
      display: flex
      margin: 0
      justify-content: space-between
      li
        margin: 0
      .app-bar__btn
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 6px 20px
        font-weight: bold
        text-transform: none
        border: 0

        width: 105px
        border-radius: 20px
        font-size: 12px
        img
          width: 18px
          height: 18px
          margin: 0
          margin-bottom: 2px
        li
          margin: 0
    .app-bar__logo, .app-bar__menu--logout, .app-bar--mobileoff
      display: none !important
.app-bar--ios
  padding-bottom: 20px
  padding-left: 10px
  padding-right: 10px

.moremenu
  position: absolute
  left: 0
  top: -195px
  border-radius: 20px
  border: 1px solid rgba(51, 51, 51, 0.7)
  backdrop-filter: blur(10px)
  background-color: white
  width: 100%
  font-weight: bold
  display: flex
  align-items: center
  font-size: 14px
  overflow: hidden

  .menu-items
    width: 100%

  a
    width: 100%
    padding: 12px
    border-bottom: thin solid #9e9e9e
  a:last-child
    border-bottom: 0

.moremenu-icon
  width: 20px
  height: 20px
  margin-right: 12px
</style>
