<template>
  <div class="loginform">
    <div class="d-flex align-center">
      <img class="loginform__image" src="/images/logo_text.png" />
    </div>
    <div class="my-5">
      <h1>Que bom te ver de novo!</h1>
      <p>
        Novo por aqui?
        <NuxtLink to="/cadastrar" target=""><b>Crie sua conta.</b></NuxtLink>
      </p>
    </div>
    <div class="loginform__social pt-5">
      <VBtn
        v-if="false"
        outlined
        rounded
        :disabled="loading"
        block
        color="#60A4FF"
        :dark="!loading"
        class="mb-2"
        @click="signInFacebook"
      >
        <img class="loginform__social__icon" src="/images/auth/facebook.png" />
        <span class="ml-2 loginform__social__text">Entrar com o Facebook</span>
      </VBtn>
      <VBtn
        outlined
        rounded
        :disabled="loading"
        block
        color="#F14336"
        :dark="!loading"
        class="mb-2"
        @click="signInGoogle"
      >
        <img class="loginform__social__icon" src="/images/auth/google.png" />
        <span class="ml-2 loginform__social__text">Entrar com o Google</span>
      </VBtn>
    </div>
    <div class="auth-divider">
      <VDivider class="my-5" />
      <span>ou entre com e-mail</span>
    </div>
    <v-form ref="form">
      <VTextField
        v-model="values.email"
        label="Email"
        rounded
        outlined
        dense
        hide-details
        class="mb-2"
        tabindex="0"
        @keyup.enter="loginLocal"
      >
        <template #prepend-inner>
          <VIcon class="loginform__icon" size="22">
            {{ $icons.account }}
          </VIcon>
        </template>
      </VTextField>

      <div tabindex="10" class="d-flex justify-end">
        <NuxtLink class="" to="/recuperar">Esqueceu a senha?</NuxtLink>
      </div>
      <VTextField
        v-model="values.password"
        rounded
        outlined
        dense
        hide-details
        class="mb-4"
        label="Senha"
        type="password"
        tabindex="0"
        @keyup.enter="loginLocal"
      >
        <template #prepend-inner>
          <VIcon class="loginform__icon" size="22">
            {{ $icons.fingerprint }}
          </VIcon>
        </template>
      </VTextField>
    </v-form>
    <div class="d-flex mt-5 pt-2">
      <VBtn
        outlined
        rounded
        :loading="loading"
        block
        dense
        color="white"
        class="new"
        depressed
        @click="loginLocal"
        ><b>Acessar minha conta</b></VBtn
      >
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { authGoogle } from '@/utils/oauth'

const FORGOT_URL = `https://api.whatsapp.com/send/?phone=+5521982509256&text=Ol%C3%A1%2C+esqueci+minha+senha+do+coReda%C3%A7%C3%A3o.+Pode+me+ajudar%3F&app_absent=0`

export default {
  data() {
    return {
      values: {
        email: '',
        password: '',
      },
      loading: false,
      forgotUrl: FORGOT_URL,
    }
  },

  methods: {
    ...mapMutations({
      signIn: 'auth/signIn',
    }),
    goSignUp() {
      this.$router.push('/assinar/cadastrar/')
    },
    async loginLocal() {
      if (!this.$refs.form.validate()) return

      this.loading = true

      try {
        const { data } = await this.$axios.post(
          '/users/login/local',
          this.values,
        )

        if (data.user.type === 'ORG_OWNER' || data.user.type === 'ORG_MEMBER') {
          this.$toast.error(
            'Essa é uma conta de instituição e não pode ser acessada por aqui.',
          )
        } else {
          this.$router.push('/auth/callback?token=' + data.token)
        }
      } catch (e) {
        this.$toast.error('Usuário ou senha inválidos')
      }

      this.loading = false
    },

    async signInGoogle() {
      this.loading = true
      await authGoogle()
    },
  },
}
</script>

<style lang="sass" scoped>
.loginform
  width: 450px
  background-color: white
  padding: 48px
  border-radius: 10px
  box-shadow: 2px 2px 5px 0px rgba(214, 214, 214, 0.75)
  position: relative
  margin-bottom: 120px
  border: 1px solid #ddd
  max-width: 100%
  p
    font-size: 14px
  h1
    font-size: 22px
    font-weight: semibold
  .loginform__image
    max-width: 100%
    width: 150px
  .auth-divider
    position: relative
    height: 70px
    display: flex
    align-items: center
    span
      position: absolute
      background-color: white
      top: 0
      bottom: 0
      height: 20px
      margin: auto
      width: 150px
      text-align: center
      left: 0
      letter-spacing: 0.3px
      font-size: 12px
      right: 0
  .loginform__icon
    margin-top: 1px
    margin-right: 4px
  .loginform__social
    .loginform__social__text
      width: 185px
      text-align: left
    .loginform__social__icon
      width: 22px
@media (max-width: 600px)
  .loginform
    padding: 16px
    max-width: 400px
    width: 100%
    margin-top: 32px
    &:after, &:before
      display: none
</style>
