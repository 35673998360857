<template>
  <div>
    <WelcomeHeader title="Você está se preparando para qual prova?">
      Isso vai nos ajudar a personalizar sua experiência.
    </WelcomeHeader>

    <Chooser v-model="exam" class="mt-4" :options="options" />
  </div>
</template>

<script>
import WelcomeHeader from './WelcomeHeader.vue'
import Chooser from './Chooser.vue'

export default {
  components: {
    WelcomeHeader,
    Chooser
  },

  data() {
    return {
      options: [
        {
          value: 'ENEM',
          name: 'ENEM'
        },
        {
          value: 'CONCURSO',
          name: 'Concurso'
        },
        {
          value: 'FUVEST',
          name: 'Fuvest'
        },
        {
          value: 'OUTRO_VESTIBULAR',
          name: 'Outro vestibular'
        }
      ]
    }
  },

  computed: {
    exam: {
      get() {
        return this.$store.state.welcome.exam
      },
      set(value) {
        this.$store.commit('welcome/setExam', value)
      }
    }
  }
}
</script>

<style lang="sass"></style>
