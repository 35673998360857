<template>
  <div class="checkout-plans">
    <div
      v-for="plan in plans"
      :key="plan.id"
      :class="{
        'checkout-plan--selected': plan.id === selectedPlan.id,
      }"
      class="checkout-plan"
      @click="selectPlan(plan)"
    >
      <div class="d-flex checkout-plan-title">
        <div class="checkout-plan-checkbox mr-2">
          <VIcon v-if="plan.id === selectedPlan.id" color="new">
            {{ $icons.checkboxOn }}
          </VIcon>
          <VIcon v-else>
            {{ $icons.checkboxOff }}
          </VIcon>
        </div>
        <h3>
          {{ plan.planName }}
        </h3>
      </div>
      <div class="ml-auto text-right">
        <div class="font-weight-bold">
          {{ plan.maxInstallments }} x {{ plan.montlyPrice }}
        </div>
        <div class="caption">Total de {{ plan.total }}</div>
      </div>
      <div v-if="plan.months === 12" class="checkout-plan-best">
        66% mais barato
      </div>
    </div>
  </div>
</template>

<script>
import { FormatMoney } from '~/utils/money'

export default {
  computed: {
    selectedPlan() {
      return this.$store.getters['auth/selectedPlan']
    },
    plans() {
      return this.$store.getters['auth/plans'].map((p) => {
        return {
          ...p,
          montlyPrice: FormatMoney(p.price / p.maxInstallments),
          total: FormatMoney(p.price),
        }
      })
    },
  },

  methods: {
    selectPlan(plan) {
      this.$store.commit('auth/setSelectedPlanId', plan.id)
    },
  },
}
</script>

<style lang="sass" scoped>
.checkout-plans
  display: grid
  gap: 10px
  .checkout-plan
    position: relative
    display: flex
    border-radius: 20px
    padding: 20px 10px
    padding-right: 20px
    border: 3px solid #e6e6e6
    background-color: #fff
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
    transition: all 0.15s ease-in-out
    .checkout-plan-best
      background: #FF6338
      border-radius: 20px
      padding: 2px 10px
      font-size: 12px
      color: white
      font-weight: bold
      letter-spacing: 0.5px
      transform: translateY(-50%)
      top: 0
      right: 20px
      position: absolute
    h3
      line-height: 24px
      font-size: 20px
    .checkout-plan-title
      height: 24px
    .caption
      color: #575757
  .checkout-plan--selected
    border: 3px solid #FF6338
    background-color: rgb(253,185,157, 0.2)
</style>
