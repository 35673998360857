<template>
  <VDialog
    v-model="open"
    max-width="500"
    no-route
    color="#dc2626"
    :title="title"
  >
    <div class="evasion-container">
      <h2 class="mb-2">⚠️ {{ title }}</h2>
      <VDivider class="mb-4" />
      <div v-if="isCompleteEvasion" class="evasion">
        <p>
          Você não discutiu adequadamente sobre o tema da redação e por isso seu
          texto foi anulado e recebeu nota zero. É importante que você deixe
          claro para o leitor sobre qual assunto você está falando e use mais as
          palavras da frase tema na redação.
        </p>
        <p class="mb-0">
          Além disso, leia com atenção os textos motivadores, a função deles é
          te guiar para que você fale exatamente sobre o tema pedido. Não
          desista, isso faz parte do processo!
        </p>
      </div>
      <div v-else class="evasion">
        <p>
          Infelizmente você não discutiu completamente sobre o tema da redação
          e, seguindo as regras de correção do ENEM, sua nota foi penalizada nas
          Competências 2, 3 e 5.
        </p>
        <p>
          Dessa maneira, você falou sobre partes importantes do assunto, mas não
          debateu de acordo com o que era esperado. É importante que você use
          mais as palavras da frase tema para não perder pontos nesse aspecto.
        </p>
        <p class="mb-0">
          Além disso, leia com calma os textos motivadores, porque eles vão te
          mostrar qual é o assunto que você precisa discutir. Faça isso e
          reescreva sua redação, não desista!
        </p>
      </div>
      <VBtn color="new" depressed dark block class="mt-2" @click="open = false"
        ><b>Entendi</b></VBtn
      >
    </div>
  </VDialog>
</template>

<script>
export default {
  props: {
    isCompleteEvasion: Boolean,
    isEvaded: Boolean,
  },

  data() {
    return {
      open: true,
    }
  },

  computed: {
    title() {
      return this.isCompleteEvasion ? 'Fuga do tema' : 'Tangenciamento do tema'
    },
  },

  watch: {
    isEvaded() {
      if (this.isEvaded) {
        this.open = true
      }
    },
  },

  created() {
    if (this.isEvaded) {
      this.open = true
    }
  },
}
</script>

<style lang="sass">
.evasion
  font-size: 16px
.evasion-container
  background-color: white
  padding: 20px
</style>
