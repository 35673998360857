import { API_URL } from '~/constants/api'

export default async (context) => {
  const { store, redirect, app } = context
  const { $axios } = app

  if (store.getters['auth/currentUser'] !== null) {
    $axios.setToken(store.getters['auth/currentUser'].token)
  }

  $axios.setBaseURL(API_URL)

  $axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response?.status === 401) {
        const expiredType = error?.response?.data || ''

        store.commit('auth/setExpired', expiredType)
        store.commit('auth/signOut')
        redirect('/entrar')
        return error
      }

      console.log('error?', error)
      throw error
    },
  )

  await store.dispatch('nuxtClientInit', context)
}
