<template>
  <div class="results-overview">
    <VCard class="result-card" width="" outlined>
      <VListItem three-line>
        <VListItemContent class="result-grade">
          <div class="text-center font-weight-bold overline">Nota Geral</div>
          <h1
            class="result-primary primary--text text-center d-flex justify-center align-center"
          >
            <VSkeletonLoader
              v-if="!hasCorrection"
              class="result-loader"
              type="image"
            ></VSkeletonLoader>
            <span v-if="hasCorrection" :class="finalGrade.color + '--text'">{{
              finalGrade.value
            }}</span>
            <span class="result-primary__total grey--text text--lighten-1"
              >/1000</span
            >
          </h1>
          <div class="d-flex flex-column align-center">
            <ResultShare v-model="essay" />

            <EssayReportIssue :correction="correction" />
          </div>
        </VListItemContent>
      </VListItem>
    </VCard>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ResultShare from './ResultShare.vue'
import EssayReportIssue from './EssayReportIssue.vue'

export default {
  components: {
    ResultShare,
    EssayReportIssue,
  },

  props: {
    essay: Object,
    demo: Boolean,
    canShare: Boolean,
    isPublic: Boolean,
  },

  data() {
    return {
      rating: 0,
    }
  },

  computed: {
    correction() {
      return this.essay.corrections[0]
    },
    grades() {
      return this.correction?.grades
    },
    finalGrade() {
      console.log('grades', this.grades)
      const gradeValue = this.grades.reduce((acc, grade) => acc + grade, 0)

      const gradeColor =
        gradeValue < 400
          ? 'red'
          : gradeValue < 600
            ? 'orange'
            : gradeValue < 800
              ? 'info'
              : 'green'

      return {
        value: gradeValue,
        color: gradeColor,
      }
    },

    hasCorrection() {
      return this.essay !== null && this.essay.correction !== null
    },
  },

  mounted() {
    if (this.finalGrade.value > 800) this.showCelebration()
  },

  methods: {
    showCelebration() {
      this.$confetti.start()

      setTimeout(() => {
        this.$confetti.stop()
      }, 2000)
    },
    formatDate(val) {
      return dayjs(val).format('DD/MM/YYYY [às] HH:mm')
    },
  },
}
</script>

<style lang="sass" scoped>
.results-overview
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  .result-card
    width: 100% !important
  .result-loader
    width: 80px
    height: 35px
    margin-right: 8px
  .result-primary
    font-size: 46px
    .result-primary__total
      font-size: 32px
  .result-median
    width: 100%
    text-align: center
  .result-grade
    position: relative

@media screen and (max-width: 960px)
  .results-overview
    .result-card
      width: 100% !important
</style>
