<template>
  <div class="content-blocked d-flex items-start">
    <div class="blocked-content d-flex flex-column align-center justify-start">
      <img src="/images/icons/padlock.svg" class="mb-2" />

      <h2>Conteúdo bloqueado</h2>
      <p>Assine o coRedação para desbloquear todo o conteúdo da plataforma.</p>
      <VBtn depressed class="primary" @click="openCheckout"> Desbloquear </VBtn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.content-blocked
  position: absolute
  top: 150px
  left: 0
  width: 100%
  height: 100%


  &::before
    content: ''
    position: absolute
    top: 0px
    left: 0
    width: 100%
    height: 100%
    padding: 0 -20px
    background-color: rgba(255,255,255,0.7)
    backdrop-filter: blur(4px)
  .blocked-content
    position: relative
    z-index: 10
    padding: 20px
    max-width: 400px
    margin: 0 auto

    img
      width: 60px
    h2, p
      text-align: center
</style>
