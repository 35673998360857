<template>
  <div class="modal">
    <VDialog
      :persistent="persistent"
      :value="value"
      :max-width="maxWidth"
      :max-height="maxHeight"
      class="modal__dialog"
      v-bind="$attrs"
      @input="onInput"
    >
      <v-card :loading="loading" class="modal__card">
        <v-card-title
          v-if="!noTitle"
          :style="{ 'background-color': color }"
          class="modal__title headline white--text"
        >
          <div
            :class="{ 'justify-center': centeredTitle }"
            class="modal__title__content d-flex align-center"
          >
            <span v-if="!$slots.title" class="modal__title__span">{{
              title
            }}</span>
            <span v-else class="modal__title__span"
              ><slot name="title"></slot
            ></span>
            <VBtn
              v-if="closable"
              outlined
              rounded
              color="rgba(255,255,255,0.9)"
              icon
              dark
              class="modal__close"
              @click="$emit('input', false)"
            >
              <VIcon size="22">
                {{ $icons.close }}
              </VIcon>
            </VBtn>
          </div>
        </v-card-title>

        <div class="modal__body">
          <div :class="{ 'modal--offset': hasActionsSlot }" class="white">
            <VCardText
              class="modal__content"
              :class="{
                'py-5': !noPadding,
                'pa-0': noPadding,
                'modal--height': !$attrs.fullscreen,
              }"
            >
              <slot></slot>
            </VCardText>
          </div>

          <VCardActions v-if="hasActionsSlot" class="grey lighten-4">
            <VSpacer v-if="!noSpacer"></VSpacer>

            <slot name="actions"></slot>
          </VCardActions>
        </div>
      </v-card>
    </VDialog>
  </div>
</template>

<script>
export default {
  props: {
    centeredTitle: Boolean,

    value: {
      type: Boolean,
      default() {
        return false
      },
    },

    noTitle: {
      type: Boolean,
      default() {
        return false
      },
    },

    noPadding: {
      type: Boolean,
      default() {
        return false
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false
      },
    },

    persistent: {
      type: Boolean,
      default() {
        return false
      },
    },

    closable: {
      type: Boolean,
      default() {
        return true
      },
    },

    color: {
      type: String,
      default() {
        return '#00b87a'
      },
    },

    noRoute: {
      type: Boolean,
      default() {
        return false
      },
    },

    maxWidth: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      default() {
        return 520
      },
    },

    maxHeight: {
      type: String,
      default() {
        return '100%'
      },
    },

    noSpacer: Boolean,
    component: Object,
    title: String,
  },

  data() {
    return {}
  },

  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions
    },

    slug() {
      if (this.title)
        return this.title
          .toLowerCase()
          .split(' ')
          .join('-')
          .normalize('NFD')
          .replace(/[\u0300-\u036F]/g, '')

      return ''
    },
  },

  watch: {
    value(v) {
      if (this.persistent) return

      if (this.noRoute) return

      if (v) {
        this.$router.push(this.$route.fullPath + `#m-${this.slug}`)
      } else if (this.$route.fullPath.includes(`m-${this.slug}`)) {
        this.$router.back()
      }
    },

    $route(nRout, oRout) {
      if (!nRout.fullPath.includes(`m-${this.slug}`)) {
        this.$emit('input', false)
      }
    },
  },

  created() {},

  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="sass">
.v-dialog
  overflow: hidden !important
.modal__card
  max-height: 100vh !important
  height: 100%
  overflow: hidden
  border-radius: 20px
.modal__body
  overflow: auto
  max-height: calc(100vh - 52px)
.modal__title
  .modal__title__content
    width: 100%
.modal__close
  margin-left: auto
.modal__title__span
  overflow-wrap: normal
  word-break: normal
.modal__content
  max-height: calc(100vh - 52px)
  &::-webkit-scrollbar
    width: 0.5em
    height: 0.5em
  &::-webkit-scrollbar-thumb
    background-color: rgba(0,0,0,.3)
    border-radius: 3px
    transition: all 200ms ease
    &:hover
      background: rgba(0,0,0,.5)
.contentpostbody
  max-height: 100% !important
.modal--height
  max-height: 80vh

.dialog-bottom-desktop
  &-enter, &-leave-to
    transform: translateY(80vh)
@media (max-width: 960px)
  .modal__content
    max-height: calc(100vh - 62px)
  .modal--height
    max-height: 100vh
  .modal__title__span
    font-size: 16px
  .modal__card
    border-radius: 0 !important
  .modal__title, .modal__content
    padding: 10px 20px !important

  .dialog-bottom-desktop
    &-enter, &-leave-to
      transform: translateY(65vh)

.dialog-bottom
  &-enter, &-leave-to
    transform: translateY(100%)
</style>
