<template>
  <div>
    <div>
      <VIcon color="primary" size="16">{{ $icons.check }}</VIcon>
      <span class="ml-1"><b>Correções ilimitadas</b></span>
    </div>

    <VTooltip max-width="300" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="mt-1" v-bind="attrs" v-on="on">
          <VIcon color="primary" size="16">{{ $icons.check }}</VIcon>
          <span class="ml-1 wall-tooltip">Deteção avançada de erros</span>
        </div>
      </template>
      <span>
        Veja marcações avançadas de erros no seu texto, como repetição de
        palavras, uso de conectivos, avaliação de frases e parágrafos, e muito
        mais.
      </span>
    </VTooltip>

    <VTooltip max-width="300" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="mt-1" v-bind="attrs" v-on="on">
          <VIcon color="primary" size="16">{{ $icons.check }}</VIcon>
          <span class="ml-1 wall-tooltip">Comentários detalhados</span>
        </div>
      </template>
      <span>
        Em todas correções você receberá um comentário detalhado explicando o
        que você fez de certo e errado, e como melhorar.
      </span>
    </VTooltip>

    <VTooltip max-width="300" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="mt-1" v-bind="attrs" v-on="on">
          <VIcon color="primary" size="16">{{ $icons.check }}</VIcon>
          <span class="ml-1 wall-tooltip">Métricas nota 1000</span>
        </div>
      </template>
      <span>
        Veja como sua redação se compara com as <b>Redações nota 1000</b> das
        edições passadas do ENEM e saiba o que melhorar.
      </span>
    </VTooltip>

    <VTooltip max-width="300" bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="mt-1" v-bind="attrs" v-on="on">
          <VIcon color="primary" size="16">{{ $icons.check }}</VIcon>
          <span class="ml-1 wall-tooltip">Curso <b>Redação de Sucesso</b></span>
        </div>
      </template>
      <span>
        Aulas práticas e objetivas para você aprender a escrever uma redação
        sobre qualquer tema do zero.
      </span>
    </VTooltip>
  </div>
</template>

<script>
export default {
  computed: {
    limits() {
      return this.$store.getters['auth/limits']
    },
  },
}
</script>
