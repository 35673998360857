<template>
  <div class="pencil-blocked d-flex items-center">
    <div class="blocked-content d-flex flex-column align-center justify-center">
      <img src="/images/icons/star.png" class="mb-2" />

      <h2>coRedação <span class="new--text">PREMIUM</span></h2>
      <p>a partir de 12 x {{ price }}/mês</p>

      <p class="mb-0 caption">Assine e tenha acesso a:</p>

      <Benefits class="mb-4" />

      <VBtn depressed class="new" dark @click="openCheckout">
        <b>⭐ Destravar minha redação</b>
      </VBtn>
    </div>
  </div>
</template>

<script>
import Benefits from './Benefits.vue'
export default {
  components: { Benefits },
  computed: {
    price() {
      return this.$store.getters['auth/priceFrom']
    },
  },
  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.wall-tooltip
  text-decoration: underline dotted
.pencil-blocked
  position: relative
  top: 0
  left: 0
  width: 100%
  height: 100%


  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(255,255,255,0.8)
    filter: blur(10px)
  .blocked-content
    position: relative
    z-index: 10
    padding: 10px
    max-width: 400px
    margin: 0 auto

    img
      width: 30px
    h2, p
      text-align: center
</style>
