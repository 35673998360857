<template>
  <div class="my-auto d-flex">
    <div class="signupform__form">
      <div class="signupform__social">
        <div class="d-flex align-center">
          <img class="signupform__image" src="/images/logo_text.png" />
        </div>
        <div class="my-5">
          <h1>Você tá quase lá!</h1>
          <p>
            Crie sua conta e comece sua jornada. Já tem conta?
            <NuxtLink to="/entrar"><b>Acesse sua conta.</b></NuxtLink>
          </p>
        </div>
        <VBtn
          outlined
          rounded
          :disabled="loading"
          block
          color="#F14336"
          :dark="!loading"
          class="mb-2"
          @click="signUpGoogle"
        >
          <img class="signupform__social__icon" src="/images/auth/google.png" />
          <span class="ml-2 signupform__social__text"
            >Cadastrar com Google</span
          >
        </VBtn>
      </div>
      <div class="auth-divider">
        <VDivider class="my-5" />
        <span>ou cadastre com e-mail</span>
      </div>
      <VForm ref="form">
        <VTextField
          v-model="values.fullname"
          label="Nome completo"
          name="name"
          autocomplete="name"
          rounded
          outlined
          dense
          validate-on-blur
          :rules="[rules.required]"
          @keyup.enter="signUp"
        >
          <template #prepend-inner>
            <VIcon class="signupform__icon" size="20">
              {{ $icons.account }}
            </VIcon>
          </template>
        </VTextField>
        <VTextField
          v-model="values.email"
          label="Email"
          rounded
          outlined
          dense
          validate-on-blur
          :rules="[rules.required, rules.email]"
          @keyup.enter="signUp"
        >
          <template #prepend-inner>
            <VIcon class="signupform__icon" size="20">
              {{ $icons.email }}
            </VIcon>
          </template>
        </VTextField>

        <VTextField
          v-model="values.password"
          rounded
          outlined
          dense
          label="Senha"
          autocomplete="new-password"
          validate-on-blur
          :type="password_visible ? 'text' : 'password'"
          :rules="[rules.required, rules.password]"
          @keyup.enter="signUp"
        >
          <template #prepend-inner>
            <VIcon class="signupform__icon" size="20">
              {{ $icons.fingerprint }}
            </VIcon>
          </template>

          <template #append>
            <VBtn
              outlined
              rounded
              small
              icon
              @click="password_visible = !password_visible"
            >
              <VIcon size="20">
                {{ !password_visible ? $icons.eyeOn : $icons.eyeOff }}
              </VIcon>
            </VBtn>
          </template>
        </VTextField>
      </VForm>
      <div class="d-flex">
        <VBtn
          outlined
          rounded
          :loading="loading"
          block
          class="new"
          color="white"
          depressed
          @click="signUp"
          ><b>Criar minha conta</b></VBtn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import { authGoogle } from '../../utils/oauth'

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default {
  data() {
    return {
      password_visible: false,
      values: {
        fullname: '',
        email: '',
        phone: '',
        password: ''
      },
      rules: {
        required: (v) => !!v || 'É necessário preencher esse campo',
        email: (v) => validateEmail(v) || 'Insira um e-mail válido',
        password: (v) =>
          v.length > 5 || 'A senha precisa ter no mínimo 6 dígitos'
      },
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      currentPlan: 'checkout/currentPlan'
    })
  },

  mounted() {
    const next = this.$route.query?.next

    if (next) {
      this.$store.commit('auth/setRedirect', next)
    }
  },

  methods: {
    ...mapMutations({
      signIn: 'auth/signIn'
    }),
    async signUp() {
      if (!this.$refs.form.validate()) return

      this.loading = true
      try {
        const { data } = await this.$axios.post('/users/add', this.values)

        this.$router.push(`/auth/callback?token=${data.token}&created=1`)
      } catch (e) {
        switch (e.response?.status) {
          case 409:
            this.$toast.error('Já existe uma conta cadastrada com esse e-mail.')
            break
          default:
            this.$toast.error(
              'Erro ao criar conta, verifique sua conexão e tente novamente.'
            )
            break
        }
      }

      this.loading = false
    },

    async signUpGoogle() {
      await authGoogle()
    }
  },

  // eslint-disable-next-line vue/order-in-components
  directives: {
    mask
  }
}
</script>

<style lang="sass" scoped>
.signupform
  align-self: start
  background-color: white
  border-radius: 10px
  box-shadow: 2px 2px 5px 0px rgba(214, 214, 214, 0.75)
  border: 1px solid #ddd
  height: auto
  display: inline
  position: relative
  .signupform__form
    padding: 32px
    max-width: 100vw
  p
    font-size: 14px
  h1
    font-size: 22px
  .signupform__image
    max-width: 100%
    height: 28px
  .auth-divider
    position: relative
    height: 70px
    display: flex
    align-items: center
    span
      position: absolute
      background-color: white
      top: 0
      bottom: 0
      height: 20px
      margin: auto
      width: 170px
      text-align: center
      left: 0
      letter-spacing: 0.3px
      font-size: 12px
      right: 0
  .signupform__icon
    margin-top: 2px
    margin-right: 4px
  .signupform__social
    .signupform__social__text
      text-align: left
    .signupform__social__icon
      width: 22px
@media (max-width: 600px)
  .signupform
    max-width: 100%
    width: 100%
    padding: 0
</style>
