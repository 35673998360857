var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VContainer',[_c('v-row',{staticClass:"pricing__container align-center justify-center"},_vm._l((_vm.parsedPlans),function(plan){return _c('v-sheet',{key:plan.planId,staticClass:"pricing pricing__small"},[_c('v-row',{staticClass:"pricing__body align-center flex-column px-5 pt-5",attrs:{"no-gutters":""}},[_c('h2',{staticClass:"text-center"},[_vm._v("Pacote "+_vm._s(plan.quantity)+" Correções")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-center caption"},[_vm._v("\n            Cada redação sai a\n            "),_c('b',{class:{ 'strike mr-1 price-opacity': plan.offer }},[_vm._v("R$ "+_vm._s(_vm.formatMoney(plan.price / plan.quantity)))])]),_vm._v(" "),(plan.offer)?_c('span',{staticClass:"text-center"},[_c('b',[_vm._v("R$\n              "+_vm._s(_vm.formatMoney(
                  (plan.price * ((100 - plan.discount) / 100)) /
                    plan.quantity,
                )))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[(plan.offer)?_c('div',{staticClass:"pricing__price--before pricing__price d-flex align-start mr-2"},[_c('span',[_vm._v(" R$ ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-end"},[_c('h5',{staticClass:"plan-money"},[_vm._v("\n                "+_vm._s(_vm.formatMoney(plan.price / plan.months))+"\n              ")]),_vm._v(" "),_c('h3',{staticClass:"pricing__months"},[_vm._v("x "+_vm._s(plan.months))])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pricing__price d-flex align-start"},[_c('span',[_vm._v(" R$ ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-end"},[(!plan.offer)?_c('h5',{staticClass:"plan-money"},[_vm._v("\n                "+_vm._s(_vm.formatMoney(plan.price / plan.months))+"\n              ")]):_vm._e(),_vm._v(" "),(plan.offer)?_c('h5',{staticClass:"plan-money"},[_vm._v("\n                "+_vm._s(_vm.formatMoney(
                    (plan.price * ((100 - plan.discount) / 100)) /
                      plan.months,
                  ))+"\n              ")]):_vm._e(),_vm._v(" "),_c('h3',{staticClass:"pricing__months"},[_vm._v("x "+_vm._s(plan.months))])])])]),_vm._v(" "),_c('p',{staticClass:"mb-0 mt-1 caption"},[_vm._v("\n          ou à vista por\n          "),(!plan.offer)?_c('b',{class:{ 'strike mr-1 price-opacity': plan.offer }},[_vm._v("R$ "+_vm._s(_vm.formatMoney(plan.price)))]):_vm._e(),_vm._v(" "),(plan.offer)?_c('b',[_vm._v("R$\n            "+_vm._s(_vm.formatMoney(plan.price * ((100 - plan.discount) / 100))))]):_vm._e()]),_vm._v(" "),_c('ul',{staticClass:"features ml-0 pl-0"},[_c('li')]),_vm._v(" "),(plan.offer)?_c('div',{staticClass:"plan-timer"},[_c('VDivider',{staticClass:"mb-2"}),_vm._v(" "),(plan.offer)?_c('plan-timer',{attrs:{"end-date":plan.endDateTime}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('v-row',{staticClass:"pricing__actions justify-center",attrs:{"no-gutters":""}},[_c('VBtn',{attrs:{"outlined":"","rounded":"","large":"","min-height":"60","color":"primary","depressed":"","block":""},on:{"click":function($event){return _vm.goForPlan(plan)}}},[_vm._v("Assinar "),_c('VIcon',{staticClass:"ml-1",attrs:{"size":"16"}},[_vm._v("mdi-arrow-right")])],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }