<template>
  <div class="navitem">
    <NuxtLink :class="classes" :to="href" v-text="title"></NuxtLink>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    href: String,
    inverse: Boolean,
  },

  computed: {
    active() {
      return this.$route.fullPath === this.href
    },

    classes() {
      return {
        'navitem--active': this.active,
        'white--text': this.inverse,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.navitem--active
  font-weight: 600
.navitem
  margin: 0 18px
  a
    font-size: 18px
    text-decoration: none
    color: #2f2f2f
</style>
