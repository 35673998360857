export default {
  async reAuth({ commit }, data) {
    const token = data?.token || localStorage.getItem('cored_token')

    console.log('reauth')

    if (token) {
      console.log('reauth with token')
      try {
        const res = await this.app.$axios.post(`/users/reauth`, {
          token,
        })
        const user = res.data

        if (user && !data?.skipCommit) {
          commit('signIn', user)
        }

        console.log('res', res)

        return user
      } catch (e) {
        console.log(e)
        // commit('signOut')
      }
    }

    return false
  },

  async fetchConfigs({ commit }) {
    try {
      const res = await this.app.$axios.get(`/`)
      const configs = res.data

      commit('setConfigs', configs)
    } catch (e) {
      console.log(e)
    }
  },

  async setPreference({ commit }, payload) {
    try {
      commit('setPreference', payload)
      await this.app.$axios.post(`/users/preferences`, {
        key: payload.key,
        value: payload.value,
      })
    } catch (e) {
      console.log(e)
    }
  },
}
