<template>
  <VDialog max-width="500" :value="shouldShow" class="aff-terms">
    <VCard>
      <VCardTitle> Termos de Afiliados </VCardTitle>
      <VCardText>
        <div>
          <h3>Resumo dos termos</h3>
          <ul>
            <li>
              Você terá um cupom que dará 10% de desconto no plano disponível.
            </li>
            <li>
              Para toda compra usando o seu cupom, você ganhará 10% do valor da
              venda.
            </li>
            <li>
              O pagamento das comissões será feito todo dia 5 de cada mês
              através da chave PIX informada no cadastro do afiliado.
            </li>
            <li>
              Se por algum motivo o usuário pedir estorno ou reembolso do valor,
              você não receberá comissão por essa venda.
            </li>
            <li>
              Você poderá acompanhar as vendas feitas através do seu cupom na
              aba "Afiliados" aqui na plataforma.
            </li>
          </ul>

          <div class="pt-4">
            Os termos completos podem ser lidos
            <a
              href="https://coredacao.com/termos-de-afiliado/"
              target="_blank"
              @click.stop
              >clicando aqui.</a
            >
          </div>
        </div>
        <VCheckbox
          v-model="accept"
          :on-icon="$icons.checkboxOn"
          :off-icon="$icons.checkboxOff"
        >
          <template #label>
            <span
              >Li e concordo com os
              <a
                href="https://coredacao.com/termos-de-afiliado/"
                target="_blank"
                @click.stop
                >Termos de Afiliado do coRedação</a
              ></span
            >
          </template>
        </VCheckbox>
        <VBtn
          depressed
          block
          color="new"
          :dark="accept"
          :disabled="!accept"
          @click="start"
        >
          Aceito os termos de afiliado
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },

  data() {
    return {
      accept: false,
      terms: '',
      loading: true,
    }
  },

  computed: {
    preferences() {
      return this.$store.getters['auth/preferences']
    },

    shouldShow() {
      return !this.preferences?.affiliateTerms
    },
  },

  methods: {
    start() {
      this.$store.dispatch('auth/setPreference', {
        key: 'affiliateTerms',
        value: '1',
      })
    },
  },
}
</script>
