<template>
  <div
    class="correcting-loading d-flex justify-center align-center flex-column"
  >
    <img src="/images/correcting.gif" />

    <h2>Corrigindo sua redação...</h2>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass" scoped>
.correcting-loading
  img
    width: 140px
</style>
