import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')

Vue.use({
  install: function (Vue, options) {
    Vue.mixin({
      methods: {
        $dayjs(dt) {
          return dayjs(dt)
        },
      },
    })
  },
})
