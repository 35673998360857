<template>
  <div class="account">
    <AppNav image="/images/dashboard/icons/settings.svg" title="Minha conta">
    </AppNav>
    <div class="account__settings">
      <Settings />
    </div>
  </div>
</template>

<script>
import Settings from '~/components/settings/Settings.vue'
import AppNav from '~/components/app/AppNav.vue'

export default {
  components: {
    Settings,
    AppNav,
  },
  head() {
    return {
      title: 'Configurações',
    }
  },
  middleware: 'requireAuth',

  layout: 'app',
}
</script>

<style lang="sass" scoped>
@media screen and (max-width: 960px)
  .account
    width: 100%
</style>
