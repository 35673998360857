<template>
  <div>
    <div class="mb-4">
      <h3 class="secondary--text">Assinatura</h3>
      <span v-if="isSubscribed"
        >Assinatura premium ativa até <b>{{ subDate }}</b></span
      >
      <span v-else
        >Membro normal
        <VBtn
          outlined
          rounded
          @click="$root.$emit('openCheckout')"
          small
          depressed
          color="primary"
          >Seja premium</VBtn
        ></span
      >
    </div>
    <h3 class="secondary--text">Dados gerais</h3>
    <VDivider class="my-4" />
    <v-form ref="form">
      <div class="form__group">
        <div class="form__item">
          <VTextField
            :disabled="loading"
            :rules="[rules.required]"
            v-model="values.fullname"
            outlined
            rounded
            dense
            label="Nome completo"
          ></VTextField>
        </div>
        <div class="form__item">
          <VTextField
            :disabled="true"
            v-model="currentUser.email"
            outlined
            rounded
            dense
            label="E-mail"
          ></VTextField>
        </div>
      </div>
    </v-form>
    <VDivider class="mb-4"></VDivider>
    <VBtn
      outlined
      rounded
      :loading="loading"
      @click="save"
      color="primary"
      depressed
      >Salvar dados</VBtn
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import dayjs from 'dayjs'

export default {
  data: function () {
    return {
      values: {
        fullname: '',
        phone: '',
      },
      loading: false,
      rules: {
        required: (v) => !!v || 'É necessário preencher esse campo',
        password: (v) =>
          (!!v && v.length > 5) || 'A senha precisa ter no mínimo 6 dígitos',
      },
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isSubscribed: 'auth/isSubscribed',
      hasEssaysEnabled: 'auth/hasEssaysEnabled',
    }),
    subDate() {
      if (this.currentUser !== null) {
        return dayjs(this.currentUser.subscriptionDate).format('DD/MM/YYYY')
      }
    },
  },

  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          const { data } = await this.$axios.put('/users/data', this.values)

          this.$toast.success('Dados atualizados com sucesso!')
        } catch (e) {}
        this.loading = false
      }
    },
  },

  created() {
    if (!!this.currentUser) {
      const user = Object.assign({}, this.currentUser)

      this.values = {
        fullname: user.fullname,
        phone: user.phone,
      }
    }
  },

  directives: {
    mask,
  },
}
</script>

<style lang="sass">
.user-coins
  width: 20px
  margin-right: 4px
.form__group
  display: flex
  justify-content: space-between
  .form__item
    width: calc(50% - 12px)

@media (max-width: 900px)
  .form__group
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .form__item
      width: 100%
</style>
