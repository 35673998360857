<template>
  <div class="text-center">
    <h2 class="mb-2">
      {{ title }}
    </h2>
    <div class="welcome-subtitle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="sass">
.welcome-subtitle
  width: 600px
  max-width: 100%
  margin: 0 auto
  text-align: center
</style>
