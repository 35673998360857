<template>
  <div>
    <div class="celebration-image d-flex justify-center">
      <img src="~/assets/images/celebration.png" />
    </div>
    <WelcomeHeader title="Que bom te ver por aqui">
      A partir de agora você terá correções de redações com notas precisas,
      comentários detalhados e conteúdos objetivos para aumentar sua nota.
    </WelcomeHeader>
  </div>
</template>

<script>
import WelcomeHeader from './WelcomeHeader.vue'

export default {
  components: {
    WelcomeHeader
  }
}
</script>

<style lang="sass">
.celebration-image img
  width: 500px
  max-width: 100%
</style>
