<template>
  <div>
    <nav-drawer :items="items" :open="drawer" @change="onDrawerChange" />
    <div
      :class="{
        'navbar--fixed': this.fixed,
        'navbar--visible': this.fixed && this.fixedVisible,
      }"
      class="navbar d-flex align-center"
    >
      <VContainer class="d-flex">
        <div class="navbar__logo d-flex align-center">
          <NuxtLink to="/">
            <img alt="coRedação Logo" src="@/assets/images/logo.png" />
          </NuxtLink>
        </div>
        <div class="hidden-sm-and-down navbar__items align-center">
          <nav-item
            :inverse="inverse"
            :title="item.title == 'home' ? homeTitle : item.title"
            :href="item.href === '/' ? homePath : item.href"
            v-for="(item, i) in items"
            :key="i"
          />
        </div>
        <VSpacer />
        <div v-if="!isLoggedIn" class="navbar__buttons hidden-sm-and-down">
          <nav-buttons :inverse="inverse" />
        </div>
        <div v-else class="navbar__user">
          <nav-user />
        </div>
        <div class="navbar__drawer hidden-md-and-up">
          <VBtn
            outlined
            rounded
            icon
            :color="menuIconColor"
            @click="switchDrawer"
          >
            <VIcon>{{ $icons.menu }}</VIcon>
          </VBtn>
        </div>
      </VContainer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import NavItem from './NavItem'
import NavButtons from './NavButtons'
import NavUser from './NavUser'
import NavItems from '@/constants/navItems'
import NavDrawer from './NavDrawer'

export default {
  data: function () {
    return {
      drawer: false,
      lastT: 0,
      fixed: false,
      fixedVisible: false,
      scroll: null,
    }
  },

  methods: {
    switchDrawer() {
      this.drawer = !this.drawer
    },

    onDrawerChange(v) {
      this.drawer = v
    },

    onScroll() {
      const t = window.scrollY

      // if(t < 100) this.fixed = false
      // else if(Math.abs(t - this.lastT) > 100){
      //   const d = t - this.lastT
      //   this.lastT = t

      //   this.fixed = t > 150
      //   this.fixedVisible = t > 150 && d < - 50
      // }

      this.fixed = t > 150
      this.fixedVisible = t > 150
    },
  },

  destroyed() {
    document.removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    //this.scroll = document.addEventListener("scroll", this.onScroll, {passive: true});
  },

  computed: {
    ...mapGetters({
      isSubscribed: 'auth/isSubscribed',
      currentUser: 'auth/currentUser',
      isLoggedIn: 'auth/isLoggedIn',
    }),

    items() {
      if (this.isLoggedIn && this.currentUser.type === 'CLIENT') {
        return NavItems.client
      }

      return NavItems.noLogged
    },

    menuIconColor() {
      return (this.$route.name === 'temas-de-redacao-id' ||
        this.$route.name === 'conteudo-id') &&
        !this.fixed
        ? 'white'
        : 'black'
    },

    inverse() {
      return (
        this.$route.fullPath.includes('/apostila/') &&
        !this.$route.fullPath.includes('/apostila/cadastrar') &&
        !this.fixed
      )
    },

    homePath() {
      return '/'
    },

    homeTitle() {
      if (this.isLoggedIn) return 'Área do Aluno'

      return 'Início'
    },
  },

  components: {
    NavItem,
    NavButtons,
    NavDrawer,
    NavUser,
  },
}
</script>

<style lang="sass" scoped>
.navbar--shadow
  box-shadow: 0 1px 3px 0 rgba(21,27,38,.15)
.navbar
  height: 72px
  position: absolute
  width: 100vw
  z-index: 5
  transition: all 150ms ease-in-out
  .navbar__items
    display: flex
  .navbar__logo
    margin-right: 12px
    img
      width: 30px
  .navbar__buttons, .navbar__drawer, .navbar__user
    margin-left: auto
    display: flex
    align-items: center

.navbar--fixed
  position: fixed
  top: -68px
  background-color: white
  box-shadow: 0 10px 10px rgba(0,0,0,.08), 0 0 0 transparent

.navbar--visible
  top: 0

@media (max-width: 960px)
  .navbar
    height: 52px
    .navbar__drawer
      margin-left: 8px
@media (max-width: 600px)
  .navbar
    .navbar__user
      display: none
    .navbar__drawer
      margin-left: auto
</style>
