import { API_URL } from '../constants/api'

let windowObjectReference = null
let previousUrl = null
let onOAuthComplete = null

const receiveMessage = (event) => {
  try {
    if (event.data.source === 'cored_auth') {
      if (onOAuthComplete != null) {
        onOAuthComplete(event.data.payload)
      }
    }
  } catch (e) {}
}

const openSignInWindowCallback = (url, onComplete) => {
  const fullUrl = url
  const windowLeftPosition = Math.ceil(document.documentElement.clientWidth / 2)

  window.removeEventListener('message', receiveMessage)
  onOAuthComplete = onComplete

  // window features
  const strWindowFeatures = `toolbar=no, menubar=no, width=600, height=700, top=100, left=${windowLeftPosition}`

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
    or if such pointer exists but the window was closed */
    windowObjectReference = window.open(
      fullUrl,
      'oauth_handler',
      strWindowFeatures,
    )
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
    then we load it in the already opened secondary window and then
    we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(
      fullUrl,
      'oauth_handler',
      strWindowFeatures,
    )
    if (windowObjectReference != null) windowObjectReference.focus()
  } else {
    /* else the window reference must exist and the window
    is not closed; therefore, we can bring it back on top of any other
    window with the focus() method. There would be no need to re-create
    the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }

  // assign the previous URL
  previousUrl = url

  // Create the event listener
  window.addEventListener('message', receiveMessage, true)
}

const openSignInWindow = (url) => {
  return new Promise((resolve) => {
    openSignInWindowCallback(url, (data) => {
      resolve(data)
    })
  })
}

export const authGoogle = () => {
  const googleAuthUrl = `${API_URL}users/google`

  setTimeout(function () {
    document.location.href = googleAuthUrl
  }, 10)
}
