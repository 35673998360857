<template>
  <div class="lesson-items">
    <LessonItem
      v-for="lesson in value"
      :key="lesson.id"
      :blocked="blocked"
      :value="lesson"
    />
  </div>
</template>

<script>
import LessonItem from './LessonItem.vue'

export default {
  components: {
    LessonItem,
  },

  props: {
    value: Array,
    blocked: Boolean,
  },
}
</script>

<style lang="sass">
.lesson-items
  padding: 24px
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  row-gap: 40px
  column-gap: 20px
  min-height: 150px

  .item-container
    &:nth-child(3n+3)
      .lesson-popover
        transform: translate(-80%,104%)
    &:nth-child(3n+1)
      .lesson-popover
        transform: translate(-30%,104%)

    @media screen and (max-width: 700px)
      &:nth-child(2n+1)
        .lesson-popover
          transform: translate(-25%,104%)
      &:nth-child(2n+2)
        .lesson-popover
          transform: translate(-75%,104%)
@media screen and (max-width: 700px)
  .lesson-items
    grid-template-columns: 1fr 1fr
    padding: 24px 12px
    .lesson-title
      h4
        font-size: 16px
</style>
