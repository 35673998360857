<template>
  <div class="essay-list">
    <VCard class="mx-auto mt-2 mb-2" max-width="100%" outlined>
      <VListItem three-line>
        <VListItemContent>
          <div v-if="!loading">
            <div v-for="(essay, i) in showedEssays" :key="essay.id">
              <div class="essay-item accent--text">
                <VLayout
                  v-ripple="{ class: 'primary--text' }"
                  align-center
                  @click="goToEssay(essay)"
                >
                  <div
                    v-if="
                      essay.corrections &&
                      essay.corrections.length > 0 &&
                      essay.corrections[0] &&
                      essay.corrections[0].grades
                    "
                    :style="{
                      backgroundColor: getGrade(essay.corrections[0].grades)
                        .color,
                    }"
                    class="correction-grade"
                    @click="goToEssay(essay)"
                  >
                    <span>{{
                      getGrade(essay.corrections[0].grades).value
                    }}</span>
                  </div>
                  <div v-else @click="goToEssay(essay)">
                    <VImg
                      lazy-src="/images/noimg.png"
                      class="essay-item__image"
                      height="52"
                      width="52"
                      :src="essay.theme.feature_image"
                    />
                  </div>
                  <div
                    class="w-full d-flex flex-column align-start"
                    @click="goToEssay(essay)"
                  >
                    <div class="title mr-2">
                      {{ essay.title ? essay.title : 'Sem título' }}
                    </div>
                    <div class="body-2 mb-2">{{ essay.theme.title }}</div>
                    <VListItem-subtitle
                      >Última atualização
                      {{ formatDate(essay.updatedAt) }}</VListItem-subtitle
                    >
                  </div>

                  <div class="essaylistmenu">
                    <VMenu bottom offset-y left>
                      <template v-slot:activator="{ on, attrs }">
                        <VBtn icon v-bind="attrs" v-on="on">
                          <VIcon>{{ $icons.menu }}</VIcon>
                        </VBtn>
                      </template>

                      <VList>
                        <VListItem v-if="false" @click="goToEssay(essay)">
                          <VListItemTitle>
                            <VIcon>{{ $icons.write }}</VIcon>
                            Editar
                          </VListItemTitle>
                        </VListItem>
                        <VListItem @click="onDelete(essay)">
                          <VListItemTitle>
                            <VIcon>{{ $icons.delete }}</VIcon>
                            Remover
                          </VListItemTitle>
                        </VListItem>
                      </VList>
                    </VMenu>
                  </div>
                </VLayout>
              </div>

              <VDivider v-if="i < showedEssays.length - 1" class="my-2" />
            </div>
          </div>
          <div v-else>
            <div v-for="i in 3" :key="i">
              <div class="d-flex">
                <VSkeletonLoader
                  class="essay-item__image"
                  width="66"
                  height="50"
                  type="image"
                ></VSkeletonLoader>
                <div class="loading__texts">
                  <VSkeletonLoader
                    width="100%"
                    max-width="600"
                    height="22"
                    type="image"
                  ></VSkeletonLoader>
                  <VSkeletonLoader
                    class="mt-2"
                    width="50%"
                    height="14"
                    type="image"
                  ></VSkeletonLoader>
                </div>
              </div>
              <VDivider v-if="i < 3" class="my-2" />
            </div>
          </div>
          <VAlert
            v-if="essays.length === 0 && !loading"
            class="ma-0"
            color="info"
            border="left"
            colored-border
          >
            {{ emptyText }}
          </VAlert>
        </VListItemContent>
      </VListItem>
    </VCard>

    <div
      v-if="!showDrafted && essays.length > 2"
      class="show-btn d-flex justify-center mb-5"
      @click="showDrafted = true"
    >
      <VBtn outlined depressed rounded small color="accent">
        + Mostrar mais
      </VBtn>
    </div>

    <Modal v-model="removeModal" title="Remover redação">
      <div>Essa ação não poderá ser desfeita.</div>

      <template #actions>
        <VBtn
          text
          rounded
          :disabled="deleting"
          depressed
          @click="removeModal = false"
          >Cancelar</VBtn
        >
        <VBtn
          outlined
          rounded
          :loading="deleting"
          color="white"
          min-width="120"
          class="red px-4"
          depressed
          @click="deleteEssay(selectedEssay)"
          >Remover redação</VBtn
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Modal from '../helpers/Modal.vue'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
  components: {
    Modal,
  },

  props: {
    essays: {
      type: Array,
      default() {
        return []
      },
    },
    emptyText: {
      type: String,
      default: 'Você ainda não tem redações corrigidas',
    },
    loading: Boolean,
  },

  data() {
    return {
      showWaiting: false,
      showCorrected: false,
      showSaved: false,
      showDrafted: false,
      removeModal: null,
      selectedEssay: null,
      deleting: false,
    }
  },

  computed: {
    showedEssays() {
      return this.showDrafted ? this.essays : this.essays.slice(0, 2)
    },
  },

  methods: {
    async deleteEssay(essay) {
      try {
        this.deleting = true
        await this.$store.dispatch('essays/deleteEssay', essay)
        this.removeModal = false
        this.deleting = false
      } catch (e) {
        this.deleting = false
        this.$toast.error('Não foi possível remover a redação')
      }
    },
    onDelete(essay) {
      this.removeModal = true
      this.selectedEssay = essay
    },
    goToEssay(essay) {
      if (essay.state === 'FINISHED') {
        this.$router.push(
          `/temas/?id=${essay.id}&theme=${essay.theme.slug}&correction=1`,
        )
        return
      }

      this.$router.push(`/temas/?id=${essay.id}&theme=${essay.theme.slug}`)
    },
    getGrade(grades) {
      const gradeValue = grades.reduce((acc, curr) => acc + curr, 0)

      const gradeColor =
        gradeValue < 400
          ? 'red'
          : gradeValue < 600
            ? 'orange'
            : gradeValue < 800
              ? 'blue'
              : 'green'

      return {
        value: gradeValue,
        color: gradeColor,
      }
    },

    formatDate(val) {
      return dayjs(val).fromNow()
    },
  },
}
</script>

<style lang="sass">
.correction-grade
  min-width: 52px
  min-height: 52px
  margin-right: 10px
  border-radius: 10px
  color: white
  display: flex
  align-items: center
  justify-items: center
  font-weight: bold
  font-size: 18px
  span
    margin: 0 auto
.show-btn
  transform: scale(0.8)
.round
  border-radius: 5px
.loading__texts
  width: 100%
  padding-left: 18px
  max-width: 700px
.essay-list__title
  font-size: 1px !important
  text-transform: uppercase
  letter-spacing: 0.5px
.essay-list
  .title
    font-size: 16px !important
    line-height: 21px
    font-weight: bold
  .VListItem__subtitle
    font-size: 12px !important
    line-height: 14px !important
.essay-item
  cursor: pointer
  position: relative
  .essay-list__result
    height: 50px
    display: flex
    justify-content: center
    align-items: center
    font-size: 24px
    font-weight: 700
    margin-right: 18px
    border-radius: 10px
    color: white
    width: 66px
  .essay-item__image
    border-radius: 10px
    margin-right: 18px
  .layout
    padding: 6px 0
    border-radius: 10px
    &:hover
      background-color: #fafafa !important

.essaylistmenu
  position: absolute
  right: 12px

@media screen and (max-width: 900px)
  .essay-item
    .layout
      padding: 6px 0
    .title
      font-size: 14px !important
      line-height: 19px !important
      font-weight: bold
      margin-bottom: 8px
      white-space: initial !important
    .essay-item__image
      margin-right: 10px
</style>
