const DAY = 24 * 60 * 60

function setClient(cname, cvalue, days = 7) {
  var d = new Date()
  d.setTime(d.getTime() + days * DAY)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function setServer(res) {
  return (cname, cvalue, days = 7) => {
    const cData = `${cname}=${cvalue}; Path=/; Max-Age=${days * DAY}`

    res.setHeader('Set-Cookie', [cData])

    //console.log(cData)
  }
}

function getServer(req, res) {
  return (cname) => {
    if (!req.headers.cookie) return

    const cookie = req.headers.cookie

    //console.log(req.headers.cookie)
    const cookies = cookie.split('; ')

    for (let i = 0; i < cookies.length; i++) {
      const c = cookies[i].split('=')

      if (c[0] === cname) return decodeURIComponent(c[1])
    }
  }
}

function getClient(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default (context, inject) => {
  const cookie = {
    set: process.client ? setClient : setServer(context.res),
    get: process.client ? getClient : getServer(context.req),
    del: (cname) => {
      if (process.client) {
        return setClient(cname, '', -1)
      } else {
        return setServer(context.res)(cname, '', -1)
      }
    },
  }

  inject('cookie', cookie)
  context.$hello = cookie
}
