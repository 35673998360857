import { API_URL } from '~/constants/api'

export const actions = {
  async nuxtClientInit({ dispatch, commit }, { query, redirect }) {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      commit('system/setInstallEvent', e)

      console.log('before installll')
    })

    const redirectPath = this.app.$cookie.get('cored_redirect')

    if (!!query.ref && !this.app.$cookie.get('ref')) {
      this.app.$cookie.set('ref', query.ref)
    }

    if (redirectPath) {
      commit('auth/setRedirect', redirectPath)
    }

    this.app.$axios.setBaseURL(API_URL)

    const reAuth = await dispatch('auth/reAuth')
    if (reAuth) {
      await dispatch('auth/fetchConfigs')
    }
  },
}
