<template>
  <div class="login d-flex align-center justify-center">
    <SignUpForm class="signupform" />
  </div>
</template>

<script>
import SignUpForm from '../components/auth/SignUpForm.vue'

export default {
  components: {
    SignUpForm,
  },

  head() {
    return {
      title: 'Criando conta',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'Curso de redação e correção de redação online.',
        },
      ],
    }
  },
}
</script>

<style lang="sass" scoped>
.login
  min-height: calc(100vh - 40px)
  height: 100vh
  position: relative
</style>
