<template>
  <div>
    <WelcomeHeader title="Como você descobriu o coRedação?">
      Última pergunta. Obrigado!
    </WelcomeHeader>

    <Chooser
      v-model="source"
      class="mt-4"
      :options="[
        {
          value: 'GOOGLE',
          name: 'Google'
        },
        {
          value: 'REDE_SOCIAL',
          name: 'Rede social'
        },
        {
          value: 'INDICACAO_PROFESSOR',
          name: 'Indicação de professor'
        },
        {
          value: 'INDICACAO_AMIGO',
          name: 'Indicação de amigo'
        },
        {
          value: 'OUTRO',
          name: 'Outro'
        }
      ]"
    />
  </div>
</template>

<script>
import WelcomeHeader from './WelcomeHeader.vue'
import Chooser from './Chooser.vue'

export default {
  components: {
    WelcomeHeader,
    Chooser
  },

  computed: {
    source: {
      get() {
        return this.$store.state.welcome.source
      },
      set(value) {
        this.$store.commit('welcome/setSource', value)
      }
    }
  }
}
</script>

<style lang="sass"></style>
