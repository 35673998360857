<template>
  <div v-click-outside="onOutside" class="item-container">
    <v-hover v-slot="{ hover }">
      <div
        class="lesson-item"
        :class="{ 'lesson-item--hover': hover || popover }"
        @click="onClick"
      >
        <div
          :style="{
            'background-color': started ? `rgba(255, 99, 56, 1)` : '#D8D8D8',
          }"
          class="lesson-content"
        >
          <img :src="icon" />
        </div>
        <div v-if="isFinished" class="lesson-stars">
          <img src="/images/icons/star.png" />
        </div>
        <VProgressCircular
          :size="80"
          :width="5"
          :value="percentage"
          :rotate="-90"
          :color="`rgba(255, 99, 56, ${percentage / 100})`"
          class="item-progress"
        ></VProgressCircular>

        <v-fade-transition>
          <div v-show="popover" ref="popover" class="lesson-popover">
            <h4>
              {{ value.title }}
            </h4>
            <p>
              {{ value.description }}
            </p>
            <VBtn
              v-if="!blocked"
              color="#FF6338"
              class="ma-0 bg-white"
              block
              rounded
              outlined
              @click="openLesson"
            >
              <b v-if="!value.blog" class="font-bold">
                Assistir <img class="star" src="/images/icons/star.png" />
              </b>
              <b v-else class="font-bold"> Ler lição 📖 </b>
            </VBtn>
            <VAlert v-else class="text-center">
              Conteúdo exclusivo para assinantes do
              <b>coRedação <span class="new--text">PREMIUM</span></b
              >. <br />
              <VBtn
                depressed
                rounded
                color="new"
                dark
                block
                class="mt-4"
                @click="openCheckout"
              >
                <b>⭐ Liberar conteúdo</b>
              </VBtn>
            </VAlert>
          </div>
        </v-fade-transition>
      </div>
    </v-hover>
    <div class="lesson-title mt-1" @click="onClick">
      <h4 class="font-bold">
        {{ value.title }}
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    blocked: Boolean,
  },

  data() {
    return {
      popover: false,
    }
  },

  computed: {
    icon() {
      return this.blocked ? '/images/icons/padlock.svg' : this.value.icon
    },
    preferenceKey() {
      return `blog-lesson-${this.value.id}`
    },
    preferenceValue() {
      const val = this.$store.getters['auth/preferences'][this.preferenceKey]
      return val ? parseInt(val) : 0
    },

    isFinished() {
      if (this.value.blog) {
        return this.preferenceValue === 2
      }

      return (
        this.value.userStatus?.state === 'FINISHED' ||
        this.value.userStatus?.percentageWatched > 90 ||
        this.$store.getters['course/lessonsProgress'][this.value.id]
          ?.percentageWatched > 90
      )
    },

    percentage() {
      if (this.value.blog) {
        return this.preferenceValue * 50
      }

      return (
        this.$store.getters['course/lessonsProgress'][this.value.id]
          ?.percentageWatched || this.value.userStatus?.percentageWatched
      )
    },

    started() {
      if (this.value.blog) {
        return this.preferenceValue > 0
      }

      const state = this.value.userStatus?.state
      const hasClientState =
        this.$store.getters['course/lessonsProgress'][this.value.id]
      return (state && state !== 'NOT_STARTED') || hasClientState
    },
  },

  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
    onOutside() {
      this.popover = false
    },

    onClick() {
      if (this.popover) {
        this.popover = false
        return
      }

      this.popover = true
      this.$nextTick(() => {
        const bounds = this.$refs.popover.getBoundingClientRect()

        const h = bounds.height + bounds.top + window.pageYOffset
        const s = window.pageYOffset + window.innerHeight

        const offset = this.$vuetify.breakpoint.mobile ? 100 : 20
        const atLeast = h + offset - s

        if (atLeast > 0) {
          this.$vuetify.goTo(window.pageYOffset + atLeast)
        }
      })
    },

    openLesson() {
      this.$root.$emit('openLesson', this.value)
    },
  },
}
</script>

<style lang="sass" scoped>
.lesson-item
  width: 80px
  height: 80px
  border-radius: 100%
  cursor: pointer
  padding: 8px
  position: relative
  transition: all 150ms ease-in-out
  position: relative

  .item-progress
    position: absolute
    top: 0
    left: 0

  .lesson-popover
    padding: 20px
    position: absolute
    background: #FF6338
    border-radius: 20px
    border: 1px solid #fff
    width: 350px
    bottom: 0
    color: white
    cursor: default
    left: 50%
    z-index: 5
    transform: translate(-50%,104%)
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px
    h4
      font-size: 18px
    p
      font-size: 16px
      margin-top: 6px
    .star
      width: 14px
      margin-left: 2px
    @media screen and (max-width: 700px)
      width: 360px
      max-width: 100vw
  .lesson-content
    border-radius: 100%
    height: 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    transition: all 150ms ease-in-out
    img
      width: 34px
  .lesson-stars
    position: absolute
    left: 50%
    top: 4px
    transform: translate(-50%, -50%)
    z-index: 10
    img
      transition: all 150ms ease-in-out
      width: 20px
      height: 20px

.item-container
  display: flex
  flex-grow: 0
  flex-direction: column
  align-items: center
.lesson-title
  max-width: 180px
  h4
    font-size: 16px
    text-align: center
    font-weight: bold

.lesson-item--hover
  .lesson-content
    background-color: rgba(216, 216, 216, 0.5) !important
    img
      width: 38px
      animation: hovering 800ms ease-in-out infinite

@keyframes hovering
  0%
    transform: translateY(0)
  50%
    transform: translateY(-4px)
  100%
    transform: translateY(0)
</style>
