<template>
  <div class="navbuttons">
    <NuxtLink v-if="!isLoggedIn" to="/entrar/">
      <VBtn
        outlined
        rounded
        class="font-weight-bold"
        @click="trackLogIn"
        aria-label="Entrar"
        large
        :color="inverse ? 'white' : 'primary'"
        text
        >Já sou aluno</VBtn
      >
    </NuxtLink>

    <NuxtLink v-if="!isLoggedIn || currentUser.credits === 0" to="/#planos">
      <VBtn
        outlined
        rounded
        :dark="!inverse"
        @click="trackSignUp"
        aria-label="Cadastrar"
        class="navbuttons__cta"
        min-width="125"
        depressed
        large
        :color="inverse ? 'white' : '#F95D4B'"
        >Planos</VBtn
      >
    </NuxtLink>
    <NuxtLink v-if="isLoggedIn" to="/">
      <VBtn
        outlined
        rounded
        class="font-weight-bold"
        @click="trackLogIn"
        aria-label="Entrar"
        large
        depressed
        :color="inverse ? 'white' : 'primary'"
        >Painel Aluno</VBtn
      >
    </NuxtLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    inverse: Boolean,
  },

  methods: {
    trackSignUp() {
      try {
        this.$vuetify.goTo('#planos')
      } catch (e) {}
    },

    trackLogIn() {},
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
}
</script>

<style lang="sass">
.navbuttons
  .navbuttons__cta
    margin-left: 8px
    .v-btn__content
      font-size: 1.05em !important
      font-weight: bold
@media (max-width: 960px)
  .navbuttons
    display: flex
    flex-direction: column-reverse
    padding: 0 16px
    .VBtn
      margin: 0
      margin-bottom: 8px
      width: 100%
</style>
