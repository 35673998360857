import { render, staticRenderFns } from "./TextSerif.vue?vue&type=template&id=7c0c811e&scoped=true&"
import script from "./TextSerif.vue?vue&type=script&lang=js&"
export * from "./TextSerif.vue?vue&type=script&lang=js&"
import style0 from "./TextSerif.vue?vue&type=style&index=0&id=7c0c811e&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c0c811e",
  null
  
)

export default component.exports