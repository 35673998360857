import ContentAPI from '@/plugins/ghost'

export default {
  async loadTags({ state, commit }) {
    commit('setLoading', true)
    if (state.tags !== null) {
      const tags = await ContentAPI.tags.browse()

      commit('setTags', tags)
    }
    commit('setLoading', false)
  },
}
