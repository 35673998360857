import dayjs from 'dayjs'

export default {
  totalFinished(state, getters) {
    return getters.finished.length
  },

  totalInMonth(state, getters) {
    const inMonth = getters.finished.filter((essay) => {
      return dayjs(essay.finishedAt).isSame(dayjs(), 'month')
    })

    return inMonth?.length ?? 0
  },

  finished(state) {
    return state.essays.filter((essay) => essay.state === 'FINISHED')
  },

  draft(state) {
    return state.essays.filter((essay) => essay.state === 'DRAFT')
  },

  currentEssayId(state) {
    return state.currentEssayId
  },

  statistics(state) {
    const totals = {
      c1: 0,
      c2: 0,
      c3: 0,
      c4: 0,
      c5: 0,
    }

    let numberOfCorrections = 0

    state.essays.forEach((essay) => {
      if (essay.state === 'FINISHED' && essay.corrections?.length > 0) {
        const grades = essay.corrections[0]?.grades

        numberOfCorrections += 1
        totals.c1 += grades[0]
        totals.c2 += grades[1]
        totals.c3 += grades[2]
        totals.c4 += grades[3]
        totals.c5 += grades[4]
      }
    })

    const averages = [0, 0, 0, 0, 0]

    if (numberOfCorrections > 0) {
      averages[0] = parseInt(totals.c1 / numberOfCorrections)
      averages[1] = parseInt(totals.c2 / numberOfCorrections)
      averages[2] = parseInt(totals.c3 / numberOfCorrections)
      averages[3] = parseInt(totals.c4 / numberOfCorrections)
      averages[4] = parseInt(totals.c5 / numberOfCorrections)
    }

    return {
      averages,
      corrections: numberOfCorrections,
      total: Object.values(averages).reduce((a, b) => a + b, 0),
    }
  },
}
