<template>
  <div class="settings">
    <VTabs v-model="tab" round color="primary" background-color="transparent">
      <VTab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </VTab>
    </VTabs>

    <VTabs-items v-model="tab" class="settings__items mt-4 elevation-1">
      <VTab-item v-for="item in items" :key="item.tab">
        <div>{{ item.content }}</div>
        <component :is="item.component" />
      </VTab-item>
    </VTabs-items>
  </div>
</template>

<script>
import SettingsSecurity from './SettingsSecurity.vue'
import SettingsGeneral from './SettingsGeneral.vue'
import SettingsPayments from './SettingsPayments.vue'

export default {
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Geral', component: SettingsGeneral },
        { tab: 'Pagamentos', component: SettingsPayments },
        { tab: 'Alterar senha', component: SettingsSecurity },
      ],
    }
  },
}
</script>

<style lang="sass" scoped>
.settings
  .settings__items
    border-radius: 8px
    padding: 16px
</style>
