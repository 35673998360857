<template>
  <div ref="contentpost" class="contentpostbody__content">
    <div v-if="!$slots.content" ref="content" v-html="value.html"></div>
    <div v-else>
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },

  data() {
    return {
      intersecteds: {
        33: false,
        66: false,
        100: false,
      },
    }
  },

  mounted() {
    if (!window.instgrm) {
      console.log('embedding instagram')
      const s = document.createElement('script')
      s.src = 'https://www.instagram.com/embed.js'

      document.getElementsByTagName('head')[0].appendChild(s)
    } else {
      window.instgrm.Embeds.process()
    }

    this.handleClicks()
  },

  methods: {
    handleClicks() {
      const links = Array.from(this.$refs.content.querySelectorAll('a'))

      links.forEach((link) => {
        const href = link.href
        const isExternal = !href.includes('coredacao')
        if (isExternal) {
          console.log('external link')
          link.target = '_blank'
        }
        link.addEventListener('click', (e) => {
          if (!isExternal) {
            e.preventDefault()

            const slug = href
              .split('/')
              .filter((p) => !!p)
              .pop()

            this.$root.$emit('openContent', {
              slug,
            })
          }
        })
      })
    },
  },
}
</script>

<style lang="sass">
.tracking
  position: absolute
  z-index: -10
  width: 100%
  height: 20px
.tracking--33
  top: 33%
.tracking--66
  top: 66%
.tracking--100
  bottom: 0

.contentpostbody__content
  box-sizing: border-box
  max-width: 680px !important
  overflow-wrap: break-word
  h2
    margin: .5em 0 .2em
    font-size: 28px
    padding-top: 28px
    padding-bottom: 12px
    color: #090a0b
    line-height: 1.1
    font-weight: 700
  h3
    color: #090a0b
    font-size: 22px
    line-height: 1.1
    padding-top: 12px
    padding-bottom: 12px
  p, center, i, li, ul
    font-size: 18px
    color: #192124
    margin-bottom: 1.6em
  ul
    margin-bottom: 12px
  .kg-embed-card
    position: relative
    iframe
      max-width: 100vw !important
      aspect-ratio: 16 / 9
      width: 100%
      height: 100%
  a
    font-size: 18px
    img
      display: none
  .kg-card
    display: flex
    flex-direction: column
    align-items: center
  figure, img, a, p, strong, em, center, i
    box-sizing: border-box
    max-width: 100% !important
  figure, img, a, p, strong, em, center, i
    margin-bottom: 2em
  blockquote
    background: rgba(0,184,122,0.03)
    border-left: 10px solid #00B87A
    margin: 0.5em 0
    padding: 0.9em 14px
    border-radius: 5px
    margin-bottom: 2em
    white-space: pre-line

  blockquote:before
    color: #00B87A
    font-size: 4em
    line-height: 0.1em
    margin-right: 0.25em
    vertical-align: -0.4em

  blockquote p
    display: inline

@media (max-width: 960px)
  .contentpostbody__content
    max-width: 100%
    overflow-wrap: break-word
    word-wrap: break-word

    -ms-word-break: break-all
    word-break: break-all
    word-break: break-word

    max-width: 100% !important
    p, li, a
      font-size: 18px
    li
      margin-bottom: 5px
    h2
      font-size: 25px
      line-height: 28px
      padding-top: 14px
      padding-bottom: 8px
    h3
      font-size: 21px
      line-height: 24px
    blockquote
      font-size: 14px
</style>
