<template>
  <div class="empty-app">
    <nuxt />
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass">
.empty-app
  background-color: #fff9f5 !important
</style>
