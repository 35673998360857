<template>
  <v-fade-transition>
    <div
      class="app-loading elevation-1 white mx-auto my-4 d-flex align-center justify-center"
    >
      <VProgressCircular
        indeterminate
        color="primary"
        :size="size"
      ></VProgressCircular>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  props: {
    value: Boolean,
    size: {
      type: String,
      default: () => '28',
    },
  },
}
</script>

<style lang="sass" scoped>
.app-loading
  border-radius: 100%
  align-self: flex-start
  max-width: 55px
  height: 55px
</style>
