<template>
  <div>
    <h3 class="secondary--text mb-2">Histórico de pagamentos</h3>
    <VDataTable
      :headers="headers"
      :items="data"
      :items-per-page="5"
      class="elevation-1"
      no-data-text="Nenhum pagamento feito"
      locale="pt-BR"
    >
      <template #item.amount="{ item }">
        <b>R$ {{ formatMoney(item.amount) }}</b>
      </template>
      <template #item.state="{ item }">
        <VChip small :color="statusData(item.state).color">
          {{ statusData(item.state).text }}
        </VChip>
      </template>
      <template #item.createdAt="{ item }">
        <span>
          {{ formatDate(item.createdAt) }}
        </span>
      </template>
      <template #item.actions="{ item }">
        <NuxtLink :to="`/conta/pagamento/${item.id}`">
          <VBtn outlined rounded color="info" small depressed>
            {{
              item.type === 'BOLETO' && item.state !== 'PAID'
                ? 'Ver boleto'
                : 'Ver detalhes'
            }}
          </VBtn>
        </NuxtLink>
      </template>
    </VDataTable>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Valor',
          align: 'start',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'Parcelas',
          align: 'start',
          sortable: false,
          value: 'installments',
        },
        {
          text: 'Estado',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'Data',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ],
      data: [],
    }
  },

  computed: {
    payments() {
      return this.data.filter((p) => p.state !== 'REFUSED')
    },
  },

  async mounted() {
    try {
      const res = await this.$axios.get('/users/payments')

      this.data = res.data
    } catch (e) {
      console.log(e)
      this.$toast.error(
        'Não foi possível carregar seus pagamentos. Verifique sua conexão e tente novamente.',
      )
    }
  },

  methods: {
    formatMoney(val) {
      const value = val / 100
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },

    formatDate(val) {
      return dayjs(val).format('DD/MM/YYYY [às] HH:mm')
    },

    statusData(status) {
      if (status === 'PROCESSING' || status === 'WAITING_PAYMENT')
        return {
          color: 'warning',
          text: 'Aguardando confirmação',
        }
      if (status === 'PAID')
        return {
          color: 'success',
          text: 'Pago',
        }
      if (status === 'REFUNDED')
        return {
          color: 'magenta',
          text: 'Reembolsado',
        }
      if (status === 'PENDING_REFUND')
        return {
          color: 'magenta',
          text: 'Processando reembolso',
        }
    },
  },
}
</script>

<style></style>
