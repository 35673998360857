<template>
  <div
    :class="{ 'AppNav--ios': device.platform === 'ios' }"
    class="AppNav__container"
  >
    <div
      :class="{ 'AppNav--sub': sub, 'AppNav--noPadding': noPadding }"
      class="AppNav"
    >
      <div v-if="!full" class="">
        <div class="AppNav__block d-flex align-center" group>
          <div
            v-if="mounted"
            key="navimg"
            class="hidden-sm-and-down AppNav__imgc"
          >
            <VImg v-if="!sub" class="AppNav__img" :src="image" />
            <VBtn
              v-if="sub"
              outlined
              rounded
              :color="!dark ? '#212121' : '#fff'"
              class=""
              large
              icon
              @click="onBackClick"
            >
              <VIcon :size="$vuetify.breakpoint.smAndDown ? 28 : 36">
                {{ $icons.arrowLeft }}
              </VIcon>
            </VBtn>
          </div>
          <div v-if="mounted" key="navtexts" class="texts ml-4">
            <div class="d-flex flex-column AppNav__back">
              <div
                v-if="mounted"
                key="navimg"
                class="hidden-md-and-up AppNav__imgc mr-2"
              >
                <VImg v-if="!sub" class="AppNav__img" :src="image" />
                <VBtn
                  v-if="sub"
                  outlined
                  rounded
                  :color="!dark ? 'grey' : '#fff'"
                  class="ml2-"
                  small
                  @click="onBackClick"
                >
                  Voltar
                </VBtn>
              </div>
              <h2>{{ title }}</h2>
            </div>
            <span>{{ subtitle }}</span>
          </div>
          <div key="navuser" class="AppNav__sidec d-flex align-center ml-auto">
            <div>
              <div v-if="$slots.side" class="AppNav__side">
                <slot name="side"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="full" class="AppNav__full d-flex">
        <VBtn
          outlined
          rounded
          color="#212121"
          class="grey lighten-5 mr-4"
          large
          icon
          @click="onBackClick"
        >
          <VIcon size="48">
            {{ $icons.arrowLeft }}
          </VIcon>
        </VBtn>
        <div class="d-flex align-center w-full">
          <slot name="full"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  props: {
    title: String,
    subtitle: String,
    image: String,
    backUrl: String,
    sub: Boolean,
    full: Boolean,
    backEvent: Boolean,
    hideUser: Boolean,
    dark: Boolean,
    noPadding: Boolean,
  },

  data() {
    return {
      mounted: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.mounted = true
    })
  },

  methods: {
    onBackClick() {
      if (this.backEvent) {
        this.$emit('back')
      } else if (this.backUrl) {
        this.$router.push(this.backUrl)
      } else {
        this.$router.go(-1)
      }
    },
  },

  computed: {
    ...mapGetters({
      device: 'auth/device',
    }),
  },
}
</script>

<style lang="sass">
.AppNav__container
  top: 0
  z-index: 10
  margin-bottom: 12px
.AppNav
  padding: 18px 0
  min-height: 80px
  .AppNav__img
    width: 45px
    height: 45px
.AppNav--noPadding
  padding: 0
  min-height: 0
.AppNav--sub
  h1
    font-size: 23px
  .AppNav__imgc
    position: relative
    border-radius: 10px
    .AppNav__img
      width: 120px
      height: 90px
      border-radius: 10px
      max-width: 150px

@media screen and (max-width: 960px)
  .AppNav__container
    margin-bottom: 0
  .AppNav
    margin-bottom: 20px
    padding: 0
    margin-top: 20px
    margin-bottom: 20px
    box-shadow: none !important
    position: relative
    border-top-left-radius: 0
    .AppNav__back
      h2
        line-height: 1.8
    .AppNav__img
      width: 25px
      height: 25px
      margin-right: 6px
      margin-top: 6px
    h1
      font-size: 28px
    .AppNav__block
      flex-direction: column
      align-items: flex-start !important
    .texts
      margin: 0 !important
      span
        font-size: 14px
    .app-user
      display: none
    .AppNav__sidec
      margin: 0 !important
  .AppNav--noPadding
    padding: 0
    margin: 4px 0
    min-height: 0
</style>
