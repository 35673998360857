<template>
  <v-app class="app">
    <client-only v-if="!loading">
      <div class="w-full">
        <div v-if="showShouldAppBar">
          <AppNavDesktop />
          <AppBar />
        </div>
        <div id="app-content" class="app-content">
          <div class="container">
            <nuxt
              class="app-internal"
              :keep-alive-props="{ exclude: ['modal'] }"
            />
          </div>
        </div>
      </div>
      <MarkModal />
      <CompetenceModal />
    </client-only>
    <VFadeTransition absolute>
      <div v-if="loading" class="app-loading">
        <div class="app-loading__content text-center">
          <div class="d-flex align-center">
            <img class="app-loading__logo" src="@/assets/images/logo.png" />
            <h1 class="ml-2 title font-weight-bold">coRedação</h1>
          </div>
          <span class="caption">Carregando...</span>
        </div>
      </div>
    </VFadeTransition>
    <ContentModal />
    <CheckoutModal />
    <LessonModal />
  </v-app>
</template>

<script>
import { Device } from '@capacitor/device'
import { mapGetters } from 'vuex'
import ContentModal from '../components/content/ContentModal.vue'
import CheckoutModal from '../components/checkout/CheckoutModal.vue'
import LessonModal from '../components/lesson/LessonModal.vue'
import AppNavDesktop from '@/components/app/AppNavDesktop'
import AppBar from '@/components/app/AppBar'
import MarkModal from '~/components/essay/MarkModal.vue'
import CompetenceModal from '~/components/essay/CompetenceModal.vue'

export default {
  components: {
    MarkModal,
    CompetenceModal,
    AppBar,
    ContentModal,
    CheckoutModal,
    AppNavDesktop,
    LessonModal,
  },

  async asyncData(context) {
    await context.store.dispatch('nuxtClientInit', context)
  },

  data() {
    return {
      left: 0,
      drawer: false,
      loading: true,
    }
  },

  middleware: ['isMaintenance', 'requireAuth'],

  watch: {},

  async mounted() {
    await Promise.all([
      this.loadStyles('/fonts/style.css'),
      this.loadStyles(
        'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap',
      ),
    ])

    const info = await Device.getInfo()

    this.$store.commit('auth/setDeviceInfo', info)

    this.$nextTick(() => {
      this.loading = false
    })
  },

  created() {
    this.$root.$on('switchMenu', () => {
      this.drawer = !this.drawer
    })

    this.loadFb()
  },

  methods: {
    loadFb() {
      // eslint-disable-next-line no-unused-expressions
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      )
      // eslint-disable-next-line no-undef
      fbq('init', '660967574633989')
    },
    loadStyles(src) {
      return new Promise((resolve) => {
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.onload = function () {
          console.log('loaded', src)
          resolve()
        }
        link.href = src
        document.getElementsByTagName('head')[0].appendChild(link)
      })
    },
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isSubscribed: 'auth/isSubscribed',
      currentUser: 'auth/currentUser',
    }),

    showShouldAppBar() {
      return !this.$route.path.includes('temas')
    },

    totalCredits() {
      return this.currentUser.credits ? this.currentUser.credits : 0
    },

    initials() {
      const names = this.currentUser?.fullname?.split(' ')

      if (names?.length === 1) {
        return this.currentUser.fullname.substring(0, 2).toUpperCase()
      } else if (names?.length > 1) {
        return names[0].charAt(0) + names.pop().charAt(0).toUpperCase()
      }

      return ''
    },
  },
}
</script>

<style lang="sass">
body
  background-color: #fff9f5
  -webkit-tap-highlight-color: rgba(0,0,0,0)

.theme--light.v-application
  color: #212121 !important
  background-color: #fff9f5
  position: relative
  overflow: hidden

  a
    color: #ff6338 !important

  &::before
    position: absolute
    content: url('/images/gradients/green1.png')
    left: 100px
    top: -300px
    width: 700px
    height: 700px
    z-index: 0
  &::after
    position: absolute
    content: url('/images/gradients/green2.png')
    right: 0
    top: -100px
    width: 700px
    height: 700px
    transform: rotate(180deg)
    z-index: 0
  .v-application--wrap
    position: relative
    z-index: 10
  @media screen and (max-width: 960px)
    &::before
      left: 0
      opacity: 0.1
      z-index: 0
    &::after
      z-index: 0
.index-emoji
  height: 16px
  margin-right: 6px
.v-list-item__content > *
  line-height: 1.4 !important
.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle
  color: rgba(0, 0, 0, 0.65)
h3, h2, h1, .user-credits
  color: #17173d
.crown
  height: 22px
.theme--light.v-application
  color: #212121 !important

.menu-item
  display: flex
  align-items: center
  img
    width: 22px
    margin-right: 12px
  span
    font-weight: 500

.v-sheet.v-card
  border-radius: 20px !important
  border: thin solid #9e9e9e
.VDivider
  border-top: 0.5px solid rgba(51, 51, 51, 0.3) !important

.VOverlay--active .VOverlay__scrim
  background-color: rgba(255,255,255,0.5) !important
  opacity: 1 !important
  backdrop-filter: blur(5px)

// Global stuff
@media (min-width: 960px)
  .v-dialog
    border-radius: 20px !important

.app-loading
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  .app-loading__logo
    max-width: 32px
  .app-loading__content
    max-width: 90%
    width: 200px
.rounded
  border-radius: 5px
a
  text-decoration: none
.app-content
  border-top-left-radius: 10px
  border-bottom-left-radius: 10px
  width: 100%
  max-width: 100vw
  position: relative
  z-index: 2
  max-width: 1160px
  margin: 0 auto
  margin-top: 70px

  .app-internal
    position: relative
    z-index: 10

  &::after
    position: absolute
    content: url('/images/gradients/yellow.png')
    right: 0
    top: 800px
    width: 434px
    height: 776px
    z-index: 0

  &::before
    position: absolute
    content: url('/images/gradients/yellow.png')
    left: 300px
    top: 1200px
    width: 372px
    height: 824px
    transform: rotate(180deg)
    z-index: 0

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active
  transition-duration: 0.2s
  transition-property: height, opacity, transform
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1)
  overflow: hidden !important

.slide-left-enter,
.slide-right-leave-active
  opacity: 0

.slide-left-leave-active,
.slide-right-enter
  opacity: 0

@media screen and (max-width: 960px)
  .v-alert
    font-size: 14px !important
  .app-content
    margin: 0
    border-radius: 0
    border-bottom-right-radius: 20px
    border-bottom-left-radius: 20px
    position: relative
    z-index: 0
    padding-bottom: 100px
    padding-top: 45px
    border-bottom: 20px solid white
    max-width: 100vw !important
    padding-left: 0

    &::after
      right: 0
      opacity: 0.8

    &::before
      left: 0
      opacity: 0.8
  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active
    transition-duration: 0.2s
    transition-property: height, opacity, transform
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1)
    overflow: hidden !important

  .slide-left-enter,
  .slide-right-leave-active
    opacity: 0
    transform: translate(0, 0)

  .slide-left-leave-active,
  .slide-right-enter
    opacity: 0
    transform: translate(0, 0)

.cursor-pointer
  cursor: pointer

.overflow-y-hidden
  overflow-y: hidden

.overflow-y
  overflow-y: auto

.AppNav-list--ios
  padding-top: 20px
</style>
