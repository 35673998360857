import { loadThemes } from '~/utils/themes'

export default {
  async loadEssay({ commit, state }, essayId) {
    const { data: essay } = await this.app.$axios.get('/essays/' + essayId)
    const { themes } = await loadThemes()

    const essayWiththeme = {
      ...essay,
      theme: themes.find((theme) => theme.id === essay.themeId),
    }

    commit('saveEssay', essayWiththeme)

    return essayWiththeme
  },
  async saveEssay({ commit, state }, essay) {
    commit('savingState', 'saving')
    const { data: savedEssay } = await this.app.$axios.post('/essays', {
      ...essay,
      id: state.currentEssayId,
    })

    console.log('savedEssay', savedEssay)

    if (savedEssay?.id) {
      commit('currentEssayId', savedEssay.id)

      commit('loaded', false)

      const { themes } = await loadThemes()

      commit('saveEssay', {
        ...essay,
        ...savedEssay,
        theme: themes.find((theme) => theme.id === savedEssay.themeId),
      })
      commit('savingState', 'saved')

      setTimeout(() => {
        commit('savingState', 'idle')
      }, 2000)
    }
  },

  async loadEssays({ commit, state }) {
    console.log('LOADING ESSAYS')
    if (state.loaded) return

    let { data: essays } = await this.app.$axios.get('/essays/')
    const { themes } = await loadThemes()

    essays = essays?.map((essay) => {
      const theme = themes.find((theme) => theme.id === essay.themeId)

      return {
        ...essay,
        theme: {
          id: theme?.id,
          feature_image: theme?.feature_image,
          title: theme?.title,
          slug: theme?.slug,
        },
      }
    })

    essays = essays ?? []

    commit('saveEssays', essays)
    commit('loaded', true)
  },

  async deleteEssay({ commit }, essay) {
    console.log('deleting essay', essay, essay.id)
    await this.app.$axios.delete('/essays/' + essay.id)

    commit('deleteEssay', essay)
  },
}
