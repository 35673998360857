<template>
  <div
    :class="{ 'feature--centered': centered }"
    class="feature d-flex align-start"
  >
    <div
      :class="{ 'feature--inverted': inverted, 'text-center': centered }"
      class="feature__container d-flex container"
    >
      <div
        v-if="!centered"
        :class="{ 'feature__block--mobile': showMobile }"
        class="feature__block"
      >
        <div class="feature__block__content">
          <slot name="content"></slot>
        </div>
        <div class="feature__block__bg"></div>
      </div>
      <div class="feature__content">
        <div class="feature__content__block">
          <span v-if="!!subtitle" class="feature__subtitle">{{
            subtitle
          }}</span>
          <text-serif
            bold
            :center="centered"
            component="h2"
            class="feature__title"
            size="32"
            >{{ title }}</text-serif
          >
          <div class="feature__block__contentmobile">
            <slot name="content"></slot>
          </div>
          <div class="feature__text">
            <slot></slot>
          </div>
          <span class="feature__caption">
            {{ caption }}
          </span>
          <div class="feature__buttons">
            <slot name="buttons"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextSerif from '@/components/helpers/TextSerif'

export default {
  props: {
    subtitle: String,
    title: String,
    caption: String,
    centered: Boolean,
    showMobile: Boolean,
    inverted: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },

  components: {
    TextSerif,
  },
}
</script>

<style lang="sass">
.feature
  margin: 64px 0
  position: relative
  .feature__block__contentmobile
    display: none
  .feature__block
    max-width: 50%
    left: 0
    height: auto
    top: 0
    box-shadow: 2px 2px 5px 0 #FEF6F0
    z-index: 1
    border-radius: 20px
    position: relative

    .feature__block__content
      height: 100%
      width: 100%
      position: relative
      background-color: white
      z-index: 1
      border-top-right-radius: 40px
      border-bottom-right-radius: 40px
      video
        width: 100%

    .feature__block__bg
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      z-index: 0
      &::after
        content: ''
        display: block
        background-image: url('/images/landing/feature/bg1.png')
        background-size: cover
        width: 370px
        height: 120px
        position: absolute
        bottom: -120px
        left: 20%
        z-index: 0

      &::before
        content: ''
        display: block
        background-image: url('/images/landing/feature/bg2.png')
        background-size: cover
        width: 260px
        height: 60px
        position: absolute
        top: -60px
        left: 20%
        z-index: 0
  .feature__content
    width: 50%
    position: relative
    z-index: 1
    margin-left: auto
    .feature__content__block
      max-width: 100%
      padding-left: 32px
      .feature__subtitle
        text-transform: uppercase
        font-size: 12px
        letter-spacing: 0.05em
        color: #020224
        background-color: #00EF9F
        border-radius: 20px
        padding: 1px 10px
        font-weight: bold
        margin-bottom: 22px
      .feature__title
        margin-top: 10px
        margin-bottom: 18px
      .feature__text
        font-size: 18px
        margin: 0
      .feature__caption
        font-size: 14px
        color: #A3A3A3
      .feature__buttons
        margin-top: 24px
.feature--inverted
  flex-direction: row-reverse
  .feature__content
    margin-left: 0 !important
    margin-right: auto
    .feature__content__block
      padding-right: 32px
      padding-left: 0

  .feature__block
    right: 0 !important
    left: auto !important

    .feature__block__content
      border-top-left-radius: 40px
      border-bottom-left-radius: 40px


    .feature__block__bg
      &::after
        transform: scaleY(-1)
        top: -120px
        left: 20%

      &::before
        top: auto
        transform: scaleY(-1)
        bottom: -60px
        left: 20%

@media (max-width: 960px)
  .feature--inverted
    flex-direction: column-reverse
  .feature
    flex-wrap: wrap
    flex-direction: column-reverse
    height: auto
    margin-top: 0
    .feature__container
      position: relative
      padding: 0
    .feature__content
      width: 100%
      text-align: center
      .feature__title
        text-align: center !important
      .feature__content__block
        width: 100%
        max-width: 100%
        padding: 0
        .feature__text
          font-size: 15px
    .feature__block__contentmobile
      display: flex
      max-width: 100%
    .feature__block
      position: relative
      width: 100%
      display: none
      max-width: 100%
      .feature__block__bg
        &::after
          transform: scaleY(-1)
          top: -60px
          left: 0
          max-width: 100%

        &::before
          top: auto
          transform: scaleY(-1)
          bottom: -60px
          left: 0
          max-width: 100%
    .feature__block--mobile
      display: flex

.feature--centered
  height: auto !important
  margin-top: 35px !important
  margin-bottom: 15px !important
  .feature__content
    margin-right: auto !important
    margin-left: auto !important
    width: 100% !important
    max-width: 700px
    .feature__content__block
      width: 100% !important
      padding-left: 0
    .feature__title
      margin-bottom: 6px !important
</style>
