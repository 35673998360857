import {
  mdiInformationOutline,
  mdiReceipt,
  mdiCreditCardOutline,
  mdiEyeCircleOutline,
  mdiLogoutVariant,
  mdiViewDashboardOutline,
  mdiLeadPencil,
  mdiCheckOutline,
  mdiEmailOutline,
  mdiPhoneInTalkOutline,
  mdiClose,
  mdiMenu,
  mdiFingerprint,
  mdiAccountOutline,
  mdiMagnify,
  mdiCloseCircleOutline,
  mdiMenuDownOutline,
  mdiCheckboxBlankCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDoubleDown,
  mdiChevronDown,
  mdiDice5Outline,
  mdiArrowRight,
  mdiEyeOutline,
  mdiShareVariant,
  mdiFilePdfBox,
  mdiPauseCircleOutline,
  mdiPlayCircleOutline,
  mdiChatOutline,
  mdiAccountQuestionOutline,
  mdiInstagram,
  mdiFacebook,
  mdiPinterest,
  mdiEyeOffOutline,
  mdiShapeOutline,
  mdiFileWordBox,
  mdiTrashCanOutline,
  mdiCheckCircle,
  mdiCircleOutline,
  mdiDotsHorizontalCircleOutline,
  mdiAlertCircleOutline,
} from '@mdi/js'

export default (context, inject) => {
  const icons = {
    theme: mdiShapeOutline,
    fingerprint: mdiFingerprint,
    account: mdiAccountOutline,
    search: mdiMagnify,
    clear: mdiCloseCircleOutline,
    menuDown: mdiMenuDownOutline,
    circle: mdiCheckboxBlankCircle,
    arrowRight: mdiChevronRight,
    arrowLeft: mdiChevronLeft,
    menu: mdiMenu,
    close: mdiClose,
    phone: mdiPhoneInTalkOutline,
    email: mdiEmailOutline,
    check: mdiCheckOutline,
    write: mdiLeadPencil,
    dashboard: mdiViewDashboardOutline,
    logout: mdiLogoutVariant,
    view: mdiEyeCircleOutline,
    credit: mdiCreditCardOutline,
    boleto: mdiReceipt,
    info: mdiInformationOutline,
    doubleDown: mdiChevronDoubleDown,
    down: mdiChevronDown,
    play: mdiPlayCircleOutline,
    pause: mdiPauseCircleOutline,
    dice: mdiDice5Outline,
    arrow: mdiArrowRight,
    share: mdiShareVariant,
    pdf: mdiFilePdfBox,
    chat: mdiChatOutline,
    help: mdiAccountQuestionOutline,
    instagram: mdiInstagram,
    facebook: mdiFacebook,
    pinterest: mdiPinterest,
    eyeOn: mdiEyeOutline,
    eyeOff: mdiEyeOffOutline,
    word: mdiFileWordBox,
    delete: mdiTrashCanOutline,
    checkboxOff: mdiCircleOutline,
    checkboxOn: mdiCheckCircle,
    menuHorizontal: mdiDotsHorizontalCircleOutline,
    error: mdiAlertCircleOutline,
  }

  inject('icons', icons)
  context.$icons = icons
}
