export default function ({ app, store, redirect, route, query }) {
  // If the user is not authenticated
  if (!store.getters['auth/isLoggedIn']) {
    console.log('not logged', query.ref, route.fullPath)
    store.commit('auth/setRedirect', route.fullPath)

    if (!!query.ref && !app.$cookie.get('ref')) {
      app.$cookie.set('ref', query.ref)
    }

    if (route.fullPath.includes('apostila')) {
      return redirect('/cadastrar')
    } else if (route.fullPath.includes('pagamento')) {
      return redirect('/cadastrar')
    } else {
      return redirect('/entrar')
    }
  }
}
