<template>
  <component
    :is="lazy ? 'v-lazy' : 'div'"
    class="contentcard-block"
    :options="{
      threshold: 0.5
    }"
    :class="{
      'contentcard--landing': landing,
      'contentcard--apptheme': theme && app
    }"
    transition="fade-transition"
  >
    <article
      :class="{ 'contentcard--theme': !!theme }"
      class="contentcard"
      @click="goToPost"
    >
      <VImg max-height="100%" :src="featureImage" class="contentcard__head">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-skeleton-loader
              class="card__skeleton mx-auto"
              width="100%"
              min-height="100%"
              type="image"
            ></v-skeleton-loader>
          </v-row>
        </template>
        <v-skeleton-loader
          v-if="loading"
          class="contentcard__image--skeleton hidden-sm-and-down"
          type="image"
          height="220"
          min-height="220"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-if="loading"
          class="contentcard__image--skeleton hidden-md-and-up"
          type="image"
          height="130"
          min-height="130"
        ></v-skeleton-loader>
      </VImg>
      <lazy-hydrate>
        <div class="contentcard__body">
          <div v-if="loading" class="contentcard__tags mb-2">
            <v-skeleton-loader type="chip" />
          </div>
          <div v-if="!loading && !hideTags" class="contentcard__tags">
            <VChip
              v-for="tag in content.tags"
              :key="tag.id"
              small
              :color="tag.color"
              link
              rounded
              outlined
              depressed
              class="contentcard__tag hidden-sm-and-down mr-2 mb-1"
              @click.stop="goToTag(tag)"
            >
              {{ tag.name }}
            </VChip>
          </div>
          <div v-if="app && theme" class="hidden-md-and-up d-flex align-center">
            <span
              v-if="primaryTag !== null"
              class="caption hidden-md-and-up"
              :style="{ color: primaryTag.color }"
              :to="`/${contentPath}/categoria/${primaryTag.slug}`"
            >
              {{ primaryTag.name }}
            </span>
            <VSpacer />
          </div>
          <div
            v-if="!loading"
            class="contentcard__title"
            :to="`/${contentPath}/${content.slug}/`"
          >
            {{ content.title }}
          </div>

          <v-skeleton-loader v-if="loading" type="text@3" />
          <p
            v-if="!!content.custom_excerpt"
            class="contentcard__excerpt mt-2 mb-0"
          >
            {{ content.custom_excerpt }}
          </p>
        </div>
      </lazy-hydrate>
    </article>
  </component>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import { VLazy } from 'vuetify/lib'

export default {
  components: {
    LazyHydrate,
    VLazy
  },
  props: {
    content: Object,
    contentPath: String,
    loading: Boolean,
    position: Number,
    landing: Boolean,
    related: Boolean,
    hideMargin: Boolean,
    hideTags: Boolean,
    lazy: Boolean,
    app: Boolean,
    theme: {
      type: Boolean,
      default() {
        return false
      }
    }
  },

  computed: {
    primaryTag() {
      return !this.loading && this.content?.tags?.length > 0
        ? this.content?.tags[0]
        : null
    },
    featureImage() {
      if (!this.content.feature_image) return ''

      return (
        this.content.feature_image.replace(
          'conteudo.coredacao.com',
          'coredacao.imgix.net'
        ) + '?w=800&h=300&fit=crop&crop=entropy&auto=format,enhance&q=75'
      )
    },
    headStyles() {
      if (!this.content || !!this.loading) return {}

      return {
        'background-image': `url("${this.content.feature_image}")`
      }
    },

    goUrl() {
      return !!this.hasState && this.content.essay.state === 'SUBMITTED'
        ? `/redacoes/${this.content.essay.id}`
        : `/${this.contentPath}/${this.content.slug}/escrever`
    }
  },

  methods: {
    goToPost() {
      if (!this.loading) {
        if (this.content.slug === 'tema-livre') {
          this.$router.push('/temas?theme=tema-livre')
        } else {
          this.$root.$emit('openContent', {
            ...this.content,
            theme: this.theme,
            hideTheme: true
          })
        }
      }
    },

    goToTag(tag) {}
  }
}
</script>

<style lang="sass" scoped>
.contentcard__mstate
  font-size: 10px !important
  line-height: 15px
  height: 15px
.readcontent
  display: none !important
.primary-tag
  color: #738a94
  font-size: 0.8rem
  line-height: 1.15em
  opacity: 0.8
  font-weight: 500
  letter-spacing: .8px
  display: block
  text-transform: uppercase

.contentcard
  width: 100%
  height: 100%
  max-width: 100%
  cursor: pointer
  z-index: 1
  transition: all .25s cubic-bezier(.02,.01,.47,1)
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 10%)
  border-radius: 20px
  cursor: pointer
  background-color: white
  transform: scale(1)
  border: thin solid #9e9e9e
  z-index: 1
  position: relative

  &:hover
    transform: translate(4px, -4px)
  .contentcard__tags
    position: relative
    z-index: 15
    .contentcard__tag
      border: thin solid #9e9e9e
      transform: scale(0.8) translateX(-10%)
  .contentcard__hover
    position: relative
    width: 100%
    height: 100%
  .contentcard__head
    height: 150px
    width: 100%
    background-size: cover
    border-top-left-radius: 18px
    border-top-right-radius: 18px
    border-bottom: thin solid #9e9e9e
  .contentcard__state
    position: absolute
    color: white
    font-size: 12px
    border-top-left-radius: 12px
    border-bottom-left-radius: 12px
    right: 0
    top: 12px
    padding: 4px 8px
  .contentcard__body
    padding: 16px
    .contentcard__title
      font-weight: 500
      color: #15171a
      letter-spacing: .4px
      font-size: 18px
      font-weight: 500
    .contentcard__excerpt
      color: #293951 !important
    span
      color: #293951
    p
      font-size: 14px

.contentcard-block
  width: 100%
  height: 100%
  position: relative
  background-color: #9e9e9e
  border-radius: 20px

.contentcard--landing
  position: relative
  .contentcard__head
    height: 150px
  .contentcard__body
    border: 1px solid #d8d7d7
    height: calc(100% - 150px)
    border-top: 0
    padding: 12px 16px
    .contentcard__title
      font-size: 22px
    span
      font-size: 11px
    p
      font-size: 14px
.card__skeleton
  div
    height: 100% !important
    width: 100% !important
.contentcard--apptheme
  .contentcard__head
    height: 110px !important
  .contentcard__body
    width: 100%
    p
      font-size: 14px

@media screen and (max-width: 960px)
  .contentcard
    border: thin solid #9e9e9e
    &:hover
      transform: none
    .contentcard__head
      border-bottom: 0
      border-right: thin solid #9e9e9e
      height: auto !important
      align-items: stretch

  .contentcard--apptheme
    min-height: 80px
    .contentcard
      display: flex
      padding: 0
    .contentcard__head
      max-width: 80px !important
      border-radius: 0
      border-top-left-radius: 20px
      border-bottom-left-radius: 20px
    .contentcard__body
      padding: 12px 12px
      .contentcard__title
        font-size: 16px
        line-height: 20px
        letter-spacing: 0.1px
      .contentcard__tags
        margin-bottom: 0

@media screen and (min-width: 960px)
  .readcontent
    display: flex !important
  .contentcard--triple
    width: calc(33.333% - 1.5em)

  .contentcard--full
    width: 100%
    .contentcard__head
      height: 100%
      width: calc(50%)
      max-width: 300px
    .contentcard__body
      min-width: calc(50%)
      padding: 25px !important
      p
        font-size: 14px
      .contentcard__title
        font-size: 1.7rem
      .contentcard__excerpt
        margin-top: 12px !important

  .contentcard--double
    width: calc(50% - 1.5em)
  .contentcard
    .contentcard__body
      p
        font-size: 14px
      .contentcard__title
        font-size: 20px
</style>
