<template>
  <div class="login d-flex align-center justify-center flex-column">
    <LoginForm />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from '~/components/auth/Login/LoginForm.vue'

export default {
  components: {
    LoginForm,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      expiredType: 'auth/expiredType',
    }),
  },

  created() {
    if (this.expiredType) {
      if (this.expiredType === 'INVALIDATED') {
        this.$toast.error(
          'Você foi desconectado(a) porque sua conta foi conectada em outro dispositivo.',
        )
      } else {
        this.$toast.error('Sessão expirada. Por favor faça login novamente.')
      }

      this.$store.commit('auth/setExpired', false)
    }
  },

  mounted() {
    const error = this.$route.query.error
    if (error) {
      if (error === 'google') {
        this.$toast.error('Não encontramos uma conta cadastrada com seu Google')
      } else if (error === 'facebook') {
        this.$toast.error(
          'Não encontramos uma conta cadastrada com seu Facebook',
        )
      }
    }
  },

  head() {
    return {
      title: 'Acessar sua conta',
    }
  },
}
</script>

<style lang="sass" scoped>
.login
  min-height: calc(100vh - 40px)
  height: 100vh
  position: relative
</style>
