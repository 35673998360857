<template>
  <div>
    <h3 class="secondary--text">Alterar senha</h3>
    <VDivider class="my-4" />
    <v-form ref="form">
      <div class="form__group">
        <div class="form__item">
          <VTextField
            type="password"
            :rules="[validPassword]"
            :disabled="loading"
            v-model="password"
            outlined
            rounded
            dense
            label="Nova senha"
          ></VTextField>
        </div>
        <div class="form__item">
          <VTextField
            type="password"
            :disabled="loading"
            :rules="[validPassword, confirmPassword(password)]"
            v-model="password_repeat"
            outlined
            rounded
            dense
            label="Repita a senha"
          ></VTextField>
        </div>
      </div>
    </v-form>
    <VDivider class="mb-4"></VDivider>
    <VBtn
      outlined
      rounded
      :loading="loading"
      @click="save"
      color="primary"
      depressed
      >Alterar senha</VBtn
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      password: '',
      password_repeat: '',
      loading: false,
      validPassword: (pw) =>
        (!!pw && pw.length > 5) || 'A senha deve conter no minímo 6 caracteres',
      confirmPassword: (pw) => (pwConfirm) =>
        pw === pwConfirm || 'As senhas não são compatíveis',
    }
  },

  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          const { data } = await this.$axios.put('/users/password', {
            password: this.password,
          })

          this.$toast.success('Senha alterada com sucesso!')
        } catch (e) {}
        this.loading = false
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
}
</script>

<style lang="sass">
.form__group
  display: flex
  justify-content: space-between
  .form__item
    width: calc(50% - 12px)

@media (max-width: 900px)
  .form__group
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    .form__item
      width: 100%
</style>
