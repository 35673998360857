<template>
  <div class="aluno pb-5 mb-5">
    <AppWelcome />
    <AppFirstStep />
    <AppNav
      image="/images/dashboard/icons/essays_on.svg"
      title="Redações"
      subtitle=""
    >
      <template #side>
        <NuxtLink to="/temas">
          <VBtn
            class="new"
            outlined
            :small="$vuetify.breakpoint.smAndDown"
            rounded
            depressed
            color="white"
          >
            <img class="write-emoji mr-2" src="/images/icons/write.svg" />
            <b>Escrever Redação</b>
          </VBtn>
        </NuxtLink>
      </template>
    </AppNav>

    <div class="app-aluno">
      <h3 class="mb-2">Seu sumário</h3>
      <div class="app-aluno__summary">
        <AppItem
          :value="statistics.corrections"
          :loading="loading"
          title="Correções"
          :subtitle="statistics.corrections === 1 ? 'redação' : 'redações'"
          image="/images/dashboard/icons/essay.svg"
        />
        <AppItem
          :value="statistics.total"
          :loading="loading"
          title="Nota média"
          subtitle="pontos"
          image="/images/dashboard/icons/grade.svg"
        />
        <AppItem
          :value="0"
          :loading="loading"
          title="Média por competência"
          subtitle="pontos"
          image="/images/dashboard/icons/grade.svg"
        >
          <template #content>
            <div class="mean-comps">
              <VTooltip v-for="i in 5" :key="i" bottom>
                <template v-slot:activator="{ on }">
                  <div
                    class="mean-comp"
                    @click="onCompetenceClick(i)"
                    v-on="$vuetify.breakpoint.mdAndUp ? on : null"
                  >
                    <div
                      :style="{
                        'background-color': competences[i].color,
                        height: `${(statistics.averages[i - 1] / 200) * 100}%`
                      }"
                      :class="{ 'gray--text': false }"
                      class="mean-comp__grade"
                    >
                      <span>{{ statistics.averages[i - 1] }}</span>
                    </div>
                  </div>
                </template>
                <span>Competência {{ i }}</span>
              </VTooltip>
            </div>
          </template>
        </AppItem>
      </div>
    </div>

    <div class="app-aluno mt-5">
      <h3>Redações corrigidas</h3>
      <EssayList
        :loading="loading"
        :themes="themes"
        :essays="finishedEssays"
        class="mt-2"
      />
      <h3>Rascunhos</h3>
      <EssayList
        :loading="loading"
        :themes="themes"
        :essays="draftEssays"
        empty-text="Você ainda não tem rascunhos"
        class="mt-2"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EssayList from '@/components/dashboard/EssayList'
import AppNav from '@/components/app/AppNav'
import competences from '@/constants/competences'
import AppItem from '~/components/app/AppItem.vue'
import AppWelcome from '~/components/app/AppWelcome.vue'
import AppFirstStep from '~/components/app/AppFirstSteps.vue'

export default {
  components: {
    EssayList,
    AppNav,
    AppItem,
    AppWelcome,
    AppFirstStep
  },

  layout: 'app',

  data() {
    return {
      themes: [],
      competences
    }
  },

  middleware: 'requireAuth',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      statistics: 'essays/statistics'
    }),

    finishedEssays() {
      return this.$store.getters['essays/finished']
    },

    draftEssays() {
      return this.$store.getters['essays/draft']
    },

    loading() {
      return !this.$store.state.essays.loaded
    }
  },

  destroyed() {},

  created() {
    this.$store.dispatch('essays/loadEssays')
  },

  methods: {
    onCompetenceClick(i) {
      console.log(i)
      this.$root.$emit('openCompetence', {
        competence: `C${i}`,
        grade: null
      })
    },
    async loadData() {}
  },

  head: {
    title: 'Redações'
  }
}
</script>

<style lang="sass" scoped>
.write-emoji
  height: 16px
.app-aluno
  .app-aluno__summary
    display: grid
    grid-gap: 0.5em
    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr))

  @media (max-width: 600px)
    .app-aluno__summary
      display: flex
      flex-direction: column
  .competence-chart
    position: relative
  .competence-empty
    max-height: 300px
    img
      max-height: 150px
  .mean-comps
    display: flex
    width: 100%
    .mean-comp
      width: 40px
      height: 80px
      max-width: 15%
      background-color: #eaeaea
      margin-right: 4px
      display: flex
      align-items: flex-end
      border-radius: 4px
      transition: all 150ms ease-in
      .mean-comp__grade
        width: 100%
        border-radius: 0 0 4px 4px
        color: white
        text-align: center
        display: flex
        align-items: flex-end
        justify-content: center
        user-select: none
        span
          margin-bottom: 2px
      &:hover
        cursor: pointer
        box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.10)

.tooltip_box
  position: relative
  background: #fefefe
  border: 2px solid rgba(33, 33, 33, 0.2)
  padding: 20px !important
  border-radius: 5px

#chart .apexcharts-tooltip
  color: #212121
  transform: translateX(10px) translateY(10px)
  overflow: visible !important
  white-space: normal !important

#chart .apexcharts-tooltip span
  padding: 5px 10px
  display: inline-block
</style>
