var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WelcomeHeader',{attrs:{"title":"Como você descobriu o coRedação?"}},[_vm._v("\n    Última pergunta. Obrigado!\n  ")]),_vm._v(" "),_c('Chooser',{staticClass:"mt-4",attrs:{"options":[
      {
        value: 'GOOGLE',
        name: 'Google'
      },
      {
        value: 'REDE_SOCIAL',
        name: 'Rede social'
      },
      {
        value: 'INDICACAO_PROFESSOR',
        name: 'Indicação de professor'
      },
      {
        value: 'INDICACAO_AMIGO',
        name: 'Indicação de amigo'
      },
      {
        value: 'OUTRO',
        name: 'Outro'
      }
    ]},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }