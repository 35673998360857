<template>
  <div
    class="lesson-finished white d-flex flex-column align-center justify-center"
  >
    <img src="/images/celebration.png" />
    <h1 class="text-center">Parabéns, você terminou o módulo!</h1>
    <p class="text-center">
      Você está avançando cada vez mais e comprometido(a) com o seu futuro!
      Continue assim!
    </p>
    <p class="text-center caption mb-5">
      <i>
        {{ quote }}
      </i>
    </p>
    <VBtn color="new" dark block rounded @click="$emit('close')">
      <b>Continuar</b>
    </VBtn>
  </div>
</template>

<script>
import quotes from '../../utils/quotes'

export default {
  data() {
    return {
      quote: quotes[(Math.random() * quotes.length) | 0],
    }
  },
  mounted() {
    this.$confetti.start()
  },

  destroyed() {
    console.log('destroyed')
    this.$confetti.stop()
  },
}
</script>

<style lang="sass" scoped>
.lesson-finished
  padding: 32px
  & > img
    max-width: 350px
  h1
    font-size: 28px
</style>
