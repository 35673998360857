export default () => ({
  user: null,
  token: null,
  expired: false,
  redirectUrl: null,
  isFirstTime: false,
  deviceInfo: {},
  selectedPlanId: 'anual',
  configs: {
    price: 0,
    limits: {
      basic: 0,
      premium: 0,
    },
  },
})
