export default function ({ store, redirect, route }) {
  console.log('MIDDLEWARE')

  if (!process.client) return

  // If the user is not authenticated
  if (!store.getters['auth/isLoggedIn']) {
    store.commit('auth/setRedirect', route.fullPath)

    return redirect('/cadastrar')
  }
}
