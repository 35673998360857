<template>
  <VDialog
    v-model="dialog"
    :max-width="showFinished ? 500 : 800"
    :fullscreen="$vuetify.breakpoint.smAndDown && !showFinished"
    transition="fade-transition"
    class="lesson-modal"
  >
    <div v-if="!showFinished" class="lesson-dialog">
      <div v-if="dialog && !lesson.blog">
        <LessonVideo
          :key="lesson.videoUrl"
          :video-url="lesson.videoUrl"
          :initial-time="initialTime"
          @progress="onVideoProgress"
        />
        <div class="lesson-action">
          <div class="lesson-action-content">
            <h3>
              {{ lesson.title }}
            </h3>
            <p>
              {{ lesson.description }}
            </p>
            <VBtn
              rounded
              outlined
              :class="{ new: isAbleToContinue }"
              color="white"
              :disabled="!isAbleToContinue"
              block
              @click="onContinue"
            >
              <img
                v-if="isAbleToContinue"
                class="star"
                src="/images/icons/star.png"
              />
              <b>Continuar</b>
            </VBtn>
            <VBtn
              rounded
              outlined
              block
              class="mt-2 hidden-md-and-up"
              @click="dialog = false"
            >
              Fechar
            </VBtn>
          </div>
        </div>
      </div>
      <div
        v-else
        ref="blog"
        class="lesson-blog d-flex flex-column align-center"
      >
        <div class="lesson-blog-close" @click="dialog = false">
          <VBtn outlined small class="bg-white" icon>
            <VIcon size="20">{{ $icons.close }}</VIcon>
          </VBtn>
        </div>
        <h1 class="lesson-blog-title new--text text-center py-5">
          {{ lesson.title }}
        </h1>
        <VDivider class="mb-5 w-full" />
        <div class="lesson-blog-content">
          <ContentPostBodyContent :value="{ html: lesson.html }" />
        </div>
        <div class="lesson-blog-continue">
          <VBtn
            rounded
            outlined
            class="new px-5"
            color="white"
            block
            @click="onContinue"
          >
            <img class="star" src="/images/icons/star.png" />
            <b>Continuar</b>
          </VBtn>
          <VBtn rounded outlined block class="mt-2" @click="dialog = false">
            Fechar
          </VBtn>
        </div>
      </div>
    </div>
    <v-fade-transition>
      <LessonFinished v-if="showFinished && dialog" @close="dialog = false" />
    </v-fade-transition>
  </VDialog>
</template>

<script>
import ContentPostBodyContent from '../content/ContentPost/ContentPostBodyContent.vue'
import LessonVideo from '@/components/lesson/LessonVideo'
import LessonFinished from '@/components/lesson/LessonFinished'

export default {
  components: {
    LessonVideo,
    LessonFinished,
    ContentPostBodyContent,
  },

  data() {
    return {
      dialog: false,
      isMobile: true,
      lesson: {},
      vimeoId: 0,
      lastSavedTime: 0,
      lastProgress: {},
      showFinished: false,
    }
  },

  computed: {
    preferenceKey() {
      return `blog-lesson-${this.lesson.id}`
    },
    preferenceValue() {
      const val = this.$store.getters['auth/preferences'][this.preferenceKey]
      return val ? parseInt(val) : 0
    },
    initialTime() {
      let time = 0
      if (this.$store.getters['course/lessonsProgress'][this.lesson.id]) {
        time =
          this.$store.getters['course/lessonsProgress'][this.lesson.id]
            .currentTimePosition
      } else {
        time = this.lesson?.userStatus?.currentTimePosition || 0
      }

      if (this.lesson.userStatus?.percentageWatched >= 95) time = 0

      return time
    },

    isAbleToContinue() {
      return this.lastProgress?.percentageWatched > 90
    },
  },

  watch: {
    dialog(v) {
      console.log('close', v, this.lesson)
      if (!v) {
        const url = this.lesson.blog
          ? '/aprenda/apostila'
          : '/aprenda/curso-enem'
        this.$router.push(url)
      }
    },
  },

  mounted() {
    this.isMobile = window.innerWidth < 600

    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 700
    })

    this.$root.$on('openLesson', (lesson) => {
      this.lesson = lesson
      this.lastSavedTime = 0
      this.lastProgress = {}
      this.showFinished = false
      this.dialog = true

      const url = this.lesson.blog ? '/aprenda/apostila' : '/aprenda/curso-enem'
      const slug = lesson.title
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '')

      // Handle router
      this.$router.push(url + '/' + slug)

      if (lesson.blog) {
        this.$nextTick(() => {
          this.$refs.blog.scrollTop = 0
        })

        if (this.preferenceValue === 0) {
          this.$store.dispatch('auth/setPreference', {
            key: this.preferenceKey,
            value: '1',
          })
        }
        return
      }

      console.log('opening lesson', lesson)

      /* If this is the first time the user is opening this video */
      if (!lesson.userStatus) {
        this.saveLessonProgress({
          currentTimePosition: 0,
          percentageWatched: 0,
          state: 'STARTED',
        })
      }
    })
  },

  destroyed() {
    this.$root.$off('openLesson')
  },

  methods: {
    onContinue() {
      const modules = this.$store.getters['course/modules'].slice()

      const currentModule = modules.find((m) => m.id === this.lesson.moduleId)

      const lessonIndex = currentModule.lessons.findIndex(
        (l) => l.id === this.lesson.id,
      )

      const nextLesson = currentModule.lessons[lessonIndex + 1]

      if (this.lesson.blog) {
        if (this.preferenceValue < 2) {
          this.$store.dispatch('auth/setPreference', {
            key: this.preferenceKey,
            value: '2',
          })
        }
      }

      if (nextLesson) {
        this.$root.$emit('openLesson', nextLesson)
      } else {
        console.log('finished module')
        this.showFinished = true
      }
    },
    onVideoProgress(data) {
      const UPDATE_INTERVAL = 5

      this.lastProgress = data

      if (data.currentTimePosition < this.lastSavedTime) {
        this.lastSavedTime = parseInt(data.currentTimePosition)
      }

      if (data.currentTimePosition - this.lastSavedTime >= UPDATE_INTERVAL) {
        this.lastSavedTime = parseInt(data.currentTimePosition)
        this.saveLessonProgress(data)
      }

      this.$store.commit('course/setLessonProgress', {
        lessonId: this.lesson.id,
        progress: data,
      })
    },
    saveLessonProgress(data) {
      console.log('SAVING VIDEO PROGRESS', data.currentTimePosition)
      try {
        this.$axios.post('/course/user/lesson/' + this.lesson.id, data, {
          progress: false,
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.lesson-dialog
  width: 100%
  height: 100%
  background: white
  position: relative
  .lesson-action
    bottom: 0
    width: 100%
    display: flex
    flex-direction: column
    .lesson-action-content
      padding: 12px 24px
.star
  height: 18px
  margin-right: 6px
  transform: translateY(-2px)
.lesson-blog
  max-height: 90vh
  overflow: auto
.lesson-blog-close
  position: absolute
  top: 0
  right: 12px
  z-index: 1
  padding: 12px
  cursor: pointer
.lesson-blog-continue
  max-width: 680px
  width: 100%
  margin-bottom: 24px

@media screen and (max-width: 960px)
  .lesson-blog-close
    right: 0px
    top: -4px
  .lesson-blog
    max-height: 100%
  .lesson-blog-content, .lesson-blog-continue, .lesson-blog-title
    padding: 0 12px
</style>

<style lang="sass">
.v-dialog:not(.v-dialog--fullscreen)
  max-height: 100vh !important
</style>
