<template>
  <div
    v-mouseover-outside="() => (hovering = false)"
    class="lesson-video-container"
    @mousemove="hovering = true"
  >
    <div id="lvideo" class="lesson-video"></div>
    <v-fade-transition>
      <div v-if="!hovering" class="video-overlay">
        <v-progress-linear
          color="#FF6338"
          :height="8"
          class="video-progress"
          :value="percent * 100"
        ></v-progress-linear>
      </div>
    </v-fade-transition>
    <AppLoading v-if="loading" class="lesson-loading" />
  </div>
</template>

<script>
import Player from '@vimeo/player'
import AppLoading from '@/components/app/AppLoading'

export default {
  components: { AppLoading },
  props: {
    videoUrl: String,
    initialTime: Number,
  },
  data() {
    return {
      click: undefined,
      player: null,
      started: false,
      playing: false,
      time: 0,
      percent: 0,
      loading: true,
      hovering: false,
    }
  },
  watch: {
    videoUrl() {
      this.loading = true
      this.hovering = false
      this.time = 0
      this.percent = 0
      this.playing = false
      this.started = false

      this.initiateVideo(this.initialTime)
    },
  },
  mounted() {
    this.initiateVideo(this.initialTime)
  },
  methods: {
    playVideo() {
      try {
        if (this.initialTime) {
          try {
            this.player.setCurrentTime(this.initialTime)
          } catch (e) {}
        }

        this.player.play().catch((e) => {
          console.log('error', e)
        })
      } catch (e) {
        console.log('error', e)
      }
    },
    initiateVideo() {
      const options = {
        url: this.videoUrl,
        width: '100%',
        color: '#F96339',
        controls: true,
        portrait: false,
        title: false,
        loop: false,
      }

      console.log('video options', options)

      this.player = new Player('lvideo', options)

      this.player.ready().catch((e) => {
        console.log('error')
      })

      this.player.setVolume(1)

      this.player.on('loaded', () => {
        console.log('Ready the video!')
        this.loading = false

        this.playVideo()
      })

      this.player.on('play', () => {
        this.playing = true
        this.started = true
        this.loading = false
        console.log('event play')
      })

      this.player.on('pause', () => {
        this.playing = false
        console.log('event pause')
      })

      this.player.on('timeupdate', (t) => {
        this.percent = t.percent
        this.time = t.seconds

        this.$emit('progress', {
          currentTimePosition: Math.floor(this.time),
          percentageWatched: Math.floor(this.percent * 100),
        })
      })
    },
  },
}
</script>

<style lang="sass">
.lesson-video-container
  position: relative
  .video-overlay
    position: absolute
    width: 100%
    height: 8px
    bottom: 0
    left: 0
    .video-progress
      position: absolute
      bottom: 0
.lesson-video
  width: 100%
  padding-top: 56.25%
  position: relative
  iframe
    width: 100%
    height: 100%
    top: 0
    position: absolute
.lesson-loading
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
</style>
