<template>
  <div ref="write-area" class="write-area">
    <AdvancedEssayWall
      v-if="!isSubscribed"
      :number-of-errors="advancedErrorsCount"
    />
    <EssayLevelErrors v-else :errors="essayMarks" />
    <div class="d-flex write-area__container">
      <div id="correction__area" ref="area" class="c1 text__area__correction">
        <span v-for="(c, i) in content" :key="i">
          <TextMarker
            v-if="!c.isText"
            :area-width="width"
            :area-height="height"
            :area-rect="areaRect"
            :node-key="i"
            :mark="c"
            :value="c.text"
            :group-hover="groupHover"
            :parent-hover="parentHover"
            :is-subscribed="isSubscribed"
            @groupHover="onGroupHoverChange"
          ></TextMarker>
          <span
            v-else
            :class="`spantext selectable_${i}`"
            v-html="c.text"
          ></span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import EssayLevelErrors from '../EssayLevelErrors.vue'
import TextMarker from './TextMarker.vue'
import AdvancedEssayWall from '~/components/paywalls/AdvancedEssayWall.vue'

export default {
  components: {
    TextMarker,
    EssayLevelErrors,
    AdvancedEssayWall,
  },

  props: {
    value: String,
    initialMarks: Array,
    isSubscribed: Boolean,
  },

  data() {
    return {
      text: '',
      selectedLine: 1,
      currentMark: null,
      created: false,
      menuOpen: false,
      groupHover: '',
      parentHover: '',
      marks: [],
      currentEditingMarks: [],
      width: 0,
      height: 0,
      areaRect: new DOMRect(),
    }
  },

  computed: {
    essayMarks() {
      return this.initialMarks.filter((m) => m.level === 'essay')
    },

    advancedErrorsCount() {
      return this.initialMarks.filter((m) => m.origin === 'custom').length
    },

    currentMarkPoints() {
      if (this.currentMark.groupId === null) {
        return this.currentEditingMarks
      } else {
        return this.marks.filter((m) => m.groupId === this.currentMark.groupId)
      }
    },
    content() {
      const mainSlice = {
        isText: true,
        start: 0,
        end: this.value.length,
      }

      const marks = this.marks

      const adjustedMarks = marks
        .map((m) => {
          const start = m.start
          const end = m.end

          if (m.level === 'paragraph') {
            return {
              ...m,
              start,
              end,
            }
          }

          // Check if the mark has children marks
          if (m.level === 'sentence') {
            m.children = marks.filter((c) => {
              if (c.start >= start && c.end <= end && c !== m) {
                c.isChild = true
                c.parentId = m.groupId
                return true
              }

              return false
            })
          }

          return {
            ...m,
            start,
            end,
          }
        })
        .filter((m) => !m.isChild)

      const slices = this.splitSlices(this.value, mainSlice, adjustedMarks, 0)

      return slices
    },
  },

  mounted() {
    this.calculateSizes()
    window.addEventListener('resize', this.calculateSizes)

    this.$nextTick(() => {
      this.loadMarks()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateSizes)
  },

  methods: {
    onGroupHoverChange(v) {
      this.groupHover = v
    },
    splitSlices(value, mainSlice, overlappingSlices, depth) {
      const result = []
      if (depth > 2) {
        console.error('Too many recursive calls')
        return result
      }
      let lastEnd = mainSlice.start

      // Sort overlapping slices by start position.
      overlappingSlices.sort((a, b) => a.start - b.start)

      // Iterate through the sorted array and create new slices.
      for (let i = 0; i < overlappingSlices.length; i++) {
        const slice = overlappingSlices[i]

        if (slice.start > lastEnd) {
          // Insert a slice from the main slice if there is a gap.
          result.push({
            ...mainSlice,
            text: this.partialText(value, lastEnd, slice.start),
            start: lastEnd,
            end: slice.start,
          })
        }

        // Insert the current overlapping slice.
        if (slice.children && slice.children.length > 0) {
          const slices = this.splitSlices(
            value,
            slice,
            slice.children,
            depth + 1,
          )
          result.push(...slices)
        } else {
          result.push({
            ...slice,
            text:
              slice.level === 'paragraph'
                ? '⚠️'
                : this.partialText(value, slice.start, slice.end),
          })
        }

        lastEnd = Math.max(lastEnd, slice.end)
      }

      if (lastEnd < mainSlice.end) {
        // Insert the remaining part of the main slice if any.
        result.push({
          ...mainSlice,
          text: this.partialText(value, lastEnd, mainSlice.end),
          start: lastEnd,
          end: mainSlice.end,
        })
      }

      return result
    },
    loadMarks() {
      if (this.initialMarks) {
        const marks = []

        this.initialMarks.forEach((m) => {
          const randomInt = Math.floor(Math.random() * 1000000)
          const groupId = `${randomInt}`

          if (m.positions) {
            m.positions.forEach((position) => {
              let start = 0
              let end = 0

              const content = this.partialText(
                this.value,
                position.start,
                position.start + position.length,
              )

              switch (m.level) {
                case 'sentence':
                  start = position.start
                  end = position.start + position.length
                  break
                case 'paragraph':
                  start = position.start
                  end = position.start
                  break
                default:
                  start = position.start
                  end = position.start + position.length
                  m.level = 'token'
                  break
              }

              marks.push({
                title: m.title,
                comment: m.message,
                competence: m.type,
                origin: m.origin,
                content,
                start,
                end,
                replacements: m.replacements,
                level: m.level,
                groupId,
              })
            })
          }
        })

        this.marks = marks.sort((a, b) => {
          if (a.start === b.start) {
            return a.end > b.end ? -1 : 1
          }

          return a.start < b.start ? -1 : 1
        })
      }
    },
    partialText(value, start, end) {
      const text = value.substring(start, end).replace(/\t/g, '&emsp;')
      return text.replace('\n', '\n\n')
    },
    calculateSizes() {
      this.$nextTick(() => {
        this.width = this.$refs?.area?.offsetWidth
          ? this.$refs?.area?.offsetWidth
          : 0
        this.height = this.$refs?.area?.offsetHeight
          ? this.$refs?.area?.offsetHeight
          : 0

        this.areaRect = this.$refs?.area?.getBoundingClientRect()
          ? this.$refs?.area?.getBoundingClientRect()
          : {}
      })
    },

    runOnCurrent(func) {
      const groupId = this.currentMark.groupId.valueOf()

      for (let i = 0; i < this.marks.length; i++) {
        const mark = this.marks[i]

        if (mark.groupId === groupId) {
          if (func) func(mark, i)
        }
      }
    },
  },
}
</script>

<style lang="sass">
.spantext
  -moz-tab-size: 8
  -o-tab-size: 8
  tab-size: 8
.noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

/* competences */
.c1::selection
  background-color: transparent
  color: black
.c2::selection
  background-color: #7ace68
  color: white
.c3::selection
  background-color: #ffe382
  color: white
.c4::selection
  background-color: #ffc871
  color: white
.c5::selection
  background-color: #00ddf2
  color: white
.correction__area
  position: relative
.write-area
  background-color: white
  padding: 16px 16px
  padding-bottom: 64px
  .write-area__container
    background: linear-gradient(transparent, transparent 39px,#bfbfbf 39px)
    background-size: 40px 40px
    position: relative
    color: black
    .write-area__linescorrection
      margin-left: -25px
      width: 35px
      padding-right: 5px
      left: -15px
      position: absolute
      max-height: 100%
      .write-area__line__mark
        user-select: none
        transition:0.2s ease all
        text-align: right
        display: flex
        align-items: center
        justify-content: flex-end
        height: 34px
        margin-top: 6px
        font-weight: bold
        font-size: 22px
    .text__area__correction
      white-space: pre-line
      margin-top: 4px
      width: 100%
      resize: none
      outline: none !important
      font-size: 18px
      line-height: 40px
      overflow: visible !important
      position: relative
      text-align: justify
      -ms-word-break: break-all
      word-break: break-all
      word-break: break-word

@media (max-width: 1260px)
  .write-area
    .write-area__container
      .write-area__linescorrection
        .write-area__line__mark
          font-size: 16px
      .text__area__correction
        font-size: 15.1px

@media (max-width: 960px)
  .write-area__linescorrection
    display: none
  .write-area
    padding: 16px 16px
    .write-area__container
      margin: 0 !important
      .text__area__correction
        font-size: 16px
        max-height: auto

.hide-lines
  margin-left: 0 !important
  .text__area__correction
    max-height: 100% !important
</style>
