<template>
  <div class="checkoutpage-container container checkoutnav">
    <VStepper v-model="step" class="mb-4" color="new" outlined vertical>
      <VStepperHeader>
        <VStepperStep editable class="text-center" step="1" color="new">
          Escolha um plano
        </VStepperStep>

        <VDivider></VDivider>

        <VStepperStep step="2" color="new"> Efetue o pagamento </VStepperStep>

        <VDivider></VDivider>

        <VStepperStep color="new" step="3"> Vire Premium ⭐ </VStepperStep>
      </VStepperHeader>
    </VStepper>
    <div class="payment">
      <div v-show="step === '1'">
        <div class="mb-2">
          <VAlert color="info" dark rounded>
            ⚠️ Última chance para assinar no preço atual. Estaremos reajustando
            nosso preço em breve.
          </VAlert>
          <h4 class="mb-2">Escolha um plano</h4>

          <PlanSelector />
          <VBtn
            class="mt-4"
            block
            rounded
            color="green"
            depressed
            dark
            large
            @click="goToPayment"
          >
            <b> Continuar </b>
          </VBtn>
        </div>
        <div>
          <Faq />
        </div>
      </div>

      <div v-show="step === '2'">
        <div class="mb-2">
          <h4 class="mb-2">Detalhes da compra</h4>
          <table class="summary w-full">
            <tbody>
              <tr>
                <td>
                  <b>Plano</b>
                </td>
                <td>
                  <b>{{ selectedPlan.planName }} ⭐</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Tempo de acesso</b>
                </td>
                <td>
                  <b>{{ selectedPlan.months }} meses</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Valor</b>
                </td>
                <td>
                  <div>
                    <b> {{ installmentsNumber }} x </b>
                    <b>
                      {{ price.monthly }}
                    </b>
                    <div v-if="installmentsNumber > 1" class="caption">
                      Total {{ price.total }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-center mt-1">
            <button
              v-if="coupon === null"
              class="mx-auto text-subtitle-2 new--text"
              @click="openCoupon = true"
            >
              Tenho um cupom de desconto.
            </button>
            <VAlert v-else block color="green lighten-3" dense>
              🏷️ Desconto de <b>{{ coupon.percentage }}%</b> aplicado.
              <VBtn icon @click="coupon = null">
                <VIcon>
                  {{ $icons.close }}
                </VIcon>
              </VBtn>
            </VAlert>
          </div>
        </div>
        <h4 class="mb-4">Método de pagamento</h4>
        <div>
          <div cols="12" class="mb-2 pb-0">
            <VBtn
              :outlined="paymentType != 0"
              :dark="paymentType == 0"
              :color="paymentType == 0 ? 'new' : 'grey'"
              rounded
              large
              block
              @click="paymentType = 0"
            >
              <img class="payment-icon" src="/images/icons/creditcard.svg" />
              <b>Cartão de crédito</b>
            </VBtn>
          </div>
          <div cols="12" class="mb-2 pb-0">
            <VBtn
              :outlined="paymentType != 2"
              :dark="paymentType == 2"
              :color="paymentType == 2 ? 'new' : 'grey'"
              rounded
              large
              block
              @click="paymentType = 2"
            >
              <img class="payment-icon" src="/images/icons/pix.svg" />
              <b>PIX</b>
            </VBtn>
          </div>
          <div cols="12">
            <div class="d-flex mt-5 w-full">
              <v-form
                v-if="paymentType === 1 || paymentType === 2"
                ref="formdata"
                class="w-full"
              >
                <h4 v-if="false" class="mb-4">
                  Dados de cobrança
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <VIcon size="18" v-on="on">{{ $icons.info }}</VIcon>
                    </template>
                    <span
                      >Informações exigidas pelo processador de pagamento, não
                      salvamos nenhuma dessas informações na nossa base de
                      dados.</span
                    >
                  </v-tooltip>
                </h4>
                <div class="w-full">
                  <v-layout class="pt-0 w-full" cols="12" md="12">
                    <VTextField
                      v-model="paymentValues.cpf"
                      v-mask="'###.###.###-##'"
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo'
                      ]"
                      outlined
                      dense
                      rounded
                      label="CPF"
                      placeholder="000.000.000-00"
                      background-color="white"
                      class="card_number"
                      @keydown.enter.prevent="processPayment"
                    />
                  </v-layout>
                </div>
              </v-form>
              <v-form
                v-if="paymentType === 0"
                ref="formcredit"
                class="formcredit w-full"
              >
                <v-slide-y-transition>
                  <VAlert v-if="refused" outlined border="left" type="error">
                    <b>Compra recusada.</b> <br />Não conseguimos completar o
                    pagamento, pois a compra foi recusada pelo seu cartão.
                  </VAlert>
                </v-slide-y-transition>
                <b class="mb-5">Dados de pagamento</b>
                <div class="mt-4">
                  <div cols="12">
                    <VTextField
                      v-model="paymentValues.number"
                      v-mask="['#### #### #### ####']"
                      outlined
                      persistent-label
                      rounded
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo',
                        (v) =>
                          !!errors.card_number || 'Número do cartão inválido'
                      ]"
                      dense
                      label="Número do cartão"
                      name="cc-number"
                      autocomplete="cc-number"
                      placeholder="0000 0000 0000 0000"
                      background-color="white"
                      class="card_number"
                      @input="errors.card_number = true"
                    >
                      <template v-slot:append>
                        <v-fade-transition>
                          <div v-if="!!creditType" class="d-flex align-center">
                            <img
                              class="payment-flag"
                              :src="`/credits/${creditType}.png`"
                            />
                          </div>
                        </v-fade-transition>
                      </template>
                    </VTextField>
                  </div>
                  <div cols="12">
                    <VTextField
                      v-model="paymentValues.name"
                      background-color="white"
                      outlined
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo'
                      ]"
                      dense
                      rounded
                      label="Nome do titular"
                      placeholder="NOME NO CARTÃO"
                    />
                  </div>
                </div>
                <div class="mt-0">
                  <div class="d-flex">
                    <VTextField
                      v-model="paymentValues.expiration"
                      v-mask="['##/##']"
                      background-color="white"
                      outlined
                      rounded
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo',
                        (v) =>
                          !!errors.card_expiration_date || 'Expiração inválida'
                      ]"
                      dense
                      placeholder="00/00"
                      name="cc-exp"
                      autocomplete="cc-exp"
                      label="Validade"
                      @input="errors.card_expiration_date = true"
                    />
                    <VTextField
                      v-model="paymentValues.cvv"
                      v-mask="['####']"
                      background-color="white"
                      outlined
                      rounded
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo',
                        (v) => !!errors.card_cvv || 'CVV inválido'
                      ]"
                      class="pl-4"
                      dense
                      placeholder="000"
                      name="cc-csc"
                      autocomplete="cc-csc"
                      label="CVV"
                      @input="errors.card_cvv = true"
                    />
                  </div>
                  <div cols="12">
                    <v-select
                      v-model="paymentValues.installments"
                      outlined
                      rounded
                      dense
                      label="Parcelas"
                      background-color="white"
                      :items="installments"
                    />
                  </div>
                  <div class="pt-0" cols="12">
                    <VTextField
                      v-model="paymentValues.cpf"
                      v-mask="'###.###.###-##'"
                      :rules="[
                        (v) => !!v || 'É necessário preencher esse campo'
                      ]"
                      outlined
                      rounded
                      dense
                      label="CPF"
                      placeholder="000.000.000-00"
                      background-color="white"
                      class="card_number"
                      @keydown.enter.prevent="processPayment"
                    />
                  </div>
                </div>
              </v-form>
            </div>

            <div class="pb-5">
              <VBtn
                rounded
                :loading="loading"
                large
                block
                dark
                color="#16a34a"
                class="mb-5"
                @click="processPayment"
                ><b>{{ confirmTitle }}</b></VBtn
              >
            </div>

            <!-- <div class="selo d-flex justify-center mt-4">
            <img src="/images/selo_pagarme.png" />
          </div> -->
          </div>

          <VOverlay color="white" absolute :value="loading">
            <div class="d-flex flex-column">
              <h4>Processando pagamento, aguarde...</h4>
              <VProgressCircular size="42" color="primary" indeterminate />
            </div>
          </VOverlay>
        </div>
      </div>

      <VDialog v-model="openCoupon" :persistent="couponLoading" max-width="350">
        <VCard>
          <VCardTitle> Aplicar cupom </VCardTitle>
          <VCardText>
            <VTextField
              v-model="couponCode"
              rounded
              label="Cupom"
              placeholder="CODIGO_CUPOM"
              outlined
              autofocus
            />
            <VBtn
              rounded
              block
              dark
              :loading="couponLoading"
              color="new"
              depressed
              @click="applyCoupon"
            >
              <b>Aplicar</b>
            </VBtn>
          </VCardText>
        </VCard>
      </VDialog>
    </div>
  </div>
</template>

<script>
import creditCardType from 'credit-card-type'
import { mask } from 'vue-the-mask'
import { sendPlausibleEvent } from '../../utils/plausible'
import PlanSelector from './PlanSelector.vue'
import { Faq } from '.'
import { ENCRYPTION_KEY } from '@/constants/pagarme'
import { FormatMoney } from '~/utils/money'

export default {
  components: {
    PlanSelector,
    Faq
  },

  directives: {
    mask
  },

  data() {
    return {
      step: '1',
      couponLoading: false,
      coupon: null,
      couponCode: '',
      paymentType: 0,
      openCoupon: false,
      loading: false,
      refused: false,
      errors: {
        card_number: true,
        card_holder_name: true,
        card_expiration_month: true,
        card_expiration_year: true,
        card_cvv: true
      },
      paymentValues: {
        number: '',
        cvv: '',
        name: '',
        year: '',
        expiration: '',
        month: '',
        cpf: '',
        installments: '12'
      }
    }
  },

  middleware: 'requireAuthSignUp',

  computed: {
    selectedPlan() {
      return this.$store.getters['auth/selectedPlan']
    },
    price() {
      const price = this.selectedPlan.price

      if (this.coupon !== null) {
        const discountMultiplier = 1 - this.coupon.percentage / 100
        const newTotal = price * discountMultiplier

        return {
          raw: newTotal,
          monthly: FormatMoney(newTotal / this.installmentsNumber),
          total: FormatMoney(newTotal)
        }
      }

      return {
        raw: price,
        monthly: FormatMoney(price / this.installmentsNumber),
        total: FormatMoney(price)
      }
    },

    confirmTitle() {
      if (this.paymentType === 0) {
        return 'Confirmar compra'
      } else if (this.paymentType === 1) {
        return 'Gerar Boleto'
      } else {
        return 'Gerar PIX'
      }
    },

    installmentsNumber() {
      return this.paymentType === 0 ? this.paymentValues.installments : 1
    },

    installments() {
      const p = []

      for (let i = 1; i <= this.selectedPlan.maxInstallments; i++) {
        p.push({
          text: i < 10 ? '0' + i : i,
          value: i
        })
      }

      return p
    },

    creditType() {
      const types = creditCardType(
        this.paymentValues.number.split(' ').join('')
      )

      if (types.length === 1) {
        return types.pop().type
      } else {
        return null
      }
    }
  },

  watch: {
    selectedPlan(v) {
      this.paymentValues.installments = v.maxInstallments
    }
  },

  created() {
    this.paymentValues.installments = this.selectedPlan.maxInstallments
  },

  methods: {
    goToPayment() {
      this.step = '2'
      try {
        sendPlausibleEvent('Checkout - Pagamento')
        // eslint-disable-next-line no-undef
        fbq('InitiateCheckout')
      } catch (e) {
        console.error(e)
      }
    },
    async applyCoupon() {
      try {
        const { data } = await this.$axios.get(
          '/billing/coupon/' + this.couponCode
        )

        this.coupon = {
          id: data.id,
          code: data.coupon,
          percentage: data.percentage
        }

        this.openCoupon = false
        this.couponCode = ''
      } catch (e) {
        this.$toast.error('Cupom inválido')
      }
    },

    async processPayment() {
      const price = this.price.price

      // Extract digits from CPF
      const cpf = this.paymentValues.cpf.replace(/\D/g, '')

      /* PIX */
      if (this.paymentType === 2) {
        if (!this.$refs.formdata.validate()) return

        this.loading = true
        try {
          const pix = await this.$axios.post('/billing/pix/create', {
            cpf,
            address: this.paymentValues.address,
            affiliateId: this.coupon ? this.coupon.id : null,
            planId: this.selectedPlan.id
          })

          this.$gtag.event({
            event_category: 'payment',
            event_action: 'payment',
            event_label: 'payment_pix',
            event_value: parseInt(price)
          })

          this.$gtag('event', 'purchase', {
            transaction_id: pix.data.paymentId,
            affiliation: 'CoRedacao',
            value: parseInt(price),
            currency: 'BRL',
            items: [
              {
                id: 'premium',
                name: 'premium',
                quantity: 1,
                price: parseInt(price)
              }
            ]
          })

          this.$gtag('event', 'conversion', {
            send_to: 'AW-659130365/E6WFCKbmt_sCEP2PproC',
            value: parseInt(price),
            currency: 'BRL',
            transaction_id: pix.data.paymentId
          })

          this.$router.push('/conta/pagamento/' + pix.data.id + '?checkout=1')

          this.$emit('success')
        } catch (e) {
          const isCPFError =
            e.response?.data?.response?.errors?.find((err) => {
              return err.message === 'Invalid CPF'
            }) || false

          if (isCPFError) {
            this.$toast.error(
              'CPF inválido, favor verificar e tentar novamente.'
            )
          } else {
            this.$toast.error(
              'Houve um problema ao gerar o PIX, favor contatar o suporte'
            )
          }
        }
        this.loading = false
      } else {
        /* Credit Card */
        const fcredit = this.$refs.formcredit.validate()
        if (!fcredit) return

        const expirationData = this.paymentValues.expiration.split('/')

        if (expirationData.length < 2) {
          this.errors.card_expiration_date = 'Validade inválida'
          return
        }
        this.paymentValues.month = expirationData[0]
        this.paymentValues.year = expirationData[1]

        const cardDetails = {
          card_holder_name: this.paymentValues.name,
          card_expiration_date:
            this.paymentValues.month + '/' + this.paymentValues.year,
          card_number: this.paymentValues.number,
          card_cvv: this.paymentValues.cvv
        }

        // eslint-disable-next-line no-undef
        const cardValidations = pagarme.validate({ card: cardDetails })

        this.errors = cardValidations.card
        if (!this.$refs.formcredit.validate()) return

        this.refused = false
        this.loading = true

        try {
          // eslint-disable-next-line no-undef
          const client = await pagarme.client.connect({
            encryption_key: ENCRYPTION_KEY
          })
          const cardHash = await client.security.encrypt(cardDetails)

          const { data } = await this.$axios.post('/billing/card/create', {
            cpf,
            installments: this.paymentValues.installments,
            cardHash,
            affiliateId: this.coupon ? this.coupon.id : null,
            planId: this.selectedPlan.id
          })

          this.$store.dispatch('auth/reAuth')

          this.$router.push('/conta/pagamento/' + data.id)
          this.$emit('success')

          this.$gtag.event({
            event_category: 'payment',
            event_action: 'payment',
            event_label: 'payment_credit',
            event_value: parseInt(price)
          })
          this.$gtag('event', 'purchase', {
            transaction_id: data.id,
            affiliation: 'CoRedacao',
            value: parseInt(price),
            currency: 'BRL',
            items: [
              {
                id: 'premium',
                name: 'premium',
                quantity: 1,
                price: parseInt(price)
              }
            ]
          })

          this.$gtag('event', 'conversion', {
            send_to: 'AW-659130365/E6WFCKbmt_sCEP2PproC',
            value: parseInt(price),
            currency: 'BRL',
            transaction_id: data.id
          })
        } catch (e) {
          if (e.response.status === 400) {
            this.refused = true
          } else {
            this.$toast.error(
              'Houve um problema ao processar seu pagamento, cheque suas informações e tente novamente.'
            )
          }
        }
        this.loading = false
      }
    }
  },

  head() {
    return {
      title: 'Assinar - Pagamento',
      script: [
        { src: 'https://assets.pagar.me/pagarme-js/4.5/pagarme.min.js' }
      ],
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'Etapa de pagamento'
        }
      ]
    }
  }
}
</script>

<style lang="sass">
.checkoutnav
  .v-stepper__step
    padding: 12px 24px !important
    flex-direction: column
    .v-stepper__step__step
      font-weight: bold
      margin-right: 0 !important
    .v-stepper__label
      margin-top: 8px
.payment-flag
  height: 20px
.payment-icon
  height: 20px
.summary
  font-weight: 200
  background: white
  border-radius: 10px
  border: thin solid #9e9e9e
  tr:nth-child(3)
    background: #f1f1f1
  tr
    td
      padding: 10px
    td:nth-child(2)
      font-weight: normal
.payment
  max-width: 500px
  margin: 0 auto
  .selo
    img
      max-height: 60px

@media screen and (max-width: 960px)
  .checkoutnav
    .v-stepper__step
      max-width: 33.3%
      padding: 6px 12px !important
      .v-stepper__step__step
        margin-right: 0
      .v-stepper__label
        margin-top: 8px
        max-width: 100px
        font-size: 14px
        text-align: center
</style>
