<template>
  <div class="recover d-flex align-center justify-center flex-column">
    <div class="recoverform">
      <div class="d-flex align-center">
        <img class="recoverform__image" src="/images/logo_text.png" />
        <VChip small dark color="new" class="ml-2">
          <b>2.0</b>
        </VChip>
      </div>
      <div class="my-5">
        <h1>Recuperação de senha</h1>
        <p>
          {{ stepSubtitle }}
        </p>
      </div>
      <v-form ref="form">
        <VAlert v-if="error" class="mb-5" dark>
          <VIcon color="red" size="30">
            {{ $icons.error }}
          </VIcon>
          <span v-html="error" />
        </VAlert>
        <div v-if="step === 2">
          <VTextField
            v-model="password"
            rounded
            outlined
            dense
            :rules="[
              (v) => !!v || 'Senha é obrigatória',
              (v) => v.length >= 6 || 'Senha deve ter no mínimo 6 caracteres',
            ]"
            label="Senha"
            type="password"
            tabindex="0"
            @keyup.enter="doAction"
          >
            <template #prepend-inner>
              <VIcon class="recoverform__icon" size="22">
                {{ $icons.fingerprint }}
              </VIcon>
            </template>
          </VTextField>
          <VTextField
            v-model="passwordRepeat"
            rounded
            outlined
            dense
            label="Repita a senha"
            type="password"
            tabindex="0"
            @keyup.enter="doAction"
          >
            <template #prepend-inner>
              <VIcon class="recoverform__icon" size="22">
                {{ $icons.fingerprint }}
              </VIcon>
            </template>
          </VTextField>
        </div>
        <VTextField
          v-show="step === 1"
          v-model="code"
          autofocus
          rounded
          outlined
          hide-details
          dense
          color="new"
          class="mb-2"
          label="Digite o código"
          placeholder="000000"
        />
        <VTextField
          v-show="step === 0"
          v-model="email"
          color="new"
          label="Email"
          rounded
          outlined
          autofocus
          dense
          placeholder="seuemail@email.com"
          hide-details
          class="mb-2"
          tabindex="0"
          @keyup.enter="sendRecoverLink"
        >
          <template #prepend-inner>
            <VIcon class="recoverform__icon" size="22">
              {{ $icons.account }}
            </VIcon>
          </template>
        </VTextField>
      </v-form>
      <div class="d-flex pt-2">
        <VBtn
          outlined
          rounded
          :loading="loading"
          block
          dense
          color="white"
          class="new"
          depressed
          @click="doAction"
          ><b>{{ btnTitle }}</b></VBtn
        >
      </div>
    </div>
  </div>
</template>

<script>
const FORGOT_URL = `https://api.whatsapp.com/send/?phone=+5521982509256&text=Ol%C3%A1%2C+esqueci+minha+senha+do+coReda%C3%A7%C3%A3o.+Pode+me+ajudar%3F&app_absent=0`

export default {
  watchQuery: true,

  asyncData({ query }) {
    return {
      step: parseInt(query.step) || 0,
      email: query.email || '',
      code: query.code || '',
    }
  },

  data() {
    return {
      step: 0,
      email: '',
      code: '',
      password: '',
      passwordRepeat: '',
      loading: false,
      forgotUrl: FORGOT_URL,
      error: '',
    }
  },

  computed: {
    stepSubtitle() {
      switch (this.step) {
        case 0:
          return 'Insira seu e-mail no campo abaixo para recuperar sua senha.'
        case 1:
          return 'Lhe enviamos um e-mail com um código de recuperação. Insira o código no campo abaixo para trocar sua senha.'
        case 2:
          return 'Insira sua nova senha nos campos abaixo.'
        default:
          return 'Insira seu e-mail no campo abaixo para recuperar sua senha.'
      }
    },

    btnTitle() {
      switch (this.step) {
        case 0:
          return 'Receber código'
        case 1:
          return 'Recuperar senha'
        case 2:
          return 'Trocar senha'
        default:
          return 'Recuperar senha'
      }
    },
  },

  created() {
    if (this.step === 1 && !this.email) {
      this.$router.push({
        path: '/recuperar',
        query: {
          step: 0,
        },
      })
    }
  },

  methods: {
    doAction() {
      switch (this.step) {
        case 0:
          this.sendRecoverLink()
          break
        case 1:
          this.checkCode()
          break
        case 2:
          this.changePassword()
          break
      }
    },
    async checkCode() {
      this.loading = true
      try {
        await this.$axios.post('/users/reset-password/check', {
          email: this.email,
          code: this.code,
        })

        this.$router.push({
          path: '/recuperar',
          query: {
            step: 2,
            email: this.email,
            code: this.code,
          },
        })
      } catch (e) {
        this.error = 'Código inválido.'
      }
      this.loading = false
    },
    async changePassword() {
      if (!this.password) {
        return this.$toast.error('É necessário informar uma senha.')
      }

      if (this.password.length < 6) {
        return this.$toast.error('A senha deve ter no mínimo 6 caracteres.')
      }

      if (this.password !== this.passwordRepeat) {
        return this.$toast.error('As senhas não conferem.')
      }

      this.loading = true
      try {
        const { data } = await this.$axios.post(
          '/users/reset-password/change',
          {
            email: this.email,
            code: this.code,
            password: this.password,
          },
        )

        const token = data.token

        this.$toast.success('Senha alterada com sucesso!')

        this.$router.push('/auth/callback?token=' + token)
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async sendRecoverLink() {
      this.loading = true
      try {
        await this.$axios.post('/users/reset-password/code', {
          email: this.email,
        })

        this.$router.push({
          path: '/recuperar',
          query: {
            step: 1,
            email: this.email,
          },
        })
      } catch (e) {
        console.log(e)
        if (e.response?.status === 404) {
          this.error = `Não encontramos nenhum usuário com esse e-mail. Verifique se você digitou corretamente ou entre em contato com o suporte <a href="${FORGOT_URL}" class="">clicando aqui.</a>`
        } else {
          this.error = 'Ocorreu um erro ao enviar o e-mail de recuperação.'
        }
      }
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.recover
  min-height: calc(100vh - 40px)
  height: 100vh
  position: relative
.recoverform
  width: 450px
  background-color: white
  padding: 48px
  border-radius: 10px
  box-shadow: 2px 2px 5px 0px rgba(214, 214, 214, 0.75)
  position: relative
  margin-bottom: 120px
  border: 1px solid #ddd
  max-width: 100%
  p
    font-size: 14px
  h1
    font-size: 22px
    font-weight: semibold
  .recoverform__image
    max-width: 100%
    width: 150px
  .auth-divider
    position: relative
    height: 70px
    display: flex
    align-items: center
    span
      position: absolute
      background-color: white
      top: 0
      bottom: 0
      height: 20px
      margin: auto
      width: 150px
      text-align: center
      left: 0
      letter-spacing: 0.3px
      font-size: 12px
      right: 0
  .recoverform__icon
    margin-top: 1px
    margin-right: 4px
  .recoverform__social
    .recoverform__social__text
      width: 185px
      text-align: left
    .recoverform__social__icon
      width: 22px
@media (max-width: 600px)
  .recoverform
    padding: 16px
    max-width: 400px
    width: 100%
    margin-top: 32px
    &:after, &:before
      display: none
</style>
