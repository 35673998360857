<template>
  <div class="faq mx-auto">
    <feature subtitle="FAQ" title="Dúvidas?" caption="" centered>
      Abaixo algumas das perguntas mais frequentes feitas pelos alunos.
    </feature>
    <template>
      <v-row justify="center">
        <v-expansion-panels
          v-for="(question, i) in questions"
          :key="i"
          accordion
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="d-flex align-center">
              <img class="faq-icon" src="/images/checkout/question.svg" />
              <b>{{ question.title }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="question.description" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </div>
</template>

<script>
import Feature from '@/components/landing/Feature'
const ESCOLA_URL = `https://api.whatsapp.com/send/?phone=+5521982509256&text=Quero saber como usar o coRedação em minha escola&app_absent=0`

export default {
  components: {
    Feature,
  },
  data() {
    return {
      questions: [
        {
          title: 'A correção é confiável?',
          description:
            'Sim! A inteligência artificial que corrige sua redação foi treinada pela Professora Raquel de Lima, que possui anos de experiência na correção das redações do ENEM.',
        },
        {
          title: 'Quem corrige as redações?',
          description:
            'Sua redação é corrigida pela Inteligência Artificial do coRedação. Além de dar uma nota por competência, ela também faz comentários sobre o seu texto, dá sugestões de melhorias e apresenta métricas comparando-o com redações nota 1000.',
        },
        {
          title: 'Em quanto tempo a redação será corrigida?',
          description:
            'No coRedação, sua redação é corrigida em 3 segundos. Dessa forma, você não sente ansiedade ao esperar pela nota e evolui mais rápido!',
        },
        {
          title: 'Quantas redações posso enviar para correção?',
          description: `Na versão gratuita, você receberá 6 créditos por mês para praticar. Esse número foi pensado para que você escreva uma redação por semana, o ideal para se preparar para a prova do ENEM.

Já na versão paga, você pode enviar suas redações de forma ilimitada, pois treinar e corrigir seus erros é fundamental para mandar bem na prova!`,
        },
        {
          title: 'Qual a diferença entre a versão gratuita e o plano pago?',
          description: `Na versão gratuita, você pode enviar 6 redações por mês para correção. Além disso, é possível visualizar as notas por competência e algumas marcações no texto, bem como acessar temas de redação e materiais, como guias de redação. <br /><br />

            Já na versão paga, você pode enviar redações ilimitadas para correção. Além disso, é possível verificar as notas por competência, ter acesso as marcações dos erros avançados encontrados no texto, ler os comentários com sugestões de melhoria, analisar as estatísticas, acessar as aulas do Curso Redação de Sucesso e participar da comunidade exclusiva no WhatsApp.
            `,
        },
        {
          title: 'Posso acessar o coRedação pelo celular?',
          description:
            'Sim! Tanto pelo celular quanto pelo computador, você pode enviar suas redações para correção no coRedação.',
        },
        {
          title: 'Posso compartilhar minha conta com outra pessoa?',
          description:
            'Não. A conta é de uso pessoal e intransferível, sendo proibido vender, emprestar ou compartilhar a mesma. ',
        },
        {
          title: 'Como usar o coRedação na minha escola?',
          description: `Caso queira oferecer o coRedação aos seus alunos entre em contato pelo <a href="${ESCOLA_URL}" target="_blank">nosso WhatsApp</a>.`,
        },
      ],
    }
  },
}
</script>

<style lang="sass">
.faq
  width: 600px
  max-width: calc(100% - 32px)
  margin-bottom: 64px
  .faq-icon
    max-width: 22px
    margin-right: 6px
  .v-expansion-panel-content__wrap
    font-size: 14px
    line-height: 18px
    text-align: justify
  b
    font-size: 14px
    line-height: 18px

  @media screen and (max-width: 960px)
    .v-expansion-panel-content__wrap
      font-size: 12px
</style>
