import dayjs from 'dayjs'
import ContentAPI from '~/plugins/ghost'
import { parseTagsArray, parseTagsInPosts } from '@/utils/tagsParser'

const FREE_THEME = {
  id: 'tema-livre',
  title: 'Tema Livre',
  slug: 'tema-livre',
  description: 'Escolha um tema livre para sua redação',
  feature_image:
    'https://conteudo.coredacao.com/content/images/2023/06/19468018.jpg',
  tags: [
    {
      id: 'tema-livre',
      name: 'Tema Livre',
      slug: 'tema-livre',
      color: '#000000',
    },
  ],
}

let themes = []
let tags = []
let lastTimeLoaded = null

const getTheme = (slug) => {
  return themes.find((theme) => theme.slug === slug)
}

const shouldReloadThemes = () => {
  if (!lastTimeLoaded) return true

  const now = dayjs()
  const diff = now.diff(lastTimeLoaded, 'minutes')

  return diff >= 10
}

const loadThemes = async () => {
  if (!shouldReloadThemes()) return { themes, tags }

  const promisesRes = await Promise.all([
    ContentAPI.posts.browse({
      limit: 'all',
      include: ['tags'],
      filter: 'tag:temas-de-redacao',
      fields: ['id', 'slug', 'title', 'feature_image'],
    }),
    ContentAPI.tags.browse({ limit: 'all' }),
  ])

  const ghostContents = promisesRes[0]
  const ghostTags = promisesRes[1]

  themes = parseTagsInPosts(ghostContents)
  tags = parseTagsArray(ghostTags, 'theme')

  lastTimeLoaded = dayjs()

  themes.unshift(FREE_THEME)

  return { themes, tags }
}

export { shouldReloadThemes, loadThemes, getTheme }
