<template>
  <div class="manutencao d-flex justify-center w-full">
    <VCard max-width="500">
      <VCardTitle class="text-center title font-weight-bold"
        ><h1>Manutenção</h1></VCardTitle
      >
      <VCardText class="text-center">
        <h3>Estamos em manutenção. Volte mais tarde.</h3>
      </VCardText>
    </VCard>
  </div>
</template>

<script>
export default {
  layout: 'empty',
}
</script>

<style lang="sass" scoped>
.manutencao
  width: 100%
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
</style>
