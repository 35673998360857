<template>
  <div class="">
    <AppNav
      sub
      image="/images/dashboard/icons/theme_on.svg"
      :title="tag ? tag.name : ''"
      :subtitle="tag ? tag.description : ''"
    >
      <template #side> </template>
    </AppNav>
    <div>
      <ContentWrapper
        lazy
        no-pagination
        :hide-pagination="true"
        :loading="loading"
        :filter="filter"
        :contents="contents"
      >
        <template #empty>
          <empty-state
            title="Sem resultados"
            subtitle="Não conseguimos encontrar nenhum artigo com os filtros selecionados"
            image="/images/empty/essay.png"
          />
        </template>
      </ContentWrapper>
    </div>
  </div>
</template>

<script>
import EmptyState from '@/components/common/EmptyState'
import ContentWrapper from '@/components/content/ContentWrapper'
import AppNav from '@/components/app/AppNav'
import ContentAPI from '@/plugins/ghost'
import { parseTagsInPosts } from '@/utils/tagsParser'

const defaultInfo = {
  title: 'Temas de redação',
  subtitle: 'Diversos temas de redação para seus estudos',
  default: true,
}

export default {
  components: {
    ContentWrapper,
    EmptyState,
    AppNav,
  },

  layout: 'app',

  middleware: 'requireAuth',

  data() {
    return {
      contents: [],
      filter: null,
      info: defaultInfo,
      loading: true,
      drawing: false,
      tag: null,
      tags: [],
      weeklyThemes: [],
      pagePath: '/temas',
      page: 1,
    }
  },

  watch: {
    $route() {
      this.loading = false
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      /* Check if its a p  */
      this.loading = true

      this.weeklyThemes = [{}, {}, {}]
      this.contents = [{}, {}, {}, {}, {}, {}]

      const slug = this.$route.params.id

      const promisesRes = await Promise.all([
        ContentAPI.posts.browse({
          limit: 'all',
          include: ['tags'],
          filter: `tag:${slug}`,
          fields: ['id', 'slug', 'title', 'feature_image', 'custom_excerpt'],
        }),
        ContentAPI.tags.browse({ limit: 'all' }),
      ])

      const ghostContents = promisesRes[0]
      const ghostTags = promisesRes[1]

      const contents = parseTagsInPosts(ghostContents)
      const tags = ghostTags
      const tag = ghostTags.find((t) => t.slug === slug)

      if (tag === null) {
        this.$nuxt.error({ statusCode: 404 })
      }

      console.log(contents, tags, tag)

      this.contents = contents
      this.tags = tags
      this.tag = tag
      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.tags-wrapper
  display: grid
  grid-gap: 1.4em
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  .tag-block
    border-radius: 10px
    padding: 12px
    color: white
    h3
      font-size: 28px
    &:hover
      .VIcon
        animation: move 800ms infinite ease-in-out

.weeklythemes
  position: relative
  margin-bottom: 48px
.soon-bg
  background-image: url('/images/soon/bgsoon.jpg')
  background-size: cover
  opacity: 0.05
  width: 100vw
  left: 0
  top: 0
  height: 100%
  position: absolute
  z-index: 0
.essaybar
  position: relative
  z-index: 2

@media screen and (max-width: 960px)
  .tags-wrapper
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))
  .themes
    padding: 0 20px
    h2
      font-size: 18px
@keyframes move
  0%
    left: 0
  50%
    left: -10px
  100%
    left: 0
</style>
