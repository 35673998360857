var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WelcomeHeader',{attrs:{"title":"Quais seu principal objetivo no coRedação?"}},[_vm._v("\n    Isso vai nos ajudar a melhorar sua experiência.\n  ")]),_vm._v(" "),_c('Chooser',{staticClass:"mt-4",attrs:{"options":[
      {
        value: 'Descobrir a nota da minha redação',
        name: 'Descobrir a nota da minha redação'
      },
      {
        value: 'Descobrir o que estou errando na minha redação',
        name: 'Descobrir o que estou errando na minha redação'
      },
      {
        value: 'Aprender a escrever com o curso, apostila e conteúdos',
        name: 'Aprender a escrever com o curso, apostila e conteúdos'
      },
      {
        value: 'Ver modelos de redação',
        name: 'Ver modelos de redação'
      }
    ]},model:{value:(_vm.goal),callback:function ($$v) {_vm.goal=$$v},expression:"goal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }