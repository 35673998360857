import StepOne from "./StepOne.vue";
import StepTwo from "./StepTwo.vue";
import StepThree from "./StepThree.vue";
import StepFour from "./StepFour.vue";
import StepFive from "./StepFive.vue";

export const WelcomeSteps = [
  {
    component: StepOne
  },
  {
    component: StepTwo
  },
  {
    component: StepThree
  },
  {
    component: StepFour
  },
  {
    component: StepFive
  }
]
