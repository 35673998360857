<template>
  <div class="welcome">
    <VDialog
      :value="shouldShow"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      no-spacer
      centered-title
      :closable="false"
      persistent
      no-padding
      color="#FF6338"
      :max-width="900"
      scrollable
    >
      <VContainer class="welcome-content ma-0">
        <div class="welcome-progress">
          <VProgressLinear
            rounded
            color="new"
            height="10"
            :value="progress"
          ></VProgressLinear>
        </div>

        <div class="my-5">
          <component :is="currentStepComponent.component" />
        </div>

        <v-layout class="d-flex justify-center pb-5">
          <VBtn
            rounded
            dark
            class="mt-4"
            depressed
            large
            color="new"
            @click="onContinue"
          >
            <b class="px-5">Continuar</b>
          </VBtn>
        </v-layout>
      </VContainer>
    </VDialog>
  </div>
</template>

<script>
import { WelcomeSteps } from '../app/Welcome/index'

export default {
  data() {
    return {
      step: 0
    }
  },

  computed: {
    progress() {
      return ((this.step + 1) * 100) / WelcomeSteps.length
    },
    currentStepComponent() {
      return WelcomeSteps[this.step]
    },
    preferences() {
      return this.$store.getters['auth/preferences']
    },

    shouldShow() {
      return !this.preferences?.welcome
    }
  },

  watch: {
    $route() {
      const etapa = this.$route.query.etapa || 0
      this.step = parseInt(etapa)
    }
  },

  mounted() {
    if (this.shouldShow) this.showCelebration()
  },

  methods: {
    onContinue() {
      if (this.step === 1 && !this.$store.state.welcome.exam) {
        return this.$toast.error('Selecione uma opção')
      }

      const exam = this.$store.state.welcome.exam

      if (this.step === 1 && exam === 'FUVEST') {
        return this.$router.push({ query: { etapa: this.step + 2 } })
      }

      if (this.step === 2) {
        if (exam === 'ENEM' && !this.$store.state.welcome.lastEnemGrade) {
          return this.$toast.error('Selecione uma opção')
        } else if (
          exam === 'CONCURSO' &&
          !this.$store.state.welcome.concursoName
        ) {
          return this.$toast.error('Digite o nome do concurso')
        } else if (
          exam === 'OUTRO_VESTIBULAR' &&
          !this.$store.state.welcome.vestibularName
        ) {
          return this.$toast.error('Digite o nome do vestibular')
        }
      }

      if (this.step === 3 && !this.$store.state.welcome.goal) {
        return this.$toast.error('Seleciona uma opção')
      }

      if (this.step === 4 && !this.$store.state.welcome.source) {
        return this.$toast.error('Seleciona uma opção')
      }

      if (this.step === 4) {
        this.$store.dispatch(
          'welcome/saveOnboardingAnswers',
          this.$store.state.welcome
        )
        this.$store.dispatch('auth/setPreference', {
          key: 'welcome',
          value: '1'
        })

        this.$store.commit('welcome/setShowFirstSteps', true)

        return
      }

      this.$router.push({ query: { etapa: this.step + 1 } })
    },
    start() {
      this.$store.dispatch('auth/setPreference', {
        key: 'welcome',
        value: '1'
      })
    },
    showCelebration() {
      this.$confetti.start()

      setTimeout(() => {
        this.$confetti.stop()
      }, 2000)
    }
  }
}
</script>

<style lang="sass">
.confetti
  width: 30px
  height: 30px

.welcome
  ul
    list-style-type: none

.welcome-content
  font-size: 18px
  background-color: white
  min-height: 600px
  padding: 40px

  .welcome-progress
    display: flex
    width: 100%
    height: 10px
    margin-bottom: 20px

@media screen and (max-width: 600px)
  .welcome-content
    font-size: 16px
    overflow-y: scroll
    padding: 20px
  .confetti
    width: 20px
    height: 20px
</style>
