<template>
  <div class="contentwrapper">
    <div
      class="contentwrapper__posts"
      :class="{ 'contentwrapper__posts--app': app && !theme }"
    >
      <div
        v-for="content in paginatedContent"
        :key="content.slug"
        class="contentwrapper__post"
      >
        <ContentCard
          :key="content.slug"
          :hide-tags="hideTags"
          :lazy="lazy"
          :app="app"
          :related="related"
          :theme="theme"
          :loading="loading"
          :content-path="contentPath"
          :content="content"
          @loading="$emit('page-change')"
        />
      </div>
    </div>
    <div v-show="paginatedContent.length === 0" class="contentwrapper__empty">
      <slot name="empty" />
    </div>
    <div
      v-show="totalPages > 0"
      class="contentwrapper__pages d-flex justify-center"
    >
      <content-pagination
        v-if="!noPagination"
        :length="totalPages"
        :total-visible="5"
        :current-page="currentPage"
        :page-path="pagePath"
        @change="pageChange"
      ></content-pagination>
    </div>
  </div>
</template>

<script>
import ContentCard from './ContentCard.vue'
import ContentPagination from './ContentPagination'
const stringSimilarity = require('string-similarity')

export default {
  components: {
    ContentCard,
    ContentPagination,
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false
      },
    },

    noPagination: {
      type: Boolean,
      default() {
        return false
      },
    },

    theme: {
      type: Boolean,
      default() {
        return false
      },
    },

    contents: Array,

    filter: {
      type: Object,
      default() {
        return null
      },
    },

    itemsPerPage: {
      type: Number,
      default() {
        return 12
      },
    },

    initialPage: {
      type: Number,
      default() {
        return 1
      },
    },

    isUneven: Boolean,
    related: Boolean,
    hidePagination: Boolean,
    app: Boolean,
    lazy: Boolean,
    hideTags: Boolean,
    contentPath: String,
    pagePath: String,
  },

  data() {
    return {
      currentPage: 1,
    }
  },

  computed: {
    filteredContent() {
      if (!this.filter || this.filter === null) return this.contents

      let filteredContent = this.contents.slice()

      const search = this.filter?.search
        ?.toLowerCase()
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036F]/g, '')
        .trim()

      /* Search by title */
      if (search) {
        const searchStr = search

        const searchResult = filteredContent.filter((c) => {
          if (!c.cleanTitle)
            c.cleanTitle = c?.title
              ?.toLowerCase()
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036F]/g, '')

          if (c?.cleanTitle?.includes(searchStr)) {
            c.similarity = 1
            return true
          }

          if (!c?.cleanTitle) return false

          c.similarity = stringSimilarity.compareTwoStrings(
            searchStr,
            c?.cleanTitle,
          )

          if (c.similarity > 0.2) return true
        })

        filteredContent = searchResult.sort((a, b) => {
          if (a.similarity > b.similarity) {
            return -1
          }
          if (a.similarity < b.similarity) {
            return 1
          }
          // a deve ser igual a b
          return 0
        })
      }

      /* Filter by tag */
      if (
        !!this.filter.tag &&
        this.filter.tag !== null &&
        this.filter.tag.id !== 'all'
      ) {
        filteredContent = filteredContent.filter((c) => {
          for (let i = 0; i < c.tags.length; i++) {
            const tag = c.tags[i]

            if (tag.id === this.filter.tag.id) return true
          }

          return false
        })
      }

      return filteredContent
    },

    paginatedContent() {
      if (this.noPagination) return this.filteredContent

      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage

      return this.filteredContent.slice(start, end)
    },

    totalPages() {
      return Math.ceil(this.filteredContent.length / this.itemsPerPage)
    },
  },

  watch: {
    filter() {
      this.currentPage = 1
    },
  },

  created() {
    this.currentPage = parseInt(this.initialPage)
  },

  methods: {
    resolvePosition(i) {
      if (this.app && !this.theme) return 0

      const total = this.paginatedContent.length
      const r = i % 6

      if (total - i === 2) {
        if (r === 1) return 2
      }

      if (total - i === 1) {
        if (r === 0) return 0
        if (r === 1) return 0
        if (r === 2) return 2
        if (r === 4) return 0
      }

      if (r > 0 && r < 4) return 1
      if (r > 3) return 2

      return r
    },

    pageChange() {
      setTimeout(() => {
        if (process.client) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }
      }, 0)
      this.$emit('page-change')
    },
  },
}
</script>

<style lang="sass" scoped>
.contentwrapper
  border-radius: 5px

  .contentwrapper__posts
    display: grid
    grid-gap: 1.8em
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))

    .contentwrapper__post
      width: 100%
      max-width: 100%

  .contentwrapper__pages
    margin-top: 25px

.contentwrapper--content
  .contentwrapper__posts
    display: flex
    flex-wrap: wrap
    justify-content: space-between
  .contentwrapper__ponts::after
    content: ""
    flex: auto

@media screen and (max-width: 600px)
  .contentwrapper
    border-radius: 5px

    .contentwrapper__posts
      grid-gap: 1em
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)) !important
</style>
