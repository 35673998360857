<template>
  <component
    :class="{ 'textserif--large': large, 'textserif--small': small }"
    :is="this.component"
    class="textserif"
    :style="style"
  >
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    component: {
      type: String,
      default: function () {
        return 'span'
      },
    },

    color: {
      type: String,
      default: function () {
        return '#020224'
      },
    },

    bold: {
      type: Boolean,
      default: function () {
        return false
      },
    },

    center: {
      type: Boolean,
      default: function () {
        return false
      },
    },

    large: Boolean,
    small: Boolean,
  },

  computed: {
    style() {
      const size = parseInt(this.size)
      return {
        color: this.color,
        'font-weight': `${this.fontWeight}`,
        'line-height': 1,
        'text-align': this.center ? 'center' : 'left',
      }
    },

    fontWeight() {
      if (this.bold) return 900
      else return 600
    },
  },
}
</script>

<style lang="sass" scoped>
.textserif
  font-family: 'Quincy CF', serif
  font-size: 42px
  line-height: 45px
  letter-spacing: 0.5px
.textserif--large
  font-size: 68px
  line-height: 65px
  letter-spacing: 1px
.textserif--small
  font-size: 28px
  line-height: 32px
  letter-spacing: 1px

@media screen and (max-width: 900px)
  .textserif--large
    font-size: 62px

@media screen and (max-width: 600px)
  .textserif
    font-size: 32px
  .textserif--large
    font-size: 44px
</style>
