<template>
  <VItemGroup :value="value">
    <VContainer>
      <VRow>
        <VCol v-for="(opt, i) in options" :key="i" cols="12" md="12">
          <VItem v-slot="{}">
            <VCard
              class="d-flex align-center"
              :class="{
                new: opt.value === value
              }"
              :style="{
                color: opt.value === value ? 'white' : 'black'
              }"
              height="50"
              @click="onInput(opt.value)"
            >
              <div class="px-2 py-1 mx-auto font-weight-bold text-center">
                {{ opt.name }}
              </div>
            </VCard>
          </VItem>
        </VCol>
      </VRow>
    </VContainer>
  </VItemGroup>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String || Number,
      required: true
    }
  },

  methods: {
    onInput(ev) {
      this.$emit('input', ev)
    }
  }
}
</script>
