<template>
  <div class="align-start mt-2 mb-5 pt-5">
    <div class="d-flex flex-column align-start mb-1">
      <h3 class="EssayStatsItem__label">
        {{ label }}
      </h3>
      <p v-html="info"></p>
    </div>
    <div class="StatsItemProgress">
      <VProgressLinear
        background-color="grey lighten-2"
        height="15"
        reverse
        :value="distances.left"
        color="green lighten-3"
        rounded
      ></VProgressLinear>
      <VTooltip bottom>
        <template v-slot:activator="{ on }">
          <div class="StatsItemProgressMedian" v-on="on"></div>
        </template>
        <span>
          Média redações nota 1000:
          <b class="title font-weight-bold">{{ median }}</b>
          {{ label.toLowerCase() }}
        </span>
      </VTooltip>
      <div
        :class="`StatsItemProgressValue ${color}`"
        :style="{
          left: `${distancePercentage}%`,
        }"
      >
        <VTooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ value }}
            </span>
          </template>
          <span> Seu texto </span>
        </VTooltip>
      </div>
      <VProgressLinear
        rounded
        background-color="grey lighten-2"
        height="15"
        :value="distances.right"
        color="green lighten-3"
      ></VProgressLinear>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    median: {
      type: Number,
      required: false,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    maxLimit: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    diffFromMedian() {
      return this.value - this.median
    },

    barSize() {
      const maxDistance = this.max - this.median

      return {
        min: this.median,
        max: maxDistance * 3,
      }
    },

    distances() {
      const minDistance = this.median - this.min
      const maxDistance = this.max - this.median

      return {
        left: (minDistance / this.barSize.min) * 100,
        right: this.maxLimit ? 100 : (maxDistance / this.barSize.max) * 100,
      }
    },

    valueDistance() {
      if (this.value < this.median) {
        return (this.value - this.median) / this.barSize.min
      } else if (this.value > this.median) {
        return (this.value - this.median) / this.barSize.max
      }
      return 0
    },

    distancePercentage() {
      if (this.value < this.median) {
        return 50 - Math.abs(this.valueDistance) * 50
      } else if (this.value > this.median) {
        return 50 + Math.abs(this.valueDistance) * 50
      }

      return 50
    },

    color() {
      if (
        (this.value <= this.max && this.value >= this.min) ||
        (this.value >= this.max && this.maxLimit)
      ) {
        return 'green darken-2'
      } else {
        return 'error'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.EssayStatsItem__label, .EssayStatsItem__value
  display: inline-flex
  align-items: center
.EssayStatsItem__label
  font-weight: bold
.EssayStatsItem__value
  width: 30px
  margin-right: 4px
  font-size: 20px
  font-weight: bold
  justify-content: flex-end
.StatsItemProgress
  height: 20px
  width: 100%
  display: flex
  align-items: center
  border-radius: 4px
  position: relative
.StatsItemProgressMedian
  height: 20px
  width: 10px
  border-left: 2px solid white
  border-right: 2px solid white
  border-radius: 2px
  background-color: black
  position: absolute
  left: 50%
  transform: translateX(-50%)
  z-index: 1
.StatsItemProgressValue
  cursor: pointer
  font-weight: bold
  display: flex
  align-items: center
  color: white
  font-size: 18px
  padding: 0 6px
  position: absolute
  z-index: 10
  transform: translateX(-50%)
  border-radius: 4px
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 5px 0px #9e9e9e
</style>
