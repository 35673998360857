<template>
  <div>
    <div class="results-cat d-flex flex-wrap no-gutters">
      <VCard
        v-for="(competence, i) in competences.slice(1)"
        :key="i"
        v-ripple="{ class: `new--text` }"
        max-width="calc(20% - 12px)"
        class="category"
        outlined
        @click="
          $root.$emit('openCompetence', {
            competence: `C${i + 1}`,
            grade: getGrade(i),
          })
        "
      >
        <VListItem>
          <VListItemContent class="comp-content-container">
            <div class="comp-content">
              <span
                :style="{ color: competence.color }"
                class="comp-title font-weight-bold"
              >
                <VIcon class="hidden-md-and-up" size="18">
                  {{ $icons.info }}
                </VIcon>
                Competência {{ competence.value[1] }}
              </span>
              <VDivider class="my-2 hidden-sm-and-down" />
              <div class="headline mb-1 text-center">
                <h2 class="grade accent--text d-flex w-full">
                  <span v-if="hasCorrection" class="result-total">
                    {{ getGrade(i) }}
                  </span>
                  <span class="result-total grey--text text--lighten-1">
                    /200
                  </span>
                </h2>
              </div>
            </div>
          </VListItemContent>
        </VListItem>
      </VCard>
    </div>
  </div>
</template>

<script>
import competences from '@/constants/competences'

export default {
  props: {
    essay: Object,
  },

  data() {
    return {
      competences,
    }
  },

  computed: {
    hasCorrection() {
      return this.essay !== null && this.essay.correction !== null
    },

    correction() {
      return this.essay.corrections[0]
    },

    grades() {
      return this.correction?.grades
    },
  },

  methods: {
    getGrade(i) {
      return this.grades[i]
    },

    getLevel(competence, grade) {
      const i = grade / 40

      return competence.levels[i]
    },
  },
}
</script>

<style lang="sass">
.comp-content-container
  cursor: pointer
  .v-list-item
    padding: 0 !important
  .headline
    margin-top: 8px
    text-align: center
  .grade
    justify-content: center
.category
  width: 100%
  transition: all 0.2s ease-in-out
  &:hover
    background-color: #FEF6F0 !important
  .comp-content
    .comp-title
      width: 100%
      display: inline-block
      text-align: center
.results-cat
  flex: 1 1
  justify-content: space-between
  position: relative

  .result-loader
    width: 50px
    height: 25px
    margin-right: 8px
  .res-actions
    position: absolute
    bottom: 0
    width: 100%
  .small-description
    height: auto
    color: rgba(0, 0, 0, 0.6)
    font-size: 14px
    padding-bottom: 44px
  .result-total
    font-size: 22px

@media screen and (max-width: 960px)
  .comp-content-container
    padding: 4px 0
  .results-cat
    grid-gap: 0
    border: thin solid #9e9e9e
    overflow: hidden
    border-radius: 20px
    .v-sheet.v-card
      border-radius: 0 !important
    .category
      max-width: 100% !important
      width: 100%
      border: none !important
      border-bottom: thin solid #9e9e9e !important
      .comp-content
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        align-items: center
        width: 100%
        span
          font-size: 18px
          white-space: nowrap
        .comp-title
          display: inline
          margin-left: 0
          text-align: left
    &:last-child
      border-bottom: 0 !important
</style>
