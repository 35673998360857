<template>
  <div class="pagination d-flex">
    <div
      @click="onChange(pagePath + (currentPage - 1))"
      v-if="this.currentPage > 1"
      v-ripple
      class="pagination__item"
    >
      <NuxtLink class="pagination__link" :to="pageHref(currentPage - 1)">
        <VIcon size="20">{{ $icons.arrowLeft }}</VIcon>
      </NuxtLink>
    </div>
    <div
      @click="onChange(pagePath + page)"
      v-for="page in pages.left"
      :key="page"
      v-ripple
      class="pagination__item"
    >
      <NuxtLink rel="prev" class="pagination__link" :to="pageHref(page)">
        <span>{{ page }}</span>
      </NuxtLink>
    </div>
    <div class="pagination__item primary white--text pagination__item--current">
      <span>{{ currentPage }}</span>
    </div>
    <div
      @click="onChange(pagePath + page)"
      v-for="page in pages.right"
      :key="page"
      v-ripple
      class="pagination__item"
    >
      <NuxtLink rel="next" class="pagination__link" :to="pageHref(page)">
        <span>{{ page }}</span>
      </NuxtLink>
    </div>
    <div
      @click="onChange(pagePath + (currentPage + 1))"
      v-if="this.currentPage < this.length"
      v-ripple
      class="pagination__item"
    >
      <NuxtLink
        rel="next"
        class="pagination__link"
        :to="pageHref(currentPage + 1)"
      >
        <VIcon size="20">{{ $icons.arrowRight }}</VIcon>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalVisible: Number,
    length: Number,
    currentPage: Number,
    pagePath: String,
  },

  methods: {
    onChange(page) {
      console.log('pagination changed')
      this.$emit('change', page)
    },

    pageHref(page) {
      page = page > 1 ? page : ''
      return this.pagePath + page
    },
  },

  computed: {
    pages() {
      const offset = Math.round(this.totalVisible / 2)
      const offsetLeft = this.currentPage - offset
      const offsetRight = this.currentPage + offset

      const leftRest = offsetLeft < 0 ? Math.abs(offsetLeft) : 0

      let pagesLeft = []
      for (let i = offsetLeft > 0 ? offsetLeft : 1; i < this.currentPage; i++) {
        pagesLeft.push(i)
      }

      let pagesRight = []
      for (let i = this.currentPage + 1; i < offsetRight + leftRest; i++) {
        if (i > this.length) break
        pagesRight.push(i)
      }

      return {
        right: pagesRight,
        left: pagesLeft,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.pagination
  .pagination__link
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    color: #333
    text-decoration: none
  .pagination__item
    width: 34px
    height: 34px
    margin: 0.3em
    display: flex
    justify-content: center
    align-items: center
    font-size: 16px
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px #9e9e9e
    cursor: pointer
    border-radius: 4px
    font-weight: bold
  .pagination__item--current
    cursor: default
</style>
