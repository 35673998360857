<template>
  <modal v-model="pop" :color="color">
    <template #title>
      <span class="mark__title">
        {{ mark.title }}
      </span>
    </template>
    <div v-if="pop" class="">
      <VBtn
        v-if="mark.competence !== 'OUTROS'"
        rounded
        x-small
        :color="color"
        dark
        depressed
        class="mb-2 d-flex align-center caption"
        @click="$root.$emit('openCompetence', mark)"
      >
        Competência {{ mark.competence }}
      </VBtn>
      <div class="mark__comment">
        {{ mark.comment }}
      </div>
      <VDivider class="my-2"></VDivider>
      <div>
        <div class="caption font-weight-bold">{{ markLabel }} destacado</div>
        <div v-html="`&quot;${mark.content}&quot;`"></div>
      </div>

      <div
        v-if="mark.replacements.length > 0"
        class="text-marker__replacement mt-4"
      >
        <div
          v-if="mark.replacements.length > 1"
          class="caption font-weight-bold"
        >
          Possíveis substituições
        </div>
        <div v-else class="caption font-weight-bold">Possível substituição</div>
        <div class="replacements d-flex flex-wrap">
          <VChip v-for="r in mark.replacements" :key="r" dark color="green">
            {{ r }}
          </VChip>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import competences from '@/constants/competences'

export default {
  components: {
    Modal: () => import('@/components/helpers/Modal'),
  },
  data() {
    return {
      mark: {},
      pop: false,
    }
  },

  computed: {
    markLabel() {
      let label = 'trecho'

      if (this.mark.level === 'sentence') {
        label = 'Frase'
      } else if (this.mark.level === 'paragraph') {
        label = 'Parágrafo'
      }

      return label
    },
    color() {
      const c = competences
        .filter((c) => c.value === this.mark.competence)
        .pop()
      return c ? c.color : ''
    },
  },
  created() {
    this.$root.$on('openMarkModal', (mark) => {
      console.log('receive', mark)
      this.mark = mark
      if (!this.pop) this.pop = true
    })
  },
}
</script>

<style lang="sass" scoped>
.mark__title
  font-size: 18px
  overflow-wrap: normal
  word-break: normal
  text-align: left
.mark__comment
  font-size: 16px
@media screen and (max-width: 960px)
  .mark__title
    font-size: 16px
</style>
