<template>
  <div class="callback d-flex justify-center mt-5">
    <VProgressCircular
      indeterminate
      color="primary"
      size="48"
    ></VProgressCircular>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'

const DEFAULT_URL = '/'

export default {
  data() {
    return {
      userSaved: null,
      created: false,
      oldToken: ''
    }
  },

  computed: {
    ...mapGetters({
      redirectUrl: 'auth/redirectUrl',
      user: 'auth/currentUser'
    })
  },

  async mounted() {
    const token = this.$route.query.token
    const created = this.$route.query.created === '1'

    this.$gtag('config', { referrer: 'https://app.coredacao.com' })

    if (created) {
      this.fbConversion()
      this.analyticsConversion()
      // this.setFirstTime(true)
    } else {
      this.$gtag.event({
        eventCategory: 'auth',
        eventAction: 'signin',
        eventLabel: 'signin'
      })
    }

    if (token) {
      const user = await this.reAuth({
        token,
        skipCommit: true
      })

      this.userSaved = user
      this.created = created

      // if (user.v1User && !!user.v1User.user?.subscriptionDate) {
      //   this.oldToken = user.v1User.token
      //   this.showVersionSelector = true
      //   return
      // }

      await this.continueLogin()
    } else {
      this.$router.push('/entrar')
    }
  },

  methods: {
    ...mapActions({
      reAuth: 'auth/reAuth'
    }),
    ...mapMutations({
      signIn: 'auth/signIn',
      signOut: 'auth/signOut',
      setRedirect: 'auth/setRedirect',
      setFirstTime: 'auth/setFirstTime'
    }),

    async continueLogin() {
      const user = this.userSaved
      const created = this.created
      console.log('continue', user, created)
      this.$store.commit('auth/signIn', user)
      await this.$store.dispatch('auth/fetchConfigs')

      if (
        this.redirectUrl !== null &&
        !!this.redirectUrl &&
        !this.redirectUrl.includes('auth')
      ) {
        this.$router.push(this.redirectUrl)
        this.setRedirect(null)
      } else {
        this.$router.replace(DEFAULT_URL)
      }

      if (created) {
        this.checkRef()
      }
    },
    analyticsConversion() {
      this.$gtag.event({
        event_category: 'signup',
        event_action: 'signup',
        event_label: 'signup'
      })

      this.$gtag('event', 'conversion', {
        send_to: 'AW-659130365/gC3zCL3b3_sCEP2PproC'
      })
    },
    fbConversion() {
      // eslint-disable-next-line no-unused-expressions
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      )
      // eslint-disable-next-line no-undef
      fbq('init', '660967574633989')
      // eslint-disable-next-line no-undef
      fbq('track', 'CompleteRegistration', {
        value: 0.0,
        currency: 'BRL'
      })
    },
    checkRef() {
      const ref = this.$cookie.get('ref')

      if (ref) {
        try {
          console.log('setting REF')
          this.$axios.post('/users/ref', {
            ref
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.callback
  padding-top: 100px
  min-height: calc(60vh - 100px)

.version-selector
  margin: 0 8px
  h1
    text-align: center
  & > div
    background: white
    border: thin solid #9e9e9e
    margin-top: 10px
    padding: 10px 20px
    border-radius: 14px
    cursor: pointer
    transition: all 150ms ease-in-out
    &:hover
      background: #f5f5f5
      border-color: #9e9e9e
</style>
