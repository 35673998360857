<template>
  <div>
    <AppNav
      image="/images/dashboard/icons/content_on.svg"
      title="Painel do Afiliado"
      subtitle=""
    >
      <template #side>
        <div class="d-flex">
          <VBtn color="new" depressed dark @click="open = true">
            <b>Meus dados</b>
          </VBtn>
        </div>
      </template>
    </AppNav>

    <div v-if="loading">
      <AppLoading />
    </div>
    <div v-else class="affiliatetabs">
      <VTabs v-model="tab" rounded>
        <VTab>
          <b>Vendas</b>
        </VTab>
        <VTab>
          <b>Recebimentos</b>
        </VTab>
      </VTabs>

      <VTabsItems v-model="tab">
        <VTabItem :key="0" :value="0">
          <VSimpleTable>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Data</th>
                  <th class="text-left">Valor da venda</th>
                  <th class="text-left">Comissão</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in payments" :key="p.id">
                  <td>{{ formatDate(p.createdAt) }}</td>
                  <td>{{ FormatMoney(p.amount / 100) }}</td>
                  <td>
                    {{ FormatMoney(getComission(p.amount)) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </VSimpleTable>
          <div v-if="payments.length === 0" class="text-center py-4">
            Nenhuma venda até o momento
          </div>
        </VTabItem>

        <VTabItem>
          <VSimpleTable>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Mês referência</th>
                  <th class="text-left">Valor</th>
                  <th class="text-left">Situação</th>
                </tr>
              </thead>
              <tbody></tbody>
            </template>
          </VSimpleTable>
          <div v-if="payouts.length === 0" class="text-center py-4">
            Nenhum recebimento até o momento
          </div>
        </VTabItem>
      </VTabsItems>
    </div>

    <VDialog v-model="open" max-width="500">
      <div class="d-flex flex-column bg-white">
        <VSimpleTable>
          <template #default>
            <tbody>
              <tr>
                <td>Cupom</td>
                <td>
                  <b>{{ affiliate.coupon }}</b>
                </td>
              </tr>
              <tr>
                <td>Desconto Cupom</td>
                <td>
                  <b>{{ affiliate.percentage }}%</b>
                </td>
              </tr>
              <tr>
                <td>Comissão por venda</td>
                <td>
                  <b>{{ affiliate.comission }}%</b>
                </td>
              </tr>
              <tr>
                <td>PIX Recebimento comissões</td>
                <td>
                  <b>{{ affiliate.pix }}</b>
                </td>
              </tr>
            </tbody>
          </template>
        </VSimpleTable>
        <a
          class="mx-auto my-4 font-weight-bold"
          :href="helpUrl"
          target="_blank"
        >
          Solicitar mudança de dados
        </a>
      </div>
    </VDialog>
    <AffiliateTerms />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import AppLoading from '~/components/app/AppLoading.vue'
import AppNav from '~/components/app/AppNav.vue'
import { FormatMoney } from '~/utils/money'
import AffiliateTerms from '~/components/AffiliateTerms.vue'
const HELP_URL = `https://api.whatsapp.com/send/?phone=+5521982509256&text=Gostaria de trocar meus dados de afiliado&app_absent=0`

export default {
  components: {
    AppNav,
    AppLoading,
    AffiliateTerms,
  },
  layout: 'app',

  data() {
    return {
      helpUrl: HELP_URL,
      open: false,
      tab: 0,
      payouts: [],
      payments: [],
      loading: true,
      FormatMoney,
    }
  },

  computed: {
    affiliate() {
      return this.$store.getters['auth/affiliate']
    },
  },

  mounted() {
    if (!this.affiliate) {
      this.$router.push('/')
    } else {
      this.loadData()
    }
  },

  methods: {
    getComission(amount) {
      return (amount * (this.affiliate.comission / 100)) / 100
    },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY')
    },
    async loadData() {
      this.loading = true

      try {
        const { data } = await this.$axios.get('/users/affiliate')
        this.payouts = data.payouts
        this.payments = data.payments
      } catch (e) {
        this.$toast.error(
          'Não foi possível carregar os dados. Atualize a página.',
        )
      }

      this.loading = false
    },
  },
}
</script>

<style lang="sass" scoped>
.affiliatetabs
  border: 1px solid rgba(51, 51, 51, 0.7)
  border-radius: 12px
  overflow: hidden
</style>
