<template>
  <div>
    <AppLoading v-if="loading" />
    <VFadeTransition>
      <div v-if="!loading" class="d-flex justify-center items-center mt-5 pt-5">
        <VCard v-if="!hasStudent" max-width="400" class="w-full">
          <VCardText>
            <h3 class="text-center mb-4">Complete o seu cadastro</h3>

            <VTextField
              v-model="invite.email"
              disabled
              outlined
              label="Email"
            ></VTextField>
            <VTextField
              v-model="data.fullname"
              autocomplete="name"
              outlined
              label="Nome completo"
            ></VTextField>
            <VTextField
              v-model="data.password"
              type="password"
              outlined
              label="Senha de acesso"
            ></VTextField>
            <VBtn
              color="new"
              block
              depressed
              rounded
              dark
              @click="acceptInvite"
            >
              <b>Terminar cadastro</b>
            </VBtn>
          </VCardText>
        </VCard>
      </div>
    </VFadeTransition>
  </div>
</template>

<script>
import AppLoading from '~/components/app/AppLoading.vue'

export default {
  components: {
    AppLoading,
  },

  data() {
    return {
      invite: null,
      loading: true,
      data: {
        fullname: '',
        password: '',
      },
    }
  },

  computed: {
    hasStudent() {
      return this.invite?.hasStudent
    },
  },

  mounted() {
    this.loadInvite()
  },

  methods: {
    async acceptInvite() {
      if (!this.hasStudent) {
        if (!this.data.fullname) {
          return this.$toast.error('Informe o seu nome completo')
        }

        if (!this.data.password || this.data.password.length < 6) {
          return this.$toast.error(
            'Informe uma senha com no mínimo 6 caracteres',
          )
        }
      }

      try {
        const { data } = await this.$axios.post(
          `/organization/invite/${this.$route.params.id}/accept`,
          this.data,
        )

        if (data.token) {
          this.$toast.success('Convite aceito com sucesso!')
          this.$router.push(`/auth/callback?token=${data.token}`)
        }
      } catch (e) {
        this.$toast.error('Erro ao aceitar o convite')
      }
    },
    async loadInvite() {
      try {
        const { data } = await this.$axios.get(
          `/organization/invite/${this.$route.params.id}`,
        )
        this.invite = data

        console.log(this.invite)

        if (this.invite.status === 'ACCEPTED') {
          return this.$router.push('/')
        }

        if (this.invite.hasStudent) {
          this.acceptInvite()
        } else {
          this.loading = false
        }
      } catch (e) {
        this.$toast.error('Erro ao aceitar o convite')
      }
    },
  },
}
</script>
