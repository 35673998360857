<template>
  <div>
    <div v-if="errors.length > 0" class="mt-4 mb-5 pb-5">
      <v-expansion-panels dense accordion>
        <v-expansion-panel v-for="(item, i) in errors" :key="i">
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <img class="warning-icon" src="/images/icons/warning.png" />
              <h4>
                {{ item.title }}
              </h4>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.message"></div>
            <div v-if="item.learnUrl" class="mt-2">
              <VBtn
                color="new"
                dark
                rounded
                small
                @click="openContent(item.learnUrl)"
              >
                <img class="warning-icon" src="/images/icons/article.svg" />
                <b>Aprenda mais</b>
              </VBtn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    openContent(contentId) {
      this.$root.$emit('openContent', {
        slug: contentId,
        hideTheme: true,
      })
    },
  },
}
</script>

<style lang="sass">
.warning-icon
  width: 20px
  height: 20px
  margin-right: 10px
</style>
