<template>
  <div class="AppNav-desktop">
    <div class="container naVContainer d-flex align-center">
      <ul class="AppNav__menu">
        <li
          v-for="(m, i) in menu"
          :key="i"
          v-ripple="{ class: `bg--text` }"
          :class="{
            'AppNav__btn--inactive': active != i,
            'AppNav--mobileoff': m.mobile_off,
          }"
        >
          <NuxtLink class="AppNav__btn d-none d-md-flex" v-if="m.to" :to="m.to">
            <div class="AppNav__icon">
              <img :src="`/images/dashboard/icons/${m.icon}.svg`" />
              <img
                :class="{ 'AppNav__icon__on--active': active == i }"
                class="AppNav__icon__on"
                :src="`/images/dashboard/icons/${m.icon}_on.svg`"
              />
            </div>
            <span>
              {{ m.title }}
            </span>
          </NuxtLink>
          <a
            v-else
            :href="m.href"
            target="_blank"
            class="AppNav__btn d-none d-md-flex"
          >
            <div class="AppNav__icon">
              <img :src="`/images/dashboard/icons/${m.icon}.svg`" />
              <img
                :class="{ 'AppNav__icon__on--active': active == i }"
                class="AppNav__icon__on"
                :src="`/images/dashboard/icons/${m.icon}_on.svg`"
              />
            </div>
            <span>
              {{ m.title }}
            </span>
          </a>
        </li>
      </ul>

      <div
        v-mouseover-outside="() => (menuOn = false)"
        class="AppNav__items d-flex align-center"
      >
        <ul class="hidden-sm-and-down AppNav__menu">
          <li
            class="AppNav__btn--more AppNav__btn--inactive AppNav__btn"
            @mouseover="openMenu('more')"
          >
            <div class="AppNav__icon">
              <VIcon size="28" color="#989898">
                {{ $icons.menuHorizontal }}
              </VIcon>
            </div>
            <span class=""> Mais </span>
          </li>
        </ul>
        <div
          v-if="false"
          class="AppNav__item d-flex align-center"
          @mouseover="openMenu('stars')"
        >
          <img src="/images/icons/star.png" />
          <span>
            {{ maxUsage - totalInMonth }}
          </span>
        </div>
        <div
          class="AppNav__item--autoleft AppNav__item d-flex align-center"
          @mouseover="openMenu('flash')"
        >
          <img src="/images/icons/flash.png" />
          <span>
            {{ totalFinished }}
          </span>
        </div>
        <div
          class="AppNav__item d-flex align-center"
          @mouseover="openMenu('coins')"
        >
          <img src="/images/icons/pencil_credit.png" />
          <span>
            {{ totalInMonth }}
          </span>
        </div>

        <a class="chat-menu" :href="helpUrl" target="_blank">
          <VBtn
            outlined
            :color="hasMessages ? 'red lighten-1' : 'primary'"
            rounded
            :class="{ pulsate: hasMessages }"
            class="mr-4"
            icon
            @click="openSupport"
          >
            <VIcon size="20">
              {{ $icons.help }}
            </VIcon>
            <div class="has-messages" />
          </VBtn>
        </a>

        <img
          class="profile-img"
          src="/images/icons/user.svg"
          @mouseover="openMenu('profile')"
        />

        <v-fade-transition>
          <div
            v-if="menuOn"
            class="AppNav-menu"
            :class="`AppNav-menu--${menuSection}`"
          >
            <v-slide-x-transition leave-absolute hide-on-leave group>
              <div
                v-if="menuSection === 'stars'"
                key="stars"
                class="menu-content coin-items d-flex"
              >
                <img src="/images/icons/star.png" />
                <div>
                  <h3>Estrelas</h3>
                  <p class="mb-0">
                    Avance nas aulas e envie redações para ganhar estrelas.
                  </p>
                </div>
              </div>

              <div
                v-if="menuSection === 'flash'"
                key="flash"
                class="menu-content coin-items"
              >
                <div class="d-flex">
                  <img class="coin-img" src="/images/icons/flash.png" />
                  <div>
                    <h3>Redações escritas</h3>
                    <p v-if="totalFinished > 0" class="mb-0">
                      Você escreveu
                      <b>{{ totalFinished }}</b>
                      <span v-if="totalFinished > 1">redações</span
                      ><span v-if="totalFinished === 1">redação</span>. Continue
                      assim!
                    </p>
                    <p v-else class="mb-0">
                      Você ainda não escreveu nenhuma redação. Comece agora!
                    </p>
                  </div>
                </div>
                <VDivider class="my-4" />
                <NuxtLink to="/temas" class="d-flex justify-center w-full">
                  <VBtn
                    block
                    color="white"
                    class="mx-auto new"
                    rounded
                    outlined
                  >
                    Enviar redação
                  </VBtn>
                </NuxtLink>
              </div>

              <div
                v-if="menuSection === 'coins'"
                key="coins"
                class="menu-content coin-items d-flex flex-column"
              >
                <div class="d-flex">
                  <img class="coin-img" src="/images/icons/pencil_credit.png" />
                  <div class="w-full">
                    <h3>Correções</h3>
                    <div class="d-flex mt-2 mb-1 align-center">
                      <span class="caption">Uso no mês</span>
                      <b class="ml-auto"
                        >{{ totalInMonth }} de {{ maxUsage }}
                      </b>
                    </div>
                    <VProgressLinear
                      :value="usagePercentage"
                      rounded
                      :color="isSubscribed ? 'success' : 'red'"
                      height="10"
                    ></VProgressLinear>

                    <p v-if="!isSubscribed" class="mt-2 mb-0 caption">
                      Seus créditos serão reiniciados no dia
                      <b>{{ $store.getters['auth/resetDate'] }}</b
                      >.
                    </p>

                    <p v-else class="mt-2 mb-0 caption">
                      Você é um usuário Premium e tem correções ilimitadas!
                    </p>
                  </div>
                </div>
                <VDivider class="my-4" />
                <NuxtLink
                  v-if="isSubscribed"
                  to="/temas"
                  class="d-flex justify-center"
                >
                  <VBtn block color="white" class="ma-0 new" rounded outlined>
                    <b>Enviar redação</b>
                  </VBtn>
                </NuxtLink>
                <div v-if="!isSubscribed">
                  <PencilWall />
                </div>
              </div>

              <div
                v-if="menuSection === 'profile'"
                key="profile"
                class="menu-content d-flex flex-column"
              >
                <div class="profile-items d-flex flex-column">
                  <div>Olá, {{ $store.getters['auth/userTitle'] }}</div>
                  <NuxtLink to="/conta"> Gerenciar Conta </NuxtLink>
                  <NuxtLink v-if="!isSubscribed" to="/assinar">
                    Seja Premium ⭐
                  </NuxtLink>
                  <NuxtLink v-if="affiliate" to="/afiliado">
                    Painel de Afiliado
                  </NuxtLink>
                  <a
                    v-if="$store.getters['auth/v1User']"
                    target="_blank"
                    :href="`https://app.coredacao.com/auth/callback?token=${$store.getters['auth/v1User'].token}`"
                  >
                    Acessar versão 1.0
                  </a>
                  <a
                    v-if="$store.getters['system/canBeInstalled']"
                    href="#"
                    @click.stop="$store.commit('system/openInstallBanner')"
                  >
                    Instalar o App 📱
                  </a>
                  <NuxtLink to="/sair"> Sair </NuxtLink>
                </div>
              </div>

              <div
                v-if="menuSection === 'more'"
                key="more"
                class="d-flex flex-column"
              >
                <div class="more-items profile-items d-flex flex-column">
                  <NuxtLink
                    v-ripple="{ class: `new--text` }"
                    class="d-flex items-center"
                    to="/aprenda/apostila"
                  >
                    <img
                      class="moremenu-icon"
                      src="/images/dashboard/icons/theme_on.svg"
                    />
                    Apostila Redação ENEM
                  </NuxtLink>
                  <NuxtLink
                    class="d-flex items-center"
                    to="/conteudo/artigos/modelos-de-redacao/"
                  >
                    <img
                      class="moremenu-icon"
                      src="/images/icons/essay_models.png"
                    />
                    Modelos de Redação
                  </NuxtLink>
                  <NuxtLink
                    class="d-flex items-center"
                    to="/conteudo/artigos/repertorio-sociocultural/"
                  >
                    <img class="moremenu-icon" src="/images/icons/book.png" />
                    Repertórios Socioculturais
                  </NuxtLink>
                </div>
              </div>
            </v-slide-x-transition>
          </div>
        </v-fade-transition>
      </div>
    </div>

    <v-fade-transition>
      <div v-if="menuOn" class="AppNaVOverlay"></div>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PencilWall from '../paywalls/PencilWall.vue'
const HELP_URL = `https://api.whatsapp.com/send/?phone=+5521982509256&text=Preciso de ajuda no coRedação&app_absent=0`

export default {
  components: { PencilWall },
  data() {
    return {
      menuLeft: false,
      menuOn: false,
      menuSection: '',
      hasMessages: false,
      helpUrl: HELP_URL,
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isSubscribed: 'auth/isSubscribed',
      hasEssaysEnabled: 'auth/hasEssaysEnabled',
    }),

    affiliate() {
      return this.$store.getters['auth/affiliate']
    },

    totalFinished() {
      return this.$store.getters['essays/totalFinished']
    },

    totalInMonth() {
      return this.currentUser?.correctionsInMonth || 0
    },

    limits() {
      return this.$store.getters['auth/limits']
    },

    maxUsage() {
      return this.isSubscribed ? '∞' : this.limits.basic
    },

    usagePercentage() {
      return Math.round((this.totalInMonth / this.maxUsage) * 100)
    },

    menu() {
      const base = [
        {
          title: 'Redações',
          to: '/',
          icon: 'essays',
          icon_on: 'essays_on',
        },
        {
          title: 'Curso',
          to: '/aprenda/curso-enem',
          icon: 'panel',
          icon_on: 'panel_on',
        },
        {
          title: 'Questões',
          href: 'https://respostacerta.com?utm_source=cored_app',
          icon: 'resposta-certa',
          icon_on: 'panel_on',
        },
      ]

      if (this.affiliate) {
        base.push({
          title: 'Afiliado',
          to: '/afiliado',
          icon: 'content',
          icon_on: 'content_on',
        })
      }

      return base
    },

    active() {
      const p = this.$route.path

      for (let i = 0; i < this.menu.length; i++) {
        const m = this.menu[i]

        if ((p.includes(m.to) && m.to !== '/') || p === m.to) return i
      }

      return -1
    },
  },

  watch: {
    $route() {
      this.menuOn = false
    },
  },
  destroyed() {
    this.$root.$off('newMessage')
  },

  mounted() {
    this.$root.$on('newMessage', () => {
      this.hasMessages = true
    })
  },
  methods: {
    goToItem(menuItem) {
      if (menuItem.to) {
        this.$router.push(menuItem.to)
      } else {
        window.open(menuItem.href, '_blank')
      }
    },
    openSupport() {
      this.$root.$emit('openSupport')
      this.hasMessages = false
    },
    openMenu(menuSection) {
      this.menuOn = true
      this.menuLeft = menuSection === 'more'

      this.menuSection = menuSection
    },
  },
}
</script>

<style lang="sass">
.AppNav__item--autoleft
  margin-left: auto
.moremenu-icon
  width: 22px
  height: 22px
  margin-right: 12px
.AppNav-desktop
  background-color: rgba(255,255,255,0.9)
  backdrop-filter: blur(10px)
  border-bottom: thin solid #9e9e9e !important
  display: flex
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 10001
  height: 70px

  .AppNav__items
    position: relative
    height: calc(100% + 24px)
    margin: -12px 0
    width: 100%
    .profile-img
      opacity: 0.5
    img
      max-height: 36px

    .AppNav__item
      width: 80px

      img
        max-height: 24px

      span
        font-size: 16px
        font-weight: bold
        margin-left: 8px
        cursor: default
        height: 20px
    .AppNav-menu
      background-color: white
      width: 380px
      border: thin solid #9e9e9e
      position: absolute
      z-index: 100
      top: 64px
      right: 0
      border-radius: 20px
      transition: all 150ms ease-in-out
      overflow: hidden

      &::after, &::before
        bottom: 100%
        border: solid transparent
        content: ""
        height: 0
        width: 0
        position: absolute
        pointer-events: none
        transition: all 150ms ease-in-out

      &::after
        border-color: rgba(255, 255, 255, 0)
        border-bottom-color: #fff
        border-width: 6px
        margin-left: -6px

      &::before
        border-color: rgba(158, 158, 158, 0)
        border-bottom-color: #9e9e9e
        border-width: 7px
        margin-left: -7px
    .AppNav-menu--more
      right: auto
      left: 0
  @media screen and(max-width: 900px)
    .AppNav__item--autoleft
      margin-left: 0
    .AppNav__items
      width: 100%
      .chat-menu
        margin-left: auto
    .AppNav-menu
      width: calc(100vw - 24px) !important
      right: 0 !important
      &::after, &::before
        width: 100%

  .nav-logo
    height: 30px

  .naVContainer
    max-width: 1160px
    margin: 0 auto
    width: 100%
    height: 100%

    .AppNav__menu
      list-style: none
      padding: 0
      position: relative
      z-index: 2
      display: flex
      align-items: center
      height: 100%
      grid-gap: 42px

      @media screen and (max-width: 960px)
        display: none
      .AppNav__btn
        padding: 12px 0
        height: 100%
        text-transform: uppercase
        letter-spacing: 0.4px
        font-size: 14px
        transition: all 300ms ease
        display: flex
        font-weight: bold
        align-items: center
        .AppNav__icon
          position: relative
          display: flex
          align-items: center
          .AppNav__icon__on
            position: absolute
            top: 0
            opacity: 0
            transform: all 150ms ease
          .AppNav__icon__on--active
            opacity: 1
        span
          color: #FF6338
          opacity: 0.9
          display: inline-flex
          align-items: center
          cursor: default
        img
          width: 28px
          height: 28px
        .AppNav__icon
          margin-right: 12px
      .AppNav__btn--more
        margin-left: 42px
      .AppNav__btn--inactive
        cursor: pointer
        border: 0
        background-color: transparent
        span
          color: #555
          cursor: pointer
          transition: all 150ms ease-in-out
        img
          opacity: 0.5
          transition: all 150ms ease-in-out
        &:hover
          img
            opacity: 0.8
          span
            color: #333

.AppNaVOverlay
  position: fixed
  background-color: rgba(255,255,255,0.9)
  height: calc(100vh - 70px)
  width: 100vw
  z-index: 50
  left: 0
  top: 70px

.profile-items
  a
    border-top: thin solid #9e9e9e
    color: #FF6338
    font-size: 15px

    &:hover
      background-color: #FFF9F5

  & > a, & > div
    font-weight: 600
    padding: 12px 24px
.more-items
  a:first-child
    border-top: 0
.coin-items
  padding: 24px
  .coin-img
    height: 50px
    max-height: 50px !important
    margin-right: 24px
  p
    font-size: 14px
.menu-content-profile
  max-width: 300px

.app-logo
  height: 40px
.no-border
  border: 0 !important
</style>
