export default {
  setExam(state, exam) {
    state.exam = exam
  },

  setLastEnemGrade(state, lastEnemGrade) {
    state.lastEnemGrade = lastEnemGrade
  },

  setConcursoName(state, concursoName) {
    state.concursoName = concursoName
  },

  setVestibularName(state, vestibularName) {
    state.vestibularName = vestibularName
  },

  setGoal(state, goal) {
    state.goal = goal
  },

  setSource(state, source) {
    state.source = source
  },

  setShowFirstSteps(state, showFirstSteps) {
    state.showFirstSteps = showFirstSteps
  }
}
