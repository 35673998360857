<template>
  <div>
    <div class="result-grade__share">
      <VBtn rounded small class="black--text" color="primary" @click="open">
        Compartilhar <VIcon size="12" class="ml-1">{{ $icons.share }}</VIcon>
      </VBtn>
    </div>
    <modal v-model="isOpen" title="Compartilhar redação">
      <VAlert class="text-center" color="info" outlined>
        Compartilhe a redação e correção com seus amigos!
      </VAlert>
      <VTextField
        ref="input"
        :value="link"
        outlined
        dense
        readonly
        label="Link da correção"
      >
        <template #append-outer>
          <VBtn
            rounded
            class="share-btn mt-0 pt-0"
            color="new"
            dark
            depressed
            @click="copy"
            >Copiar</VBtn
          >
        </template>
      </VTextField>
    </modal>
  </div>
</template>

<script>
export default {
  components: {
    Modal: () => import('@/components/helpers/Modal'),
  },
  props: {
    value: Object,
  },

  data() {
    return {
      isOpen: false,
      loading: false,
      shareStatus: false,
    }
  },

  computed: {
    link() {
      return process.env.selfUrl + 'redacao/' + this.value.id
    },

    label() {
      return this.shareStatus
        ? 'Compartilhamento ativo'
        : 'Compartilhamento desativado'
    },
  },

  watch: {
    shareStatus(v) {},
  },

  created() {
    this.shareStatus = !!this.value.shareable
  },

  methods: {
    copy() {
      this.$nextTick(() => {
        const input = this.$refs.input.$el.querySelector('input')

        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand('copy')

        this.$toast.success('Link de compartilhamento copiado!')
      })
    },
    open() {
      this.isOpen = true
    },
  },
}
</script>

<style lang="sass" scoped>
.result-grade__share
  .VBtn
    height: 22px !important
    .v-btn__content
      font-size: 10px !important
.share-btn
  margin-top: -6px !important

@media screen and (max-width: 960px)
  .result-grade__share
    top: 38px
    width: 100%
    display: flex
    justify-content: center
</style>
