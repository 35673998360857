<template>
  <div>
    <AppNav
      class="mb-0"
      image="/images/dashboard/icons/theme_on.svg"
      title="Apostila Redação ENEM do zero"
      subtitle=""
    >
      <template #side> </template>
    </AppNav>
    <AppLoading v-if="loading" class="mt-5" />

    <div v-else class="apostila-container">
      <LessonModule v-for="mod in modules" :key="mod.id" :value="mod" blog />
    </div>
  </div>
</template>

<script>
import ContentAPI from '@/plugins/ghost'
import AppLoading from '@/components/app/AppLoading'
import AppNav from '@/components/app/AppNav'
import LessonModule from '~/components/lesson/LessonModule.vue'

export default {
  layout: 'app',

  components: {
    AppNav,
    AppLoading,
    LessonModule,
  },

  data() {
    return {
      content: [],
      loading: true,
      modules: [],
    }
  },

  computed: {},

  created() {
    this.loadData()
  },

  methods: {
    postToLesson(content, moduleId) {
      return {
        id: content.id,
        title: content.title,
        html: content.html,
        excerpt: content.custom_excerpt,
        icon: 'https://coredassets.s3.amazonaws.com/icones/m1a3.svg',
        blog: true,
        moduleId,
      }
    },

    async loadData() {
      this.loading = true
      try {
        let content = await ContentAPI.posts.browse({
          limit: 'all',
          include: ['tags'],
          filter: 'tag:apostila2',
          fields: [
            'id',
            'slug',
            'title',
            'feature_image',
            'custom_excerpt',
            'html',
            'text',
          ],
        })

        content = content.map((c) => {
          return {
            ...c,
            position: parseInt(c.slug.match(/\d+/)[0]),
          }
        })

        content.sort((a, b) => {
          // Extract numbers from slug
          return a.position - b.position
        })

        this.content = content

        this.modules = [
          {
            id: 1,
            title: 'Esqueleto da redação ENEM',
            description:
              'Travar na hora de começar a redação é muito comum e talvez esteja acontecendo com você. Por isso nesse módulo você vai conhecer o tipo de texto pedido no ENEM e saber como interpretar corretamente qualquer tema de redação. ',
            lessons: [
              this.postToLesson(content[0], 1),
              this.postToLesson(content[1], 1),
            ],
          },
          {
            id: 2,
            title: 'Construindo a redação ENEM do zero',
            description:
              'Chegou a hora de colocar a mão na massa! Nesse módulo você entenderá com detalhes qual estrutura de redação seguir e como escrever os parágrafos de introdução, desenvolvimento e conclusão.',
            lessons: [
              this.postToLesson(content[2], 2),
              this.postToLesson(content[3], 2),
              this.postToLesson(content[4], 2),
              this.postToLesson(content[5], 2),
            ],
          },
          {
            id: 3,
            title: 'Como a redação do ENEM é corrigida',
            description:
              'Saber como escrever é metade do processo. Pra aumentar sua nota e ir pro próximo nível é necessário conhecer os critérios usados pra corrigir sua redação e é exatamente isso que você encontrará nesse módulo.',
            lessons: [
              this.postToLesson(content[6], 3),
              this.postToLesson(content[7], 3),
              this.postToLesson(content[8], 3),
              this.postToLesson(content[9], 3),
              this.postToLesson(content[10], 3),
              this.postToLesson(content[11], 3),
            ],
          },
        ]

        this.$store.commit('course/setModules', this.modules)

        console.log(this.content)
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
  },

  head() {
    return {
      title: 'Apostila',
    }
  },
}
</script>

<style lang="sass" scoped></style>
