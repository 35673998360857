<template>
  <VDialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="800"
    min-height="90vh"
  >
    <div
      :class="{
        'content-modal--ios': $store.getters['auth/device'].platform === 'ios',
      }"
      class="content-modal white"
    >
      <div class="content-modal__close" @click="dialog = false">
        <VBtn outlined small class="bg-white" icon>
          <VIcon size="20">{{ $icons.close }}</VIcon>
        </VBtn>
      </div>

      <div v-if="hasBack" class="content-modal__back" @click="back">
        <VBtn outlined small class="bg-white" icon>
          <VIcon size="20">{{ $icons.arrowLeft }}</VIcon>
        </VBtn>
      </div>

      <div
        :options="{ suppressScrollX: true }"
        class="content-modal__internal pb-5"
      >
        <VImg
          v-if="!notFound"
          class="content-modal__img grey"
          :src="featureImage"
          max-height="300"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-skeleton-loader
                class="card__skeleton mx-auto"
                width="100%"
                min-height="100%"
                type="image"
              ></v-skeleton-loader>
            </v-row>
          </template>
        </VImg>
        <div class="content-modal__body">
          <span v-if="theme" class="caption">Tema de redação</span>
          <span v-else class="caption">Conteúdo</span>
          <h1 class="content-modal__title" v-text="content.title"></h1>

          <VDivider v-if="!loading" class="mb-5" />

          <div v-if="!loading">
            <div v-if="!notFound">
              <PostBody v-if="currentTab === 'text'" :value="content" />
              <div v-if="currentTab !== 'text'" class="relative">
                <PostBody
                  v-if="currentTab === 'repertoires'"
                  :value="{ html: repertoires }"
                />
                <PostBody
                  v-if="currentTab === 'arguments'"
                  :value="{ html: htmlArguments }"
                />
                <PostBody
                  v-if="currentTab === 'interventions'"
                  :value="{ html: interventions }"
                />

                <ContentBlocked v-if="!isSubscribed" />
              </div>
            </div>
            <div v-else>
              <VAlert color="info" outlined class="white--text">
                Infelizmente no momento este conteúdo não esta mais disponível.
                Sentimos muito pelo inconveniente
              </VAlert>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="d-flex justify-center">
        <AppLoading />
      </div>
      <div v-if="!notFound" class="content-modal__cta">
        <VBtn
          v-if="theme && !hideThemeChooser"
          outlined
          :small="$vuetify.breakpoint.smAndDown"
          rounded
          class="new px-4"
          color="white"
          @click="goToTheme"
        >
          <img class="icon-img mr-2" src="/images/icons/write.svg" />
          <span>Escolher tema</span>
        </VBtn>
      </div>
    </div>
  </VDialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AppLoading from '../../components/app/AppLoading'
import PostBody from './ContentPost/ContentPostBodyContent'
import ContentBlocked from './ContentBlocked.vue'
import ContentAPI from '@/plugins/ghost'

export default {
  components: {
    AppLoading,
    PostBody,
    ContentBlocked,
  },
  data() {
    return {
      currentTab: 'text',
      dialog: false,
      loading: true,
      theme: false,
      notFound: false,
      currentSlug: '',
      tab: 0,
      content: {},
      hasBack: -1,
      essays: [],
      hasArguments: false,
      hasInterventions: false,
      hasRepertoires: false,
      interventions: '',
      repertoires: '',
      htmlArguments: '',
      hideThemeChooser: false,
    }
  },

  watch: {
    dialog(v) {
      if (!v) {
        this.close()
      }
    },
    $route(r, oldR) {
      this.handleRoute(r)
    },
    loading(v) {
      if (!v) {
        this.handleStickyBar()
      }
    },
    currentTab(text) {},
  },
  mounted() {
    this.$root.$on('openContent', (content) => {
      this.content = content
      this.hasBack += 1
      this.hideThemeChooser = content.hideThemeChooser

      console.log('open content')

      if (!content.slug) {
        this.dialog = true
        this.loading = false
        this.notFound = true
        this.theme = !!content.hideTheme
        this.content = {
          title: content.hideTheme
            ? 'Tema não disponível'
            : 'Conteúdo não disponível',
        }

        return
      }

      if (this.content.hideTheme) {
        this.load(this.content.slug)
      } else {
        this.openContent(content.slug)
      }
    })

    this.handleRoute(this.$route)
  },

  destroyed() {
    this.$root.$off('openContent')
  },

  computed: {
    ...mapGetters({
      hasEssaysEnabled: 'auth/hasEssaysEnabled',
      isSubscribed: 'auth/isSubscribed',
      isLoggedIn: 'auth/isLoggedIn',
    }),
    featureImage() {
      if (!this.content.feature_image) return ''

      return (
        this.content.feature_image.replace(
          'conteudo.coredacao.com',
          'coredacao.imgix.net',
        ) + '?w=800&h=300&fit=crop&crop=entropy&auto=format,enhance&q=75'
      )
    },
  },

  methods: {
    handleStickyBar() {
      this.$nextTick(() => {
        const el = this.$refs.tabs
        console.log('sticky', el)
        if (!el) return

        console.log('passed sticky')

        const observer = new IntersectionObserver(
          ([e]) => {
            e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
          },
          { threshold: [1] },
        )

        observer.observe(el)
      })
    },
    openContent(slug) {
      this.$router.push({
        path: this.$route.fullPath,
        query: { ...this.$route.query, content: slug },
      })
    },
    back() {
      this.hasBack -= 1
      this.$router.go(-1)
    },
    handleRoute(r) {
      const contentSlug = r.query.content

      if (contentSlug && this.currentSlug !== contentSlug) {
        this.load(contentSlug)
      } else {
        this.dialog = false
      }
    },
    close() {
      console.log('close', this.content)
      if (!this.content.hideTheme) {
        this.$router.push({ path: this.$route.path })
      }

      this.content = {}
      this.theme = false
      this.hasBack = -1
      this.tab = 0

      this.currentSlug = ''
    },
    async load(contentSlug) {
      this.currentSlug = contentSlug
      this.loading = true
      this.dialog = true
      this.notFound = false

      const c = await ContentAPI.posts.read(
        { slug: contentSlug },
        {
          formats: ['html', 'plaintext'],
          include: 'tags,authors',
        },
      )

      if (c !== null) {
        this.content = {
          ...this.content,
          ...c,
        }

        this.currentTab = 'text'

        this.theme =
          !!c.tags.find((t) => t.slug === 'temas-de-redacao') ||
          this.content.slug === 'tema-livre'

        this.loading = false
      }
    },

    goToTheme() {
      this.$router.push(`/temas?theme=${this.content.slug}`)
      this.dialog = false
    },
  },
}
</script>

<style lang="sass">
.content-modal
  position: relative
  height: 90vh
  border-radius: 20px
  border-top-left-radius: 5px
  border-top-right-radius: 5px
  .content-tabs
    position: sticky
    top: -1px
    background-color: white
    z-index: 20
  .content-tabs.is-pinned
    padding-top: 10px
    padding-bottom: 4px
    border-bottom-right-radius: 10px
    border-bottom-left-radius: 10px
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    padding: 0 -80px
  .content-modal__img
    border-top-left-radius: 5px
    border-top-right-radius: 5px
  .content-modal__close
    position: absolute
    right: 2%
    top: 12px
    z-index: 10
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    border-radius: 100%
  .content-modal__back
    position: absolute
    left: 2%
    top: 12px
    z-index: 10
  .content-modal__internal
    max-height: 90vh
    overflow-y: auto
    .content-modal__body
      padding: 20px 80px
      padding-bottom: 40px
  .content-modal__cta
    position: absolute
    bottom: 28px
    left: 50%
    transform: translateX(-50%)
  .icon-img
    height: 18px
@media screen and (max-width: 900px)
  .content-modal
    border-radius: 0
    min-height: 100vh
    min-height: -webkit-fill-available
    -webkit-overflow-scrolling: touch
    .content-tabs
      margin: 0 -20px
    .content-tabs.is-pinned
      padding-top: 28px
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
    .content-modal__img
      border-radius: 0
    .content-modal__internal
      max-height: 100%
      .content-modal__body
        padding: 10px 20px
        .content-modal__title
          font-size: 22px
.content-modal--ios
  background-color: green
  .content-modal__close
    top: 40px !important
    right: 3%
  .content-modal__back
    top: 40px
    left: 3%

.content-tabs
  .tab-icon
    height: 18px
    margin: 0 12px
  .VTab
    text-transform: none
    padding: 0
    padding-right: 8px

    &::before
      border-radius: 20px 20px 0 0
</style>
