import { render, staticRenderFns } from "./apostila.vue?vue&type=template&id=306a7e98&scoped=true&"
import script from "./apostila.vue?vue&type=script&lang=js&"
export * from "./apostila.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306a7e98",
  null
  
)

export default component.exports