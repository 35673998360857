<template>
  <div class="mark-wall__content">
    <div class="bg-white pa-2">
      Assine o <b class="new--text">PREMIUM</b> para visualizar as marcações
      avançadas.
    </div>

    <VBtn small depressed class="new mt-4" dark @click="openCheckout">
      <b>⭐ Destravar minha redação</b>
    </VBtn>
  </div>
</template>

<script>
export default {
  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.mark-wall__content
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  position: absolute
  top: -10px
  left: 0
  background-color: rgba(255,255,255,0.4)
  backdrop-filter: blur(3px)
  z-index: 10
  padding: 20px
  height: 100%
  > div
    line-height: 28px !important
    border-radius: 12px
</style>
