<template>
  <VApp>
    <Transition name="slide-left" mode="out-in">
      <nuxt />
    </Transition>
    <MarkModal />
    <CompetenceModal />
    <ContentModal />
    <CheckoutModal />
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex'
import CheckoutModal from '~/components/checkout/CheckoutModal.vue'

export default {
  components: {
    MarkModal: () => import('@/components/essay/MarkModal'),
    CompetenceModal: () => import('@/components/essay/CompetenceModal'),
    ContentModal: () => import('@/components/content/ContentModal.vue'),
    CheckoutModal,
  },

  data() {
    return {
      left: 0,
    }
  },

  middleware: ['isMaintenance'],

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isSubscribed: 'auth/isSubscribed',
    }),
  },

  async mounted() {
    await Promise.all([
      this.loadStyles(
        'https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@900&display=swap',
      ),
      this.loadStyles(
        'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;800&display=swap',
      ),
    ])
  },

  methods: {
    loadStyles(src) {
      return new Promise((resolve) => {
        const link = document.createElement('link')
        link.type = 'text/css'
        link.rel = 'stylesheet'
        link.onload = function () {
          resolve()
        }
        link.href = src
        document.getElementsByTagName('head')[0].appendChild(link)
      })
    },
  },
}
</script>

<style lang="sass">
.promo-container
  width: 100%
  .promo
    width: 100%
    background-color: #080808
    position: fixed
    bottom: 0
    z-index: 100
    border-top-left-radius: 20px
    border-top-right-radius: 20px
    box-shadow: 0px -8px 47px 0px rgba(112,112,112,1)
    .promo-timer
      min-width: 120px
    .promo-close
      position: absolute
      top: -15px
      right: -15px
      box-shadow: 0px -8px 47px 0px rgba(112,112,112,1)
      border-radius: 100%
    p
      font-size: 14px
    h2
      font-size: 30px
      font-weight: 900
  .promo-coupon
    font-size: 32px
    font-weight: 900
    height: 100%
    border: 5px solid white
    display: flex
    align-items: center

.rounded
  border-radius: 5px
a
  text-decoration: none
@media screen and (max-width: 960px)
  .promo-container
    .promo, .promo-title
      flex-direction: column
      align-items: center
    h2,p
      text-align: center
    p
      margin: 12px 0 !important
    .promo-coupon
      margin-top: 12px
    .promo
      .promo-close
        right: 0
</style>
