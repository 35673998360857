<template>
  <div class="payment-page">
    <VCard
      v-if="payment.type === 'PIX' && payment.state !== 'PAID'"
      class="mx-auto relative mb-4 mt-4"
      max-width="100%"
      outlined
    >
      <VListItem three-line>
        <VListItemContent>
          <div class="hidden-sm-and-down font-weight-bold mb-4">
            Escaneie este código para pagar
          </div>
          <div class="hidden-md-and-up font-weight-bold mb-4">
            Copie o código abaixo para pagar
          </div>
          <div>
            <div class="mb-2">
              <b>1.</b> Acesse seu Internet Banking ou app de pagamentos.
            </div>
            <div class="mb-2"><b>2.</b> Escolha pagar via Pix.</div>
            <div class="hidden-sm-and-down mb-2">
              <b>3.</b> Escaneie o seguinte código:
            </div>
            <div class="hidden-md-and-up mb-2">
              <b>3.</b> Copie o seguinte código:
            </div>
          </div>

          <div class="hidden-sm-and-down qrcode">
            <div class="d-flex justify-center">
              <VueQrcode
                :value="payment.pixQrcode"
                tag="img"
                :options="{ maxWidth: 250 }"
              ></VueQrcode>
            </div>
          </div>
          <div class="hidden-sm-and-down text-center caption">
            🕑 Pague e será creditado na hora
          </div>
          <VDivider class="hidden-sm-and-down my-4" />
          <div class="">
            <div class="hidden-sm-and-down">
              <div class="mb-4 font-weight-bold">
                Ou copie este código QR para fazer o pagamento
              </div>
              <p>
                Escolha pagar via Pix pelo seu Internet Banking ou app de
                pagamentos. Depois, cole o seguinte código:
              </p>
            </div>
            <div class="qrcode-bar mx-auto">
              <VTextField
                ref="qrcode"
                class="qrcode-bar__input"
                readonly
                outlined
                dense
                hide-details
                :value="payment.pixQrcode"
                @click="copyQrCode"
              >
                <template #append>
                  <div>
                    <VBtn
                      outlined
                      rounded
                      depressed
                      color="primary"
                      small
                      class="mb-2"
                      @click="copyQrCode"
                      >Copiar</VBtn
                    >
                  </div>
                </template>
              </VTextField>
              <div class="hidden-md-and-up mt-2 text-center caption">
                🕑 Pague e será creditado na hora
              </div>
              <VBtn
                class="mt-2 hidden-md-and-up"
                dark
                color="new"
                rounded
                block
                @click="copyQrCode"
              >
                <b>Copiar código</b>
              </VBtn>
            </div>
            <div v-if="loading" class="qrcode-loading d-flex justify-center">
              <VProgressCircular indeterminate color="primary" size="22" />
            </div>
          </div>
        </VListItemContent>
      </VListItem>
    </VCard>

    <VCard class="mx-auto" max-width="100%" outlined>
      <VListItem three-line>
        <VListItemContent>
          <div class="overline">Resumo</div>
          <v-simple-table>
            <template v-slot:default>
              <thead></thead>
              <tbody>
                <tr>
                  <td><b>Tempo de acesso</b></td>
                  <td>{{ payment.planMonths }} meses</td>
                </tr>
                <tr>
                  <td><b>Valor Total</b></td>
                  <td>R$ {{ formatMoney(payment.amount) }}</td>
                </tr>
                <tr v-if="payment.installments > 1">
                  <td><b>Parcelas</b></td>
                  <td>{{ payment.installments }}</td>
                </tr>
                <tr v-if="payment.installments > 1">
                  <td><b>Valor da parcela</b></td>
                  <td>
                    R$ {{ formatMoney(payment.amount / payment.installments) }}
                  </td>
                </tr>
                <tr>
                  <td><b>Tipo de pagamento</b></td>
                  <td v-if="payment.type === 'CREDIT_CARD'">
                    Cartão de Crédito
                  </td>
                  <td v-if="payment.type === 'PIX'">PIX</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </VListItemContent>
      </VListItem>
    </VCard>

    <VCard class="mx-auto mt-4" max-width="100%" outlined>
      <VListItem three-line>
        <VListItemContent>
          <div class="overline">Situação</div>
          <div v-if="payment.state === 'WAITING_PAYMENT'" class="d-flex">
            <div v-if="payment.type === 'PIX'">
              <div class="title font-weight-bold mb-1">
                🕑 Aguardando pagamento
              </div>
              <p>Pague e será creditado na hora</p>
            </div>
          </div>
          <div v-if="payment.state === 'PAID'" class="d-flex">
            <div>
              <VListItem-title class="headline mb-1 green--text"
                >Pagamento confirmado!</VListItem-title
              >
              <VListItem-subtitle class="mb-4"
                >Obrigado por comprar conosco, seu acesso completo já foi
                liberado!</VListItem-subtitle
              >

              <NuxtLink to="/">
                <VBtn dark rounded depressed color="new">
                  Voltar para a página inicial
                </VBtn>
              </NuxtLink>
            </div>
          </div>
        </VListItemContent>
      </VListItem>
    </VCard>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapMutations } from 'vuex'
import dayjs from 'dayjs'

export default {
  components: {
    VueQrcode,
  },

  props: {
    payment: Object,
  },

  data() {
    return {
      loading: true,
      interval: null,
    }
  },

  created() {
    if (
      this.payment.type === 'PIX' &&
      this.payment.state === 'WAITING_PAYMENT'
    ) {
      this.interval = setInterval(this.reloadPayment, 5000)
    }

    console.log(this.payment)
  },

  destroyed() {
    if (
      this.payment.type === 'PIX' &&
      this.payment.state === 'WAITING_PAYMENT'
    ) {
      clearInterval(this.interval)
    }
  },

  methods: {
    ...mapMutations({
      addCredits: 'auth/addCredits',
      addSubscription: 'auth/addSubscription',
    }),
    async reloadPayment() {
      if (this.payment.state === 'PAID') return
      try {
        const { data } = await this.$axios.get(
          '/billing/payment/' + this.$route.params.id,
        )
        this.$emit('payment', data)

        if (data.state === 'PAID') {
          this.$store.dispatch('auth/reAuth')
          clearInterval(this.interval)
        }
      } catch (e) {
        console.log(e)
      }
    },
    copyQrCode() {
      this.$nextTick(() => {
        /* Get the text field */
        const copyText = this.$refs.qrcode.$el.querySelector('input')

        /* Select the text field */
        copyText.select()
        copyText.setSelectionRange(0, 99999) /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand('copy')

        /* Alert the copied text */
        this.$toast.success('Código copiado!')
      })
    },

    formatMoney(val) {
      const amount = val / 100
      return amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },

    formatDate(val) {
      return dayjs(val).format('DD/MM/YYYY [às] HH:mm')
    },
  },
}
</script>

<style lang="sass" scoped>
.qrcode-loading
  position: absolute
  top: 2%
  right: 2%
.qrcode-bar__input
  cursor: pointer
.qrcode
  max-width: 100%
.overline
  font-weight: bold
.payment__icon
  img
    width: 25px
    margin-right: 16px

.payment-page
  max-width: 100%
</style>
