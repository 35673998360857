<template>
  <VDialog v-model="open" persistent max-width="500">
    <div v-if="open" class="limit-blocked d-flex items-center">
      <div
        class="blocked-content d-flex flex-column align-center justify-center"
      >
        <h2 class="text-center mb-5">Serviço temporariamente indisponível</h2>

        <p class="text-start">Tente novamente mais tarde.</p>

        <VBtn class="mt-4" depressed @click="$emit('input', false)">
          Fechar
        </VBtn>
      </div>
    </div>
  </VDialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },

  data() {
    return {
      open: false,
    }
  },

  computed: {
    price() {
      return this.$store.getters['auth/priceFrom']
    },
    resetDate() {
      return this.$store.getters['auth/resetDate']
    },
  },

  watch: {
    value(val) {
      this.open = !!val
    },

    open(val) {
      this.$emit('input', val)
    },
  },

  created() {
    this.open = !!this.value
  },

  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.limit-blocked
  position: relative
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: #fff

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(255,255,255,0.8)
    filter: blur(10px)
  .blocked-content
    position: relative
    z-index: 10
    padding: 40px 20px
    max-width: 460px
    margin: 0 auto

    img
      width: 100px
    h2, p
      text-align: center
</style>
