<template>
  <div class="lesson-module">
    <LessonHead :blog="blog" :value="value" />
    <div class="lesson-divisor" />
    <div class="relative" :class="{ 'lessons-blocked': blocked }">
      <LessonItems :blocked="blocked" :value="value.lessons" />
    </div>
  </div>
</template>

<script>
import LessonHead from './LessonHead.vue'
import LessonItems from './LessonItems.vue'

export default {
  components: {
    LessonHead,
    LessonItems,
  },
  props: {
    blog: Boolean,
    value: Object,
    blocked: Boolean,
  },
}
</script>

<style lang="sass" scoped>
.lesson-module
  background: white
  border: thin solid #9e9e9e
  border-radius: 20px
  margin-bottom: 80px
  position: relative

  .lessons-blocked
    min-height: 400px

  &::before
    content: ''
    width: 1px
    height: 80px
    background: #9e9e9e
    position: absolute
    bottom: -80px
    left: 50%
  .lesson-divisor
    height: 1px
    background: #9e9e9e
    width: 100%
</style>
