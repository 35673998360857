<template>
  <div class="content">
    <AppNav
      image="/images/dashboard/icons/course_on.svg"
      title="Cursos"
      subtitle="Aprenda a escrever uma redação do zero sobre qualquer tema"
    >
      <template #side> </template>
    </AppNav>

    <div>
      <div class="learn-container">
        <div v-for="(c, i) in content" :key="i" class="learn-block">
          <VCard
            class="learn-item mx-auto"
            outlined
            @click="$router.push(c.to)"
          >
            <div class="learn-content d-flex flex-column">
              <div class="relative">
                <VImg :src="c.img" height="200px" class="learn-img"></VImg>
                <div
                  v-if="c.premium"
                  class="premium px-2 d-flex align-center ml-4 white--text red"
                >
                  <img class="learn-icon" src="/images/icons/crown.svg" />
                  <span class="ml-1">Premium</span>
                </div>
              </div>

              <VCardTitle class="font-weight-bold mb-2">
                <div>
                  <div class="subtitle-2 font-weight-bold">
                    {{ c.subtitle }}
                  </div>
                  <h3 class="learn-title new--text">{{ c.title }}</h3>
                </div>
              </VCardTitle>

              <VCardSubtitle>
                <div v-html="c.description"></div>
                <div class="learn-details__placeholder"></div>
                <div class="learn-details">
                  <VDivider v-if="c.perks" class="my-2" />
                  <div v-if="c.perks" class="perks d-flex">
                    <div
                      v-for="(p, j) in c.perks"
                      :key="j"
                      class="d-flex align-center"
                    >
                      <img class="learn-icon" :src="p.icon" />
                      <VSkeletonLoader
                        v-if="p.loading"
                        type="image"
                        class="mx-1"
                        height="20"
                        width="20"
                        min-height="20"
                      ></VSkeletonLoader>
                      <b v-if="!p.loading" class="mx-1">{{ p.value }}</b>
                      <span class="font-weight-bold" v-text="p.text"></span>
                    </div>
                  </div>
                </div>
              </VCardSubtitle>
            </div>
          </VCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNav from '@/components/app/AppNav'

export default {
  layout: 'app',

  middleware: 'requireAuth',

  components: {
    AppNav,
  },

  data() {
    return {
      content: [
        {
          title: 'Redação de Sucesso',
          subtitle: 'Curso em Vídeo',
          img: '/images/capas/curso1.png',
          to: '/aprenda/curso-enem',
          description:
            'Nesse curso você vai aprender a escrever uma redação nota 1000 do zero, com aulas que vão desde a estrutura da redação até a prática da escrita.',
          perks: [
            {
              icon: '/images/icons/chapter.svg',
              text: '19 videoaulas',
            },
            {
              icon: '/images/icons/coverage.svg',
              text: 'Básico ao avançado',
            },
          ],
        },
        {
          title: 'Redação ENEM do zero',
          subtitle: 'Apostila',
          description:
            'Nessa apostila você vai encontrar comentários detalhados sobre como a Redação do ENEM é corrigida e técnicas da prova para escrever cada parágrafo do seu texto do básico ao avançado.',
          to: '/aprenda/apostila',
          img: '/images/capas/apostila.png',
          perks: [
            {
              icon: '/images/icons/chapter.svg',
              text: '12 lições',
            },
          ],
        },
      ],
    }
  },

  mounted() {},

  methods: {},

  head: {
    title: 'Aprenda',
  },
}
</script>

<style lang="sass" scoped>
.learn-title
  word-break: normal !important
.learn-details__placeholder
  width: 100%
  height: 55px
.learn-details
  width: calc(100% - 32px)
  position: absolute
  bottom: 12px
.learn-content
  flex-grow: 1
  align-self: flex-end
  border-radius: 20px
.learn-container
  display: grid
  grid-gap: 1.4em
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
  padding-bottom: 60px
.premium
  border-radius: 12px
  font-size: 12px
  position: absolute
  top: 12px
  right: 12px
.perks
  grid-gap: 8px
.learn-block
  background-color: #9e9e9e
  border-radius: 20px
  min-height: 100%
  align-self: stretch
.learn-item
  min-height: 100%
  align-self: stretch
  cursor: pointer
  transition: all .25s cubic-bezier(.02,.01,.47,1)
  border-radius: 20px
  .learn-img
    border-top-left-radius: 20px
    border-top-right-radius: 20px
  &:hover
    transform: translate(4px, -4px)
  .learn-icon
    height: 15px

@media screen and (max-width: 960px)
  .learn-item
    transform: scale(1)
    &:hover
      transform: none
</style>
