export default [
  'Todos os seus sonhos podem se tornar realidade se você tiver coragem para persegui-los – Walt Disney',
  'O sucesso é a soma de pequenos esforços repetidos dia após dia – Robert Collier',
  'Quem estuda e não pratica o que aprendeu, é como o homem que lavra e não semeia. – Provérbio Árabe',
  'Educação é o passaporte para o futuro, porque o amanhã pertence àqueles que se preparam para ele hoje – Malcolm X',
  'As raízes dos estudos são amargas, mas seus frutos são doces. – Aristóteles',
  'A única forma de alcançar o impossível é pensar que é possível. – Lewis Carroll',
  'Você nunca sabe que resultados virão da sua ação, mas se você nunca fizer nada, não existirão resultados. – Mahatma Gandhi',
  'Imagine uma nova história para sua vida e acredite nela. – Paulo Coelho',
  'Gênio é um por cento de inspiração e noventa e nove por cento de transpiração. – Thomas Edison',
  'Não importa o quão devagar você vá, desde que não pare. – Confúcio',
  'Aprenda com o ontem. Viva o hoje. Tenha esperança para o amanhã – Albert Einstein',
  'Cada sonho que você deixa para trás é um pedaço do seu futuro que deixa de existir. – Steve Jobs',
  'Aquele que faz perguntas é um bobo por cinco minutos. Mas aquele que jamais questiona é um bobo para sempre – Provérbio Chinês',
  'O aprendiz é um mestre em formação – Fernanda Simões Rodrigues',
]
