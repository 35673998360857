const parseTagsArray = function (tags, type = false) {
  var parsedTags = []
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i]

    try {
      var additionalData = JSON.parse(tag.meta_description)
    } catch (e) {
      console.log('error', e)
      continue
    }

    if (!additionalData || additionalData === null) continue

    const tagObject = { ...tag, ...additionalData }

    if (!type || additionalData.type === type) {
      parsedTags.push(tagObject)
    }
  }
  return parsedTags
}

const parseTagsInPosts = (posts) => {
  for (let i = 0; i < posts.length; i++) {
    posts[i].tags = parseTagsArray(posts[i].tags)
  }

  return posts
}

export { parseTagsArray, parseTagsInPosts }
