<template>
  <div class="content">
    <AppNav
      image="/images/dashboard/icons/content_on.svg"
      title="Conteúdo"
      subtitle="Aprenda mais e aumente sua nota"
    >
      <template #side> </template>
    </AppNav>

    <div class="content-block">
      <NuxtLink
        class="content-block__item"
        :to="c.cta_to"
        v-for="(c, i) in content"
        :key="i"
        v-ripple="{ class: 'primary--text' }"
      >
        <v-card outlined>
          <v-list-item two-line>
            <v-list-item-avatar tile size="40" color="transparent">
              <img :src="c.icon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1 font-weight-bold">
                {{ c.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="item-subtitle">{{
                c.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import AppNav from '@/components/app/AppNav'

export default {
  layout: 'app',

  middleware: 'requireAuth',

  head() {
    return {
      title: 'Conteúdos',
    }
  },

  data: function () {
    return {
      content: [
        {
          title: 'Artigos e Dicas',
          description: 'Conteúdo exclusivo atualizado constantemente.',
          cta: 'Ver curso',
          cta_to: '/conteudo/artigos',
          icon: '/images/dashboard/icons/blog_on.svg',
        },
        {
          title: 'Mapas Mentais',
          description:
            'Resumos em formato de Mapas Mentais para te ajudar a memorizar informações importantes. ',
          cta: 'Ver curso',
          cta_to: '/conteudo/mapas-mentais',
          icon: '/images/dashboard/icons/mindmap.svg',
        },
      ],
    }
  },

  components: {
    AppNav,
  },
}
</script>

<style lang="sass" scoped>
.content
  .content-block
    display: grid
    grid-gap: 0.8em
    grid-template-columns: 1fr
    .content-block__item
      max-width: calc(100vw - 40px)
      &:hover
        .v-card
          background-color: #fafafa !important

@media screen and (max-width: 960px)
  .headline
    font-size: 18px !important
  .item-subtitle
    white-space: normal !important
    font-size: 12px !important
</style>
