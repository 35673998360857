<template>
  <div>
    <VBtn
      color="red"
      text
      class="mt-2"
      small
      rounded
      depressed
      @click="open = true"
    >
      Reportar um problema
    </VBtn>
    <Modal
      v-model="open"
      :loading="sending"
      title="Reportar problema"
      max-width="450"
    >
      <VForm v-if="!sent" ref="form">
        <VRadioGroup
          v-model="issueType"
          :disabled="sending"
          :rules="[(v) => !!v || 'Selecione uma opção']"
          label="Qual opção abaixo melhor descreve o problema?"
        >
          <VRadio
            v-for="issue in issues"
            :key="issue.value"
            :off-icon="$icons.checkboxOff"
            :on-icon="$icons.checkboxOn"
            :value="issue.value"
            :label="issue.label"
          >
            {{ issue.label }}
          </VRadio>
        </VRadioGroup>

        <VTextarea
          v-model="issueDescription"
          outlined
          :disabled="sending"
          label="Descreva o problema"
          persistent-hint
          placeholder="Quanto mais detalhado melhor."
          :rules="[(v) => !!v || 'Por favor descreva o problema.']"
        >
        </VTextarea>
      </VForm>

      <div v-if="sent">
        <h2 class="text-center mb-4">
          <b>Problema reportado com sucesso!</b>
        </h2>
        <p class="text-center">
          Obrigado por nos ajudar a melhorar a plataforma.
        </p>
      </div>
      <template#actions>
        <VBtn :disabled="sending" text rounded depressed @click="open = false"
          >Fechar</VBtn
        >
        <VBtn
          v-if="!sent"
          dark
          rounded
          depressed
          color="new"
          class="px-4"
          :loading="sending"
          @click="sendReport"
        >
          <b>Reportar problema</b>
        </VBtn>
      </template#actions>
    </Modal>
  </div>
</template>

<script>
import Modal from '../helpers/Modal.vue'

export default {
  components: {
    Modal,
  },
  props: {
    correction: Object,
  },
  data() {
    return {
      open: false,
      issueType: '',
      issueDescription: '',
      sending: false,
      sent: false,
      issues: [
        {
          label: 'Problema nas marcações',
          value: 'MARKS',
        },
        {
          label: 'Tem algo errado com o comentário',
          value: 'COMMENT',
        },
        {
          label: 'Não concordo com a nota',
          value: 'GRADES',
        },
        {
          label: 'Outro',
          value: 'OTHER',
        },
      ],
    }
  },

  methods: {
    async sendReport() {
      const validation = this.$refs.form.validate()

      if (!validation) return

      this.sending = true

      try {
        await this.$axios.post('/correction/report', {
          issueType: this.issueType,
          issueDescription: this.issueDescription,
          correctionId: this.correction.id,
        })

        this.sent = true
      } catch (e) {
        console.log(e, this.correction)
        this.$toast.error(
          'Ocorreu um erro ao reportar o problema. Tente novamente mais tarde.',
        )
      }

      this.sending = false
    },
  },
}
</script>

<style lang="sass" scoped></style>
