import { io } from 'socket.io-client'
import { API_URL } from '~/constants/api'

export default function ({ store }, inject) {
  const url = API_URL

  const socket = io(url, {
    withCredentials: true,
    autoConnect: false,
  })

  socket.onAny((event, ...args) => {
    console.log(event, args)
  })

  inject('io', socket)
}
