<template>
  <v-app>
    <VContainer>
      <div class="errorpage d-flex flex-column align-center">
        <div
          class="d-flex flex-column align-center text-center"
          v-if="error.statusCode === 404"
        >
          <img src="/images/404.svg" />
          <h1>
            {{ pageNotFound }}
          </h1>
        </div>
        <div class="" v-else>
          <h1 class="text-center">
            {{ otherError }}
          </h1>
          <p class="text-center">
            Volte e tente novamente, se o problema persistir entre em contato
            com o nosso suporte.
          </p>
        </div>
        <div>
          <a @click.prevent.stop="reload()" href="/" class="mt-4 mr-4">
            <VBtn outlined rounded color="primary" depressed>Atualizar</VBtn>
          </a>

          <a href="/"> Voltar ao início </a>
        </div>
      </div>
    </VContainer>
  </v-app>
</template>

<script>
import NavBar from '@/components/common/NavBar/NavBar'

export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: 'Página não encontrada',
      otherError: 'Aconteceu algum problema.',
    }
  },
  head() {
    console.log(this.error)
    const title =
      this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },

  mounted() {
    console.log('error', this.error)
  },

  methods: {
    reload() {
      window.location?.reload()
    },
  },

  components: {
    NavBar,
  },
}
</script>

<style lang="sass" scoped>
.errorpage
  margin-top: 120px
</style>
