<template>
  <div class="cursoenem">
    <AppNav
      class="mb-0"
      image="/images/dashboard/icons/panel_on.svg"
      title="Redação de Sucesso"
      subtitle="Aprenda a escrever uma redação nota 1000 com videoaulas direto ao ponto"
    >
      <template #side> </template>
    </AppNav>
    <AppLoading v-if="loading" />
    <div v-else class="cursoenem-main">
      <LessonModule
        v-for="(mod, i) in modules"
        :key="mod.id"
        :value="mod"
        :blocked="i > 0 && !isSubscribed"
      />
    </div>
  </div>
</template>

<script>
import quotes from '../../utils/quotes'
import LessonModule from '../../components/lesson/LessonModule.vue'
import AppNav from '../../components/app/AppNav.vue'
import AppLoading from '@/components/app/AppLoading'

export default {
  components: {
    LessonModule,
    AppLoading,
    AppNav,
  },

  data() {
    return {
      quote: quotes[(Math.random() * quotes.length) | 0],
      loading: false,
    }
  },

  computed: {
    userName() {
      return this.$store.getters['auth/userTitle']
    },
    modules() {
      return this.$store.getters['course/modules']
    },
    isSubscribed() {
      return this.$store.getters['auth/isSubscribed']
    },
  },

  created() {
    this.loadModules()
  },

  methods: {
    async loadModules() {
      console.log('loading modules')
      try {
        const { data } = await this.$axios.get('/course/module')

        console.log(data)

        this.$store.commit('course/setModules', data)
        this.loading = false
      } catch (e) {
        this.$toast.error('Não foi possível carregar os módulos')
      }
    },
  },

  head() {
    return {
      title: 'Aprender',
    }
  },

  layout: 'app',
}
</script>

<style lang="sass">
.cursoenem
  padding-bottom: 120px
  .cursoenem-main
    max-width: 100%

.motivation
  white-space: pre-wrap
  font-weight: 300
  font-size: 16px

.lesson-module:nth-last-child(1)
  &::before
    display: none
@media screen and (max-width: 960px)
  .cursoenem
    .cursoenem-main
      max-width: 100%
</style>
