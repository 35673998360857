import ContentAPI from '@/plugins/ghost'
import dayjs from 'dayjs'

export default {
  async load({ state, commit }) {
    if (state.lastLoad !== null) {
      const loadedDate = dayjs(state.lastLoad)

      if (dayjs().subtract(5, 'minute').isAfter(loadedDate)) {
        const themes = await ContentAPI.posts.browse({
          limit: 'all',
          include: ['tags'],
          filter: 'tag:temas-de-redacao',
          fields: ['id', 'slug', 'title', 'feature_image'],
        })
        commit('save', themes)
      }
    } else {
      const themes = await ContentAPI.posts.browse({
        limit: 'all',
        include: ['tags'],
        filter: 'tag:temas-de-redacao',
        fields: ['id', 'slug', 'title', 'feature_image'],
      })
      commit('save', themes)
    }
  },
}
