<template>
  <div class="plan-timer text-center">
    <p class="caption mb-0">Promoção acaba em</p>
    <div v-if="show" class="d-flex text-center justify-center">
      <span> {{ d }}d </span>
      <span> {{ h }}h </span>
      <span> {{ m }}m </span>
      <span> {{ s }}s </span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    endDate: String | Date,
  },

  data: function () {
    return {
      d: 0,
      h: 0,
      m: 0,
      s: 0,
      date: null,
      show: true,
    }
  },

  created() {
    this.date = new Date(this.endDate)

    console.log(this.date, this.endDate)

    this.calcDate()
  },

  destroyed() {
    this.show = false
  },

  methods: {
    calcDate() {
      // Get today's date and time
      var now = new Date().getTime()

      // Find the distance between now and the count down date
      var distance = this.date.getTime() - now

      if (distance < 0 || !this.show) {
        this.show = false
        return
      }

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      )
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      this.d = days
      this.h = hours
      this.m = minutes
      this.s = seconds

      setTimeout(() => {
        this.calcDate()
      }, 1000)
    },
  },
}
</script>

<style lang="sass" scoped>
.plan-timer
  span
    margin: 0 6px
    font-size: 18px
    line-height: 18px
    font-weight: bold
</style>
