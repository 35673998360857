<template>
  <div class="share-page">
    <div class="free-nav">
      <VContainer>
        <div class="d-flex align-center">
          <NuxtLink class="d-flex align-center" to="/">
            <img src="@/assets/images/logo.png" />
          </NuxtLink>
          <VSpacer />
          <NuxtLink to="/cadastrar">
            <VBtn color="new" rounded class="white--text">
              <b>✏️ Cadastre-se grátis</b>
            </VBtn>
          </NuxtLink>
        </div>
      </VContainer>
    </div>
    <VContainer>
      <AppLoading v-if="loading" />

      <div v-if="!loading">
        <EssayPage :essay="essay" is-public @reload="loadData" />
      </div>
    </VContainer>
  </div>
</template>

<script>
import AppLoading from '../../components/app/AppLoading.vue'
import EssayPage from '../../components/essay/EssayPage.vue'
import ContentAPI from '../../plugins/ghost'

export default {
  components: {
    EssayPage,
    AppLoading,
  },
  data() {
    return {
      essay: null,
      loading: true,
    }
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
  },

  mounted() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.loading = true
      try {
        const correctionId = this.$route.params.correction

        const essay = await this.$axios.get(
          '/essays/' + this.$route.params.id,
          {
            params: {
              correctionId,
              isPublic: true,
            },
          },
        )

        let content = null
        try {
          const data =
            essay.data.themeId === 'tema-livre'
              ? {
                  slug: 'tema-livre',
                }
              : {
                  id: essay.data.themeId,
                }
          content = await ContentAPI.posts.read(data, {
            formats: ['html', 'plaintext'],
            include: 'tags,authors',
          })
        } catch (e) {
          console.log('error', e)
        }

        essay.data.theme = content || {
          title: 'Tema indisponível',
        }

        if (!essay.data || essay.data === null) {
          return this.$nuxt.error({
            statusCode: 404,
            message: 'Redação não encontrada',
          })
        }

        this.essay = essay.data

        if (this.essay.contentType === 'IMAGE') {
          if (this.essay.state === 'FINISHED') {
            this.loadCorrectionImage()
          } else {
            this.loadOriginalImage()
          }
        }
      } catch (e) {
        console.log(e)
        return this.$nuxt.error({
          statusCode: 404,
          message: 'Redação não encontrada',
        })
      }
      this.loading = false
    },
  },

  head() {
    return {
      title: 'Redação Corrigida',
    }
  },
}
</script>

<style lang="sass" scoped>
.share-page
  padding-top: 70px
.free-nav
  background-color: rgba(255,255,255,0.9)
  backdrop-filter: blur(10px)
  border-bottom: thin solid #9e9e9e !important
  display: flex
  align-items: center
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 100
  height: 62px

  img
    height: 38px
    margin: 0 auto
</style>
