<template>
  <div v-if="numberOfErrors > 0">
    <v-alert dense color="error" dark class="mb-5 mt-2">
      <template #prepend>
        <div class="hidden-sm-and-down essay-level-paywall">
          <span class="icon">⚠️</span>
        </div>
      </template>
      <v-row align="center" class="ml-2 w-full">
        <v-col cols="12" md="8" class="grow subtitle">
          <span class="font-weight-bold">
            {{ numberOfErrors }}
          </span>
          erros avançados adicionais foram encontrados no seu texto.
        </v-col>
        <v-col md="4" class="shrink d-flex justify-center w-full mx-auto">
          <v-btn small class="ml-auto my-0" @click="openCheckout"
            ><b>Desbloquear erros</b></v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    numberOfErrors: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass">
.essay-level-paywall
  display: flex
  .icon
    font-size: 22px
</style>
