<template>
  <div class="checkout"></div>
</template>

<script>
export default {
  data() {
    return {}
  },

  created() {
    this.$root.$emit('openCheckout')
    this.$router.replace('/')
  },

  layout: 'app',
}
</script>

<style lang="sass">
.v-tooltip__content
  opacity: 1 !important
  box-shadow: 0 13px 27px -5px rgba(50,50,93,.25), 0 8px 16px -8px rgba(0,0,0,.3), 0 -6px 16px -6px rgba(0,0,0,.025)
.checkout__item
  margin-bottom: 96px
</style>
