<template>
  <v-navigation-drawer
    v-model="isOpen"
    fixed
    temporary
    right
    touchless
    class="drawer"
  >
    <v-list-item>
      <v-list-item-avatar>
        <img
          alt="coRedação Logo"
          class="drawer__logo"
          src="@/assets/images/logo.png"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <div class="d-flex align-center">
            <h3>coRedação</h3>
            <VSpacer />
            <VBtn outlined rounded @click="onItemClick" icon>
              <VIcon>
                {{ $icons.close }}
              </VIcon>
            </VBtn>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <VDivider></VDivider>

    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.href"
        @click="onItemClick"
        link
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <nav-buttons @click="isOpen = false" />
  </v-navigation-drawer>
</template>

<script>
import NavButtons from './NavButtons'

export default {
  props: {
    open: Boolean,
    items: Array,
  },

  data() {
    return {}
  },

  methods: {
    onItemClick() {
      this.isOpen = false
    },
  },

  computed: {
    isOpen: {
      get() {
        return this.open
      },

      set(v) {
        this.$emit('change', v)
      },
    },
  },

  components: {
    NavButtons,
  },
}
</script>

<style lang="sass" scoped>
.drawer
  .drawer__logo
    width: 30px
    height: 27.6px
</style>
