<template>
  <div class="logout d-flex justify-center">
    <VProgressCircular indeterminate color="primary"></VProgressCircular>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  created() {
    this.signOut()
    this.$store.commit('essays/loaded', false)
    this.$router.push('/')
  },

  methods: {
    ...mapMutations({
      signOut: 'auth/signOut',
    }),
  },
}
</script>

<style lang="sass" scoped>
.logout
  margin-top: 80px
</style>
