import Vue from 'vue'

export default {
  saveEssay(state, essay) {
    const essayIndex = state.essays.findIndex((e) => e.id === essay.id)

    if (essayIndex >= 0) {
      Vue.set(state.essays, essayIndex, essay)
    } else {
      state.essays.push(essay)
    }
  },

  saveEssays(state, essays) {
    console.log('SAVING ESSAYS', essays)
    state.essays = essays
  },

  loaded(state, loaded) {
    state.loaded = loaded
  },

  savingState(state, savingState) {
    state.savingState = savingState
  },

  currentEssayId(state, id) {
    console.log('currentEssayId', id)
    state.currentEssayId = id
  },

  deleteEssay(state, essay) {
    const essayIndex = state.essays.findIndex((e) => e.id === essay.id)

    Vue.delete(state.essays, essayIndex)
  },
}
