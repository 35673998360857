<template>
  <div>
    <div class="themesnav">
      <VContainer>
        <VStepper v-model="step" color="new" outlined vertical>
          <VStepperHeader>
            <VStepperStep
              :editable="step === '2' && !isFinished"
              class="text-center"
              step="1"
              color="new"
              @click="!isFinished && onStepClick('1')"
            >
              Escolha um tema
            </VStepperStep>

            <VDivider></VDivider>

            <VStepperStep
              :editable="selectedTheme !== null"
              step="2"
              color="new"
              @click="onStepClick('2')"
            >
              Escreva sua redação
            </VStepperStep>

            <VDivider></VDivider>

            <VStepperStep color="new" :editable="isFinished" step="3">
              Receba sua correção
            </VStepperStep>
          </VStepperHeader>
        </VStepper>

        <AppNav
          v-show="step === '1'"
          back-url="/"
          class="searchnav mt-2"
          full
          no-padding
        >
          <template #full>
            <VTextField
              ref="search"
              v-model="search"
              class="ma-0 pa-0"
              hide-details
              outlined
              clearable
              dense
              placeholder="Buscar um tema"
              full-width
              @input="onSearch"
            >
              <template #prepend-inner>
                <VIcon>
                  {{ $icons.search }}
                </VIcon>
              </template>
            </VTextField>

            <VBtn
              outlined
              rounded
              small
              :disabled="drawing"
              depressed
              color="#F95D4B"
              :dark="!drawing"
              class="font-weight-bold ml-4"
              @click="drawTheme"
            >
              <div v-if="!drawing" class="d-flex align-center">
                <VIcon class="" color="#F95D4B">
                  {{ $icons.dice }}
                </VIcon>
                <span class="d-none d-sm-flex ml-1"> Sortear Tema </span>
              </div>
              <div v-else>
                <VProgressCircular
                  indeterminate
                  color="primrary"
                  size="18"
                  class="mr-1"
                ></VProgressCircular>
                Escolhendo um tema...
              </div>
            </VBtn>
          </template>
        </AppNav>

        <EssayWriteBar
          v-if="step === '2'"
          :essay="essayContent"
          @openTheme="openCurrentTheme"
          @finishEssay="onEssayFinished"
        />

        <AppNav
          v-show="step === '3'"
          back-url="/"
          class="searchnav mt-2"
          full
          no-padding
        >
          <template #full>
            <VBtn
              outlined
              rounded
              small
              depressed
              color="white"
              class="new font-weight-bold ml-auto"
              @click="step = '2'"
            >
              <div class="d-flex align-center">
                <VIcon size="14" class="" color="white">
                  {{ $icons.write }}
                </VIcon>
                <span class="ml-1"> Reescrever </span>
              </div>
            </VBtn>
          </template>
        </AppNav>
      </VContainer>
    </div>
    <div class="themespacer" />
    <VContainer>
      <div class="essayappindex">
        <ContentWrapper
          v-show="step === '1'"
          app
          lazy
          no-pagination
          :hide-pagination="true"
          :theme="true"
          :initial-page="1"
          :loading="loading"
          content-path="temas"
          :filter="filter"
          :contents="contents"
          @page-change="loading = true"
        >
          <template #empty>
            <EmptyState
              title="Sem resultados"
              subtitle="Não conseguimos encontrar nenhum tema com os filtros selecionados"
              image="/images/empty/essay.png"
            />
          </template>
        </ContentWrapper>

        <div v-if="!loading">
          <EssayWrite
            v-show="step === '2'"
            v-model="essayContent"
            :theme="selectedTheme"
          />
        </div>

        <div v-if="step === '3'">
          <CorrectingLoading v-if="correcting" />
          <div v-else>
            <AppLoading v-if="loading" />
            <EssayPage v-else :essay="essay" />
          </div>
        </div>
      </div>
    </VContainer>
    <CorrectionsLimit v-model="limitWall" />
    <PremiumLimit v-model="limitWallPremium" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentWrapper from '../../components/content/ContentWrapper.vue'
import AppNav from '../../components/app/AppNav.vue'
import { loadThemes, getTheme } from '../../utils/themes'
import EssayWrite from '../../components/essay/EssayWrite.vue'
import EssayWriteBar from '../../components/essay/EssayWriteBar.vue'
import CorrectingLoading from '../../components/essay/CorrectingLoading.vue'
import EmptyState from '~/components/common/EmptyState.vue'
import EssayPage from '~/components/essay/EssayPage.vue'
import AppLoading from '~/components/app/AppLoading.vue'
import CorrectionsLimit from '~/components/paywalls/CorrectionsLimit.vue'
import PremiumLimit from '~/components/paywalls/PremiumLimit.vue'

export default {
  components: {
    CorrectionsLimit,
    ContentWrapper,
    AppNav,
    EssayWrite,
    EssayWriteBar,
    CorrectingLoading,
    EmptyState,
    EssayPage,
    AppLoading,
    PremiumLimit,
  },

  middleware: 'requireAuth',

  data() {
    return {
      step: '1',
      contents: [],
      essay: {},
      selectedTheme: null,
      correcting: false,
      limitWall: false,
      limitWallPremium: false,
      loading: true,
      drawing: false,
      filter: {
        tags: [],
        search: '',
      },
      essayContent: {
        text: '',
        title: '',
        words: 0,
      },
      tags: [],
      search: '',
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isSubscribed: 'auth/isSubscribed',
    }),

    isFinished() {
      return this.essay.state === 'FINISHED'
    },

    isTag() {
      return this.$route.fullPath.includes('categoria')
    },

    showedTags() {
      return this.tags.filter((t) => t.color !== '#8BCB8B')
    },

    isInHome() {
      return this.tag === null && this.$route.params.tag !== 'todos'
    },

    heroInfo() {
      if (!this.isLoggedIn) return this.info
      else
        return {
          title:
            'Escolha um tema' +
            (!this.info.default ? ` - ${this.info.title}` : ''),
          subtitle:
            (!this.info.default && this.info.subtitle !== null
              ? `${this.info.subtitle}`
              : '') +
            'Escolha entre um dos nossos diversos temas para escrever uma redação',
        }
    },
  },

  watch: {
    $route() {
      this.checkRouteProps()
    },
  },

  async created() {
    this.checkRouteProps()
    await this.loadData()
    this.checkRouteProps()
  },

  methods: {
    openCurrentTheme() {
      if (!this.selectedTheme) return
      this.$root.$emit('openContent', {
        slug: this.selectedTheme.slug,
        hideTheme: true,
        hideThemeChooser: true,
      })
    },

    onStepClick(step) {
      if (step === '1' && this.step === '2') {
        this.$router.push('/temas')
      }

      if (step === '2' && this.step === '1' && this.selectedTheme) {
        this.$router.push(`/temas/?theme=${this.selectedTheme.slug}`)
      }
    },

    async onEssayFinished() {
      this.step = '3'
      this.correcting = true

      try {
        const essayId = this.$store.getters['essays/currentEssayId']
        await this.$axios.post('/correction/evaluate/' + essayId, {
          text: this.essayContent.text,
          themeTitle: this.selectedTheme.title,
        })

        const essay = await this.$store.dispatch('essays/loadEssay', essayId)
        this.essay = essay

        this.$store.commit('essays/loaded', false)
        this.$store.commit('auth/contabilizeCorrection')

        this.$router.push({
          path: '/temas',
          query: {
            id: essay.id,
            theme: this.selectedTheme.slug,
            correction: 1,
          },
        })
      } catch (e) {
        this.step = '2'
        const msg =
          e.response?.data?.error ||
          'Erro ao corrigir redação. Tente novamente mais tarde, se o problema persistir entre em contato com o suporte.'

        console.log('error', e.response?.data)

        if (e.response?.data?.errorCode === 'LIMIT_EXCEEDED') {
          if (this.isSubscribed) {
            this.limitWallPremium = true
          } else {
            this.limitWall = true
          }
          return
        }

        this.$toast.error(msg)
      }

      this.correcting = false
    },

    checkRouteProps() {
      if (this.step === 3) return
      this.loading = false

      const themeSlug = this.$route.query.theme
      const correction = this.$route.query.correction

      console.log('correction', correction)

      if (correction) {
        this.step = '3'
      } else if (themeSlug) {
        this.step = '2'

        this.selectedTheme = getTheme(themeSlug)
        console.log('hey', this.selectedTheme)
      } else {
        this.step = '1'
      }
    },
    onTagClick(tag) {
      console.log('tag', tag)
      this.tag = tag
      this.setFilter({
        tag,
      })
      this.$router.push(`/temas/categoria/${tag.slug}/1`)
    },
    onSearch() {
      this.setFilter({
        ...this.filter,
        search: this.search,
      })

      // Scroll to top
      window.scrollTo(0, 0)
    },
    drawTheme() {
      this.drawing = true

      const allThemes = this.contents

      const theme = allThemes[Math.floor(Math.random() * allThemes.length)]

      this.$root.$emit('openContent', { ...theme, hideTheme: true })
      this.drawing = false
    },

    onFilterChange(newF) {
      if (
        newF.type === 'tag' &&
        this.filter !== null &&
        newF.tag !== this.filter.tag
      )
        this.loading = true
      this.setFilter(newF)
    },

    setFilter(newF) {
      this.filter = newF
    },

    async loadData() {
      this.loading = true
      this.contents = [{}, {}, {}, {}, {}, {}]
      const essayId = this.$route.query.id

      const { themes, tags } = await loadThemes()

      this.contents = themes
      this.tags = tags

      if (essayId) {
        try {
          const essay = await this.$store.dispatch('essays/loadEssay', essayId)
          this.essay = essay

          this.essayContent = {
            text: essay.content,
            title: essay.title,
            words: 0,
          }

          this.selectedTheme = themes.find((t) => t.id === essay.themeId)

          this.$store.commit('essays/currentEssayId', essay.id)
        } catch (e) {
          console.log(e)
          this.$router.push('/')
          this.$toast.error('Redação não encontrada')
        }
      } else {
        this.$store.commit('essays/currentEssayId', null)
      }

      this.loading = false
    },
  },

  head() {
    let title = 'Escolha um tema'

    if (this.step === '2') {
      title = 'Escreva sua Redação'
    } else if (this.step === '3') {
      title = this.isFinished ? 'Sua correção' : 'Corrigindo sua redação'
    }

    return {
      title,
    }
  },
}
</script>

<style lang="sass">
.themesnav
  background-color: rgba(255,255,255,0.9)
  backdrop-filter: blur(10px)
  position: fixed
  width: 100%
  z-index: 50
  border-bottom: thin solid #9e9e9e
  .v-stepper__step
    padding: 12px 24px !important
    flex-direction: column
    .v-stepper__step__step
      font-weight: bold
      margin-right: 0 !important
    .v-stepper__label
      margin-top: 8px

.tags-wrapper
  display: grid
  grid-gap: 1.4em
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))
  .tag-block
    border-radius: 10px
    padding: 12px
    color: white
    h3
      font-size: 22px
    p
      font-size: 14px !important
    &:hover
      .VIcon
        animation: move 800ms infinite ease-in-out

.weeklythemes
  position: relative
  margin-bottom: 48px
.soon-bg
  background-image: url('/images/soon/bgsoon.jpg')
  background-size: cover
  opacity: 0.05
  width: 100vw
  left: 0
  top: 0
  height: 100%
  position: absolute
  z-index: 0
.essaybar
  position: relative
  z-index: 2

@media screen and (max-width: 960px)
  .tags-wrapper
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr))
  .themes
    h2
      font-size: 18px

  .themesnav
    .v-stepper__step
      max-width: 33.3%
      padding: 6px 12px !important
      .v-stepper__step__step
        margin-right: 0
      .v-stepper__label
        margin-top: 8px
        max-width: 100px
        font-size: 14px
        text-align: center
@keyframes move
  0%
    left: 0
  50%
    left: -10px
  100%
    left: 0
.finish-icon
  width: 18px
  margin-right: 4px
.v-stepper--vertical
  padding: 0 !important
.themespacer
  height: 170px
</style>
