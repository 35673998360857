export default [
  {
    text: 'Outros',
    color: '#292524',
    value: 'OUTROS',
  },
  {
    text: 'Competência 1',
    color: '#f87171',
    value: 'C1',
    posts: [
      {
        url: '/conteudo/artigos/8-dicas-para-conseguir-nota-maxima-na-competencia-1-do-enem',
        title: '8 dicas para conseguir nota máxima na competência 1 do ENEM',
      },
    ],
    description:
      'É avaliado se a redação do participante está adequada às regras de ortografia, como acentuação, ortografia, uso de hífen, emprego de letras maiúsculas e minúsculas e separação silábica. Ainda são analisadas a regência verbal e nominal, concordância verbal e nominal, pontuação, paralelismo, emprego de pronomes e crase.',
    levels: [
      'Demonstra desconhecimento da modalidade escrita formal da Língua Portuguesa.',
      'Demonstra domínio precário da modalidade escrita formal da Língua Portuguesa, de forma sistemática, com diversificados e frequentes desvios gramaticais, de escolha de registro e de convenções da escrita.',
      'Demonstra domínio insuficiente da modalidade escrita formal da Língua Portuguesa, com muitos desvios gramaticais, de escolha de registro e de convenções da escrita.',
      'Demonstra domínio mediano da modalidade escrita formal da Língua Portuguesa e de escolha de registro, com alguns desvios gramaticais e de convenções da escrita.',
      'Demonstra bom domínio da modalidade escrita formal da Língua Portuguesa e de escolha de registro, com poucos desvios gramaticais e de convenções da escrita.',
      'Demonstra excelente domínio da modalidade escrita formal da Língua Portuguesa e de escolha de registro. Desvios gramaticais ou de convenções da escrita serão aceitos somente como excepcionalidade e quando não caracterizarem reincidência.',
    ],
  },
  {
    text: 'Competência 2',
    color: '#86198f',
    value: 'C2',
    posts: [
      {
        url: '/conteudo/artigos/5-passos-para-garantir-200-pontos-na-competencia-2-do-enem',
        title: '5 passos para garantir 200 pontos na Competência 2 do ENEM',
      },
    ],
    post: '5e8d2840fcfa5304a2619575',
    description:
      'Avalia as habilidades integradas de leitura e de escrita do candidato. O tema constitui o núcleo das ideias sobre as quais a redação deve ser organizada e é caracterizado por ser uma delimitação de um assunto mais abrangente.',
    levels: [
      'Fuga ao tema/não atendimento à estrutura dissertativo-argumentativa. Nestes casos a redação recebe nota 0 (zero) e é anulada.',
      'Apresenta o assunto, tangenciando o tema, ou demonstra domínio precário do texto dissertativo-argumentativo, com traços constantes de outros tipos textuais.',
      'Desenvolve o tema recorrendo à cópia de trechos dos textos motivadores ou apresenta domínio insuficiente do texto dissertativo-argumentativo, não atendendo à estrutura com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação previsível e apresenta domínio mediano do texto dissertativo-argumentativo, com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação consistente e apresenta bom domínio do texto dissertativo-argumentativo, com proposição, argumentação e conclusão.',
      'Desenvolve o tema por meio de argumentação consistente, a partir de um repertório sociocultural produtivo e apresenta excelente domínio do texto dissertativo-argumentativo.',
    ],
  },
  {
    text: 'Competência 3',
    color: '#0e7490',
    value: 'C3',
    posts: [
      {
        url: '/conteudo/artigos/o-segredo-para-conquistar-nota-maxima-na-competencia-3/',
        title: 'O segredo para conquistar nota máxima na Competência 3',
      },
    ],
    description:
      'O candidato precisa elaborar um texto que apresente, claramente, uma ideia a ser defendida e os argumentos que justifiquem a posição assumida em relação à temática da proposta da redação. Trata da coerência e da plausibilidade entre as ideias apresentadas no texto, o que é garantido pelo planejamento prévio à escrita, ou seja, pela elaboração de um projeto de texto.',
    levels: [
      'Apresenta informações, fatos e opiniões não relacionados ao tema e sem defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões pouco relacionados ao tema ou incoerentes e sem defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, mas desorganizados ou contraditórios e limitados aos argumentos dos textos motivadores, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, limitados aos argumentos dos textos motivadores e pouco organizados, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema, de forma organizada, com indícios de autoria, em defesa de um ponto de vista.',
      'Apresenta informações, fatos e opiniões relacionados ao tema proposto, de forma consistente e organizada, configurando autoria, em defesa de um ponto de vista.',
    ],
  },
  {
    text: 'Competência 4',
    color: '#FFA726',
    value: 'C4',
    posts: [
      {
        url: '/conteudo/artigos/competencia-4-o-que-fazer-para-alcancar-nota-maxima-no-enem/',
        title: 'Competência 4: o que fazer para alcançar nota máxima no ENEM',
      },
    ],
    description: `São avaliados itens relacionados à estruturação lógica e formal entre as partes da redação. A organização textual exige que as frases e os parágrafos estabeleçam entre si uma relação que garanta uma sequência coerente do texto e a interdependência entre as ideias.

    Preposições, conjunções, advérbios e locuções adverbiais são responsáveis pela coesão do texto porque estabelecem uma inter-relação entre orações, frases e parágrafos. Cada parágrafo será composto por um ou mais períodos também articulados. Cada ideia nova precisa estabelecer relação com as anteriores.`,
    levels: [
      'Não articula as informações.',
      'Articula as partes do texto de forma precária.',
      'Articula as partes do texto, de forma insuficiente, com muitas inadequações e apresenta repertório limitado de recursos coesivos.',
      'Articula as partes do texto, de forma mediana, com inadequações, e apresenta repertório pouco diversificado de recursos coesivos.',
      'Articula as partes do texto com poucas inadequações e apresenta repertório diversificado de recursos coesivos.',
      'Articula bem as partes do texto e apresenta repertório diversificado de recursos coesivos.',
    ],
  },
  {
    text: 'Competência 5',
    color: '#0277BD',
    value: 'C5',
    posts: [
      {
        url: '/conteudo/artigos/5-passos-para-garantir-200-pontos-na-competencia-2-do-enem/',
        title: '5 passos para garantir 200 pontos na Competência 2 do ENEM',
      },
    ],
    description: `Apresentar uma proposta de intervenção para o problema abordado que respeite os direitos humanos. Propor uma intervenção para o problema apresentado pelo tema significa sugerir uma iniciativa que busque, mesmo que minimamente, enfrentá-lo. A elaboração de uma proposta de intervenção na prova de redação do Enem representa uma ocasião para que o candidato demonstre o preparo para o exercício da cidadania, para atuar na realidade em consonância com os direitos humanos.`,
    levels: [
      'Não apresenta proposta de intervenção ou apresenta proposta não relacionada ao tema ou ao assunto.',
      'Apresenta proposta de intervenção vaga ou apenas citada, precária ou relacionada apenas ao assunto.',
      'Elabora, de forma insuficiente, proposta de intervenção relacionada ao tema, ou proposta não articulada com a discussão desenvolvida no texto.',
      'Elabora, de forma mediana, proposta de intervenção relacionada ao tema e articulada à discussão desenvolvida no texto.',
      'Elabora bem proposta de intervenção relacionada ao tema e articulada à discussão desenvolvida no texto.',
      'Elabora muito bem proposta de intervenção, de forma detalhada, relacionada ao tema e articulada à discussão desenvolvida no texto.',
    ],
  },
]
