<template>
  <div class="stats-blocked d-flex">
    <div class="blocked-content mt-4">
      <div class="pa-4 bg-white d-inline-flex flex-column align-center">
        <img src="/images/icons/star.png" class="mb-2" />

        <h2>coRedação <span class="new--text">PREMIUM</span></h2>
        <p>a partir de 12 x {{ price }}/mês</p>

        <p class="mb-4">
          Veja como sua redação se compara com as <b>Redações nota 1000</b> das
          edições passadas do ENEM. E mais:
        </p>

        <Benefits class="mb-4" />

        <VBtn depressed class="new mb-4" dark @click="openCheckout">
          <b>⭐ Destravar minha redação</b>
        </VBtn>
      </div>
    </div>
  </div>
</template>

<script>
import Benefits from './Benefits.vue'
export default {
  components: { Benefits },
  computed: {
    price() {
      return this.$store.getters['auth/priceFrom']
    },
  },
  methods: {
    openCheckout() {
      this.$root.$emit('openCheckout')
    },
  },
}
</script>

<style lang="sass" scoped>
.wall-tooltip
  text-decoration: underline dotted
.stats-blocked
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 20


  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(255,255,255,0.4)
    backdrop-filter: blur(3px)
  .blocked-content
    position: relative
    z-index: 10
    padding: 10px
    max-width: 400px
    margin: 0 auto

    img
      width: 30px
    h2, p
      text-align: center
</style>
