<template>
  <div>
    <AppNav class="mt-2" full no-padding>
      <template #full>
        <div class="d-flex w-full align-center">
          <div class="d-flex align-center">
            <span v-if="savingState === 'saving'"> Salvando </span>
            <span v-if="savingState === 'saved'" class="new--text">
              Salvo
            </span>
          </div>
          <VSpacer />

          <div class="baritems">
            <VMenu
              transition="slide-y-transition"
              open-on-hover
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn rounded depressed large v-bind="attrs" v-on="on">
                  <span class="item-text">
                    {{ essay.paragraphs }}
                  </span>
                  <span> Paragráfos </span>
                </VBtn>
              </template>
              <VList class="">
                <div class="barcontent pa-4">
                  Redações nota alta possuem entre <b>4 e 5</b> parágrafos. O
                  ideal é que você tenha 4 parágrafos, sendo 1 de
                  <b>introdução</b>, 2 de <b>desenvolvimento</b>, e 1 de
                  <b>conclusão</b>.
                </div>
              </VList>
            </VMenu>

            <VMenu
              transition="slide-y-transition"
              open-on-hover
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn rounded depressed large v-bind="attrs" v-on="on">
                  <span class="item-text">
                    {{ essay.words }}
                  </span>
                  <span> Palavras </span>
                </VBtn>
              </template>
              <VList>
                <div class="barcontent pa-4">
                  A maioria das redações nota alta possuem
                  <b>mais de 360</b> palavras.
                </div>
              </VList>
            </VMenu>
          </div>

          <VTooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                class="mr-2"
                large
                v-bind="attrs"
                icon
                @click="$emit('openTheme')"
                v-on="on"
              >
                <VIcon>{{ $icons.theme }}</VIcon>
              </VBtn>
            </template>
            <span>Visualizar tema</span>
          </VTooltip>
          <VBtn
            outlined
            rounded
            depressed
            class="new d-flex align-center"
            color="white"
            @click="confirm = true"
          >
            <img class="finish-icon" src="/images/icons/goal.svg" />
            Corrigir redação
          </VBtn>
        </div>
      </template>
    </AppNav>
    <Modal v-model="confirm" persistent title="Opa, terminou?">
      <p>
        Você tem certeza que deseja finalizar sua redação e receber sua
        correção?
      </p>
      <p>Essa ação irá consumir <b>1 crédito de correção</b>.</p>
      <template #actions>
        <VBtn text rounded depressed @click="confirm = false">Cancelar</VBtn>
        <VBtn
          outlined
          rounded
          color="white"
          class="new px-4"
          depressed
          @click="finishEssay"
          >Corrigir redação</VBtn
        >
      </template>
    </Modal>
  </div>
</template>
<script>
import AppNav from '../app/AppNav.vue'
import Modal from '../helpers/Modal.vue'

export default {
  components: {
    AppNav,
    Modal,
  },

  props: {
    essay: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      confirm: false,
    }
  },

  computed: {
    savingState() {
      return this.$store.state.essays.savingState
    },
  },

  methods: {
    finishEssay() {
      this.$emit('finishEssay')
    },
  },
}
</script>

<style lang="sass">
.baritems
  display: flex
  align-items: center
  gap: 8px
  margin-right: 4px

  @media screen and (max-width: 600px)
    display: none
.barcontent
  max-width: 300px !important
.item-text
  font-weight: bold
  margin-right: 2px
</style>
