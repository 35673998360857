<template>
  <div class="lesson-head">
    <div v-if="!blog">
      <VProgressCircular
        :value="progress"
        :size="circleSize"
        :width="6"
        :rotate="-90"
        color="#FF6338"
        class="d-none d-md-flex"
      >
        <span class="circle-value"> {{ progress }}% </span>
      </VProgressCircular>
    </div>
    <div
      class="head-content"
      :class="{ 'pl-5': !blog && $vuetify.breakpoint.mdAndUp }"
    >
      <div>
        <h2 class="">📚 {{ value.title }}</h2>
        <p class="mb-0">
          {{ value.description }}
        </p>
      </div>
      <div v-if="false" class="head-stars">
        <img src="/images/icons/star.png" />
        <span>1</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blog: Boolean,
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    circleSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 50
        case 'sm':
          return 50
        case 'md':
          return 80
        case 'lg':
          return 80
        case 'xl':
          return 80
      }

      return 50
    },

    progress() {
      if (this.blog) return 0
      let finisheds = 0

      if (!this.value || !this.value.lessons) return 0

      this.value.lessons.forEach((lesson) => {
        const isFinished =
          lesson.userStatus?.state === 'FINISHED' ||
          lesson.userStatus?.percentageWatched > 90 ||
          this.$store.getters['course/lessonsProgress'][lesson.id]
            ?.percentageWatched > 90

        if (isFinished) finisheds += 1
      })

      return Math.floor((finisheds / (this.value.lessons?.length || 1)) * 100)
    },
  },
}
</script>

<style lang="sass" scoped>
.lesson-head
  padding: 12px 24px
  display: flex
  .head-content
    display: flex
    flex-grow: 1
    h2
      font-size: 24px
      color: #333333
    .head-stars
      margin-left: auto
      display: flex
      position: relative
      img
        width: 60px
        height: 60px
        align-self: center
      span
        position: absolute
        color: #333333
        font-weight: bold
        font-size: 24px
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
  .circle-value
    font-size: 18px
    font-weight: bold

@media screen and (max-width: 700px)
  .lesson-head
    padding: 12px
    flex-direction: column
    .head-content
      padding: 0
      h2
        font-size: 22px
        margin-bottom: 6px
      p
        font-size: 16px
      .head-stars
        display: none
    .circle-value
      font-size: 14px
      font-weight: normal
</style>
