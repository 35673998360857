<template>
  <div>
    <AppNav
      class="mb-0"
      sub
      :image="content.feature_image"
      title="Exibindo artigo"
      :subtitle="tagName"
    />

    <content-post-head app class="article-head pt-0" :content="content" />
    <VDivider class="mt-5" />
    <content-post-body :content="content" />
  </div>
</template>

<script>
import ContentAPI from '@/plugins/ghost'
import { parseTagsArray, parseTagsInPosts } from '@/utils/tagsParser'
import LazyHydrate from 'vue-lazy-hydration'
import AppNav from '@/components/app/AppNav'

export default {
  mounted() {
    console.log(this.$route)
  },
  middleware: 'requireAuth',
  layout: 'app',

  scrollToTop: true,

  head() {
    return {
      title: this.content.title + ' | Tema de Redação' + this.tagName,

      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.content.title + ' | Tema de Redação' + this.tagName,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.content.title + ' | Tema de Redação' + this.tagName,
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.content.feature_image,
        },
        { hid: 'description', name: 'description', content: this.description },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.description,
        },
      ],

      link: [
        {
          rel: 'canonical',
          href: `https://coredacao.com${this.$route.path.slice(-1) === '/' ? this.$route.path : this.$route.path + '/'}`,
        },
      ],
    }
  },

  computed: {
    description() {
      if (!!this.content.meta_description) return this.content.meta_description

      const words = this.content.plaintext.substring(0, 160).split(' ')
      return words.splice(0, words.length - 1).join(' ') + '...'
    },

    tagName() {
      return !!this.content.primary_tag ? this.content.primary_tag.name : ''
    },
  },

  mounted() {
    this.$vuetify.goTo(0, {
      container: document.querySelector('.app-content'),
      duration: 0,
    })
  },

  async asyncData({ params, error }) {
    try {
      var content = await ContentAPI.posts.read(
        { slug: params.id },
        {
          formats: ['html', 'plaintext'],
          include: 'tags,authors',
        },
      )

      content.tags = parseTagsArray(content.tags)
    } catch (e) {
      error({ statusCode: 404, message: 'Redação não encontrada' })
    }

    return {
      content: content,
    }
  },

  components: {
    ContentPostHead: () =>
      import('@/components/content/ContentPost/ContentPostHead'),
    ContentPostBody: () =>
      import('@/components/content/ContentPost/ContentPostBody'),
    ContentPostSocial: () =>
      import('@/components/content/ContentPost/ContentPostSocial'),
    LazyHydrate,
    AppNav,
  },
}
</script>

<style lang="sass" scoped>
.article-head
  padding: 0 60px
</style>
