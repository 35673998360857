export default {
  setInstallEvent(state, installEvent) {
    state.installEvent = installEvent
  },

  openInstallBanner(state) {
    if (!state.installEvent) return

    state.installEvent.prompt()
  },
}
