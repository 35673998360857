<template>
  <div>
    <WelcomeHeader title="Quais seu principal objetivo no coRedação?">
      Isso vai nos ajudar a melhorar sua experiência.
    </WelcomeHeader>

    <Chooser
      v-model="goal"
      class="mt-4"
      :options="[
        {
          value: 'Descobrir a nota da minha redação',
          name: 'Descobrir a nota da minha redação'
        },
        {
          value: 'Descobrir o que estou errando na minha redação',
          name: 'Descobrir o que estou errando na minha redação'
        },
        {
          value: 'Aprender a escrever com o curso, apostila e conteúdos',
          name: 'Aprender a escrever com o curso, apostila e conteúdos'
        },
        {
          value: 'Ver modelos de redação',
          name: 'Ver modelos de redação'
        }
      ]"
    />
  </div>
</template>

<script>
import WelcomeHeader from './WelcomeHeader.vue'
import Chooser from './Chooser.vue'

export default {
  components: {
    WelcomeHeader,
    Chooser
  },

  computed: {
    goal: {
      get() {
        return this.$store.state.welcome.goal
      },
      set(value) {
        this.$store.commit('welcome/setGoal', value)
      }
    }
  }
}
</script>

<style lang="sass"></style>
