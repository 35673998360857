import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=021cb8f1&scoped=true&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&id=021cb8f1&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "021cb8f1",
  null
  
)

export default component.exports