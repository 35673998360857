import dayjs from 'dayjs'
import { FormatMoney } from '~/utils/money'

export default {
  isSubscribed(state) {
    return !!state.user?.isPremium
  },

  v1User(state) {
    return state.user.v1User || null
  },

  expiredType(state) {
    return state.expired
  },

  resetDate(state, getters) {
    if (!getters.isLoggedIn) return null

    const user = getters.currentUser
    const lastResetDay = dayjs(user.lastResetDay)

    return lastResetDay.add(1, 'month').format('DD-MM-YYYY')
  },

  isLoggedIn(state) {
    return state.token !== null
  },

  currentUser(state) {
    return state.user
  },

  hasEssaysEnabled(state, getters) {
    if (getters.isLoggedIn) {
      return true
    }

    return false
  },

  redirectUrl(state) {
    return state.redirectUrl ? state.redirectUrl : null
  },

  isFirstTime(state) {
    return state.isFirstTime
  },

  userTitle(_state, getters) {
    return getters.currentUser?.fullname?.split(' ')[0] || ''
  },

  userAbbreviation(_state, getters) {
    const names = getters.currentUser?.fullname?.split(' ')

    if (!names) return ''

    if (names.length === 1) {
      return getters.currentUser?.fullname?.substring(0, 2)?.toUpperCase()
    } else if (names.length > 1) {
      return names[0]?.charAt(0) + names?.pop()?.charAt(0)?.toUpperCase()
    }
  },

  device(state) {
    return state.deviceInfo
  },

  plans(state) {
    return state.configs.plans
  },

  selectedPlan(state) {
    return state.configs.plans.find((plan) => plan.id === state.selectedPlanId)
  },

  priceFrom(state) {
    // if (!state.configs.plans) {
    //   return FormatMoney(7.99)
    // }
    return FormatMoney(state.configs.plans[0].price / 12)
  },

  limits(state) {
    return state.configs.limits
  },

  preferences(state, getters) {
    if (getters.currentUser) {
      return getters.currentUser.preferences
    }

    return []
  },

  affiliate(state) {
    return state.user?.affiliate || null
  },
}
