<template>
  <span
    v-mouseover-outside="onLeave"
    :style="{
      marginRight: mark.level === 'paragraph' ? '6px' : 0,
      display: 'inline',
      backgroundColor: highlight
        ? `${color.bg}80`
        : mark.level === 'token'
          ? `${color.bg}60`
          : `${color.bg}20`,
    }"
    class="text-marker"
    @mouseenter="onEnter"
    ><span
      ref="mark"
      :class="`mark selectable_${nodeKey}`"
      :style="markStyle"
      @click.stop.prevent="onClick"
      v-html="value"
    /><VSlideYTransition>
      <div
        v-show="hover"
        ref="popover"
        :style="markPosition"
        class="text-marker__content"
        @mouseover="interruptClose"
      >
        <MarkWall v-if="isBlockedMark" />
        <div ref="popover" class="text-marker__popover">
          <div
            :style="{ 'background-color': '#FEF6F0' }"
            class="d-flex align-center text-marker__title"
          >
            <span class="mark__title">
              {{ mark.title }}
            </span>
            <VSpacer></VSpacer>
            <VBtn
              v-if="mark.competence !== 'OUTROS'"
              rounded
              small
              depressed
              :color="color.comp"
              dark
              class="text-marker__competence-btn d-flex align-center caption"
              @click="$root.$emit('openCompetence', mark)"
            >
              {{ mark.competence }}
            </VBtn>
          </div>
          <div class="text-marker__body">
            <div class="text-left">
              {{ !!mark.comment ? mark.comment : 'Sem comentário' }}
            </div>
            <div
              v-if="mark.replacements.length > 0"
              class="text-marker__replacement mt-4"
            >
              <div
                v-if="mark.replacements.length > 1"
                class="caption font-weight-bold"
              >
                Possíveis substituições
              </div>
              <div v-else class="caption font-weight-bold">
                Possível substituição
              </div>
              <div class="replacements d-flex flex-wrap">
                <VChip
                  v-for="r in mark.replacements"
                  :key="r"
                  dark
                  color="green"
                >
                  {{ r }}
                </VChip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VSlideYTransition>
  </span>
</template>

<script>
import competences from '@/constants/competences'
import MarkWall from '~/components/paywalls/MarkWall.vue'

export default {
  components: {
    MarkWall,
  },
  props: {
    mark: Object,
    value: String,
    readonly: Boolean,
    nodeKey: Number,
    areaWidth: Number,
    areaHeight: Number,
    groupHover: String,
    parentHover: String,
    areaRect: DOMRect,
    isSubscribed: Boolean,
  },

  data() {
    return {
      hover: false,
      pop: false,
      leaveFunction: null,
      showCompetence: false,
      canBeHidden: true,
      correctionArea: document.querySelector('#correction__area'),
      markPosition: {
        top: `${0}px`,
        left: `${0}px`,
      },
    }
  },

  computed: {
    isBlockedMark() {
      return !this.isSubscribed && this.mark.origin === 'custom'
    },
    highlight() {
      return this.hover || this.groupHover === this.mark.groupId
    },
    markStyle() {
      return {
        'border-bottom':
          this.mark.level === 'sentence' ||
          this.mark.isChild ||
          this.isBlockedMark
            ? `2px solid ${this.color.line}80`
            : '',
        'padding-top': '3px',
        'text-align': 'center',
        cursor: this.readonly ? 'default' : 'pointer',
      }
    },

    color() {
      const c = competences
        .filter((c) => {
          // Extract number from competence value
          return c.value === this.mark.competence
        })
        .pop()

      return {
        comp: c.color,
        bg:
          this.mark.level === 'token' && !this.isBlockedMark
            ? c.color
            : '#aaaaaa',
        line:
          this.mark.level === 'token' && !this.mark.isChild
            ? c.color
            : '#999999',
      }
    },
  },

  created() {
    this.$root.$on('markEnter', (componentId) => {
      if (componentId !== this._uid && !!this.hover && !!this.canBeHidden) {
        this.hover = false
      }
    })
  },

  methods: {
    onClick() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$root.$emit('openMarkModal', {
          ...this.mark,
          text: this.value,
        })
      }
    },

    interruptClose() {
      if (this.leaveFunction) {
        clearTimeout(this.leaveFunction)
        this.canBeHidden = false

        setTimeout(() => {
          this.canBeHidden = true
        }, 150)
      }
    },

    onLeave(force = false) {
      if (this.hover) {
        this.leaveFunction = setTimeout(() => {
          if (this.canBeHidden) {
            this.hover = false
            if (this.groupHover === this.mark.groupId) {
              this.$emit('groupHover', null)
            }
          }
        }, 150)
      }
    },

    calculatePosition(ev) {
      let popoverHeight = this.$refs.popover.offsetHeight
      popoverHeight = popoverHeight > 0 ? popoverHeight : 200

      const isCollidingRight = ev.offsetX + 400 > this.areaWidth

      const left = isCollidingRight ? ev.offsetX - 400 : ev.offsetX

      const rectRelated = this.correctionArea.getBoundingClientRect()
      const rectMark = ev.target.getBoundingClientRect()

      let top = rectMark.top - rectRelated.top + rectMark.height + 5

      // Top logic
      // 1. Check if it's colliding with parent bottom
      if (this.areaHeight - top < 250) {
        top = top - popoverHeight - rectMark.height - 25
      }

      // 2. Check if it's colliding with viewport

      this.markPosition = {
        top: `${top}px`,
        left: `${left}px`,
      }
    },

    onEnter(ev) {
      if (this.$vuetify.breakpoint.smAndDown) return
      if (!this.hover && !!this.canBeHidden) {
        this.calculatePosition(ev)
        this.hover = true

        this.$nextTick(() => {
          this.calculatePosition(ev)
        })

        this.canBeHidden = false

        setTimeout(() => {
          this.canBeHidden = true
        }, 150)

        this.$root.$emit('markEnter', this._uid)

        // Emiting groupHover
        this.$emit('groupHover', this.mark.groupId)
        setTimeout(() => {
          this.$emit('groupHover', this.mark.groupId)
        }, 150)
      } else if (this.leaveFunction !== null) {
        clearTimeout(this.leaveFunction)
        this.leaveFunction = null
      }
    },
  },
}
</script>

<style lang="sass">
.hovermark
  position: absolute
  width: 100%
  height: 100%
.mark__title
  font-size: 16px
  overflow-wrap: normal
  word-break: normal
  text-align: left
.text-marker__block
  display: inline
.text-marker
  width: 100%
  margin: 0 0.5px
  padding: 0 0
  display: inline
  user-select: none
  white-space-collapsing: discard
  transition: all 0.2s ease-in-out

  .text-marker__content
    user-select: none
    font-family: 'Source Sans Pro', sans-serif
    z-index: 20
    position: absolute
    overflow: hidden
    border-radius: 10px
    white-space: normal !important
    width: 400px
    max-width: 100%
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
    .text-marker__popover
      position: relative
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
      border-radius: 10px
      background: white
    .text-marker__body
      background-color: white
      padding: 12px 24px
      line-height: 19px
      font-size: 16px
      font-weight: 400

    .text-marker__title
      flex: 1
      flex-grow: 1
      padding: 8px 0
      padding-left: 24px
      padding-right: 12px
      border-top-right-radius: 10px
      border-top-left-radius: 10px
      font-size: 17px
      line-height: 19px
      font-weight: bold
      color: #263238
      .v-btn__content
        font-size: 10px !important
    .text-marker__competence
      width: 8px
      height: 8px
      border-radius: 4px
      margin-right: 6px
.replacements
  gap: 4px
@media screen and (max-width: 960px)
  .mark
    cursor: pointer !important
  .text-marker__content
    display: none !important
</style>
