<template>
  <div class="EssayStats pa-4 text-start">
    <h2>Métricas do texto</h2>
    <p>
      Veja como seu texto se compara com as redações nota 1000 das edições
      passadas do ENEM.
    </p>
    <VDivider class="w-full my-2" />
    <EssayStatsItem
      v-for="stat in stats"
      :key="stat.label"
      :label="stat.label"
      :info="stat.info"
      :value="stat.value"
      :median="stat.median"
      :max="stat.max"
      :min="stat.min"
      :max-limit="!!stat.maxLimit"
    />
  </div>
</template>

<script>
import EssayStatsItem from './EssayStatsItem.vue'
export default {
  components: {
    EssayStatsItem,
  },
  props: {
    statistics: {
      type: Object,
      required: true,
    },
    isFreeTheme: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      stats: [],
    }
  },

  mounted() {
    this.loadStats()
  },

  methods: {
    loadStats() {
      if (!this.statistics) return

      const stats = []

      if (this.statistics?.Geral?.Palavras) {
        stats.push({
          label: 'Palavras',
          info: 'Número de palavras no seu texto.<br> Redações nota 1000 têm entre <b>320 e 476</b> palavras.',
          value: this.statistics.Geral.Palavras,
          median: 403,
          min: 320,
          max: 476,
        })
      }

      if (this.statistics?.Geral?.Linhas) {
        stats.push({
          label: 'Linhas',
          info: 'Número de linhas no seu texto.<br> Redações nota 1000 têm entre <b>22 e 30</b> linhas.',
          value: Math.ceil(this.statistics?.Geral?.Linhas),
          median: 26,
          min: 22,
          max: 30,
        })
      }

      if (this.statistics?.Parágrafos?.Total) {
        stats.push({
          label: 'Parágrafos',
          info: 'Número de parágrafos no seu texto.<br> Redações nota 1000 têm 4 ou 5 parágrafos.',
          value: this.statistics?.Parágrafos?.Total,
          median: 4,
          min: 4,
          max: 5,
        })
      }

      if (this.statistics['Operadores argumentativos']?.Total) {
        stats.push({
          label: 'Operadores argumentativos',
          info: 'Número de operadores argumentativos no seu texto.<br> Redações nota 1000 têm entre <b>10 e 17</b> operadores argumentativos.',
          value: this.statistics['Operadores argumentativos']?.Total,
          median: 13,
          min: 10,
          max: 17,
        })
      }

      if (this.statistics?.Vocabulário?.Conectivos) {
        stats.push({
          label: 'Conectivos',
          info: 'Número de conectivos no seu texto. Redações nota 1000 têm <b>7 e 17</b> conectivos.',
          value: this.statistics.Vocabulário.Conectivos,
          median: 12,
          min: 7,
          max: 17,
        })
      }

      if (this.statistics?.Desvios?.Total) {
        stats.push({
          label: 'Desvios gramaticais ou ortográficos',
          info: 'Número de desvios no seu texto. Redações nota 1000 têm entre <b>0 a 7</b> desvios.',
          value: this.statistics?.Desvios?.Total,
          median: 5,
          min: 0,
          max: 7,
        })
      }

      if (this.statistics?.Entidade) {
        const total =
          this.statistics?.Entidade.Pessoa +
          this.statistics?.Entidade.Local +
          this.statistics?.Entidade.Organização

        stats.push({
          label: 'Entidades citadas',
          info: 'Número de entidades citadas no seu texto.<br> Redações nota 1000 citam entre <b>3 e 11</b> entidades.',
          value: total,
          median: 7,
          min: 3,
          max: 11,
          maxLimit: true,
        })
      }

      if (this.statistics?.Tema && !this.isFreeTheme) {
        stats.push({
          label: 'Palavras da frase temática',
          info: 'Número de palavras da frase temática no seu texto.<br> Redações nota 1000 têm entre <b>7 e 20</b> palavras desse tipo.',
          value: this.statistics?.Tema['Palavras frase temática'],
          median: 14,
          min: 7,
          max: 20,
        })
      }

      this.stats = stats
    },
  },
}
</script>
