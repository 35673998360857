export default {
  client: [
    {
      title: 'Início',
      href: '/',
    },
    // {
    //   title: 'Curso Redação ENEM',
    //   href: '/curso-redacao-enem/'
    // },
    {
      title: 'Temas',
      href: '/temas-de-redacao/',
    },
    {
      title: 'Conteúdo',
      href: '/conteudo/',
    },
    {
      title: 'Apostila gratuita',
      href: '/apostila/parte-1',
    },
  ],
  noLogged: [
    {
      title: 'Início',
      href: '/',
    },
    // {
    //   title: 'Curso Redação ENEM',
    //   href: '/curso-redacao-enem/'
    // },
    {
      title: 'Temas',
      href: '/temas-de-redacao/',
    },
    {
      title: 'Conteúdo',
      href: '/conteudo/',
    },
    {
      title: 'Apostila gratuita',
      href: '/conteudo/apostila',
    },
  ],
}
