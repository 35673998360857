export default function ({ store, redirect, route }) {
  console.log('MIDDLEWARE')

  if (!process.client) return

  // If the user is not authenticated
  if (!store.getters['auth/isLoggedIn']) {
    console.log('not logged', route.fullPath)
    store.commit('auth/setRedirect', route.fullPath)

    return redirect('/apostila/cadastrar')
  }
}
