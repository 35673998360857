<template>
  <div>
    <Modal
      v-model="open"
      max-width="900"
      max-height="90vh"
      :color="content.color"
      :title="content.text"
      @input="$emit('input', open)"
    >
      <div class="competence-container mb-5">
        <VTabs v-model="tab" class="tabs" :color="content.color" height="40">
          <VTab>Descrição</VTab>
          <VTab>Níveis</VTab>
        </VTabs>
        <VTabs-items v-model="tab" class="competence-content">
          <VTab-item class="mt-2">
            <div v-if="grade !== null" class="mb-4">
              <h3>Nível</h3>
              <span class="c-modal__text">
                Você tirou <b>{{ grade }}</b> de <b>200</b> pontos nessa
                competência.
              </span>
            </div>
            <h3>Sobre a competência</h3>
            <div class="c-modal__text">
              {{ content.description }}
            </div>
          </VTab-item>
          <VTab-item class="pt-2">
            <div class="c-modal__text">
              <div v-for="(level, i) in content.levels" :key="i" class="mb-2">
                <h4>{{ i * 40 }} ponto<span v-if="i > 0">s</span></h4>
                {{ level }}
              </div>
              <VDivider class="my-2"></VDivider>
            </div>
          </VTab-item>
        </VTabs-items>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../helpers/Modal.vue'
import competences from '@/constants/competences'

export default {
  components: {
    Modal,
  },
  data() {
    return {
      open: false,
      tab: null,
      grade: null,
      competence: 0,
    }
  },

  computed: {
    content() {
      const c = competences.filter((c) => c.value === this.competence).pop()
      return c || {}
    },

    levelDesc() {
      const i = this.grade / 40

      return this.content.levels[i]
    },
  },

  destroyed() {
    this.$root.$off('openCompetence')
  },

  created() {
    this.$root.$on('openCompetence', (data) => {
      console.log('received openCompetence', data)
      this.competence = data.competence
      this.grade = data.grade
      this.tab = null
      this.open = true
    })
  },
}
</script>

<style lang="sass" scoped>
.competence-content
  max-height: calc(100vh - 300px)
  overflow: auto
.c-modal__text
  font-size: 16px
.tabs
  margin-top: -15px
  margin-bottom: 12px
</style>
