<template>
  <VContainer>
    <v-row class="pricing__container align-center justify-center">
      <v-sheet
        v-for="plan in parsedPlans"
        :key="plan.planId"
        class="pricing pricing__small"
      >
        <v-row
          no-gutters
          class="pricing__body align-center flex-column px-5 pt-5"
        >
          <h2 class="text-center">Pacote {{ plan.quantity }} Correções</h2>
          <div class="d-flex">
            <p class="text-center caption">
              Cada redação sai a
              <b :class="{ 'strike mr-1 price-opacity': plan.offer }"
                >R$ {{ formatMoney(plan.price / plan.quantity) }}</b
              >
            </p>
            <span v-if="plan.offer" class="text-center">
              <b
                >R$
                {{
                  formatMoney(
                    (plan.price * ((100 - plan.discount) / 100)) /
                      plan.quantity,
                  )
                }}</b
              >
            </span>
          </div>
          <div class="d-flex">
            <div
              v-if="plan.offer"
              class="pricing__price--before pricing__price d-flex align-start mr-2"
            >
              <span> R$ </span>
              <div class="d-flex align-end">
                <h5 class="plan-money">
                  {{ formatMoney(plan.price / plan.months) }}
                </h5>
                <h3 class="pricing__months">x {{ plan.months }}</h3>
              </div>
            </div>
            <div class="pricing__price d-flex align-start">
              <span> R$ </span>
              <div class="d-flex align-end">
                <h5 v-if="!plan.offer" class="plan-money">
                  {{ formatMoney(plan.price / plan.months) }}
                </h5>
                <h5 v-if="plan.offer" class="plan-money">
                  {{
                    formatMoney(
                      (plan.price * ((100 - plan.discount) / 100)) /
                        plan.months,
                    )
                  }}
                </h5>
                <h3 class="pricing__months">x {{ plan.months }}</h3>
              </div>
            </div>
          </div>
          <p class="mb-0 mt-1 caption">
            ou à vista por
            <b
              v-if="!plan.offer"
              :class="{ 'strike mr-1 price-opacity': plan.offer }"
              >R$ {{ formatMoney(plan.price) }}</b
            >
            <b v-if="plan.offer"
              >R$
              {{ formatMoney(plan.price * ((100 - plan.discount) / 100)) }}</b
            >
          </p>
          <ul class="features ml-0 pl-0">
            <li></li>
          </ul>
          <div v-if="plan.offer" class="plan-timer">
            <VDivider class="mb-2" />
            <plan-timer v-if="plan.offer" :end-date="plan.endDateTime" />
          </div>
        </v-row>
        <v-row no-gutters class="pricing__actions justify-center">
          <VBtn
            outlined
            rounded
            large
            min-height="60"
            color="primary"
            depressed
            block
            @click="goForPlan(plan)"
            >Assinar <VIcon class="ml-1" size="16">mdi-arrow-right</VIcon></VBtn
          >
        </v-row>
      </v-sheet>
    </v-row>
  </VContainer>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import PlanTimer from './PlanTimer.vue'
import perks from '@/constants/perks'

export default {
  components: { PlanTimer },
  props: {
    plans: Array,
  },

  data() {
    return {
      perks,
    }
  },

  mounted() {
    this.$gtag('event', 'begin_checkout', {
      items: [],
      coupon: '',
    })
  },

  methods: {
    ...mapMutations({
      setSelected: 'checkout/setSelected',
    }),
    goForPlan(plan) {
      this.$gtag.event({
        eventCategory: 'compra',
        eventAction: 'plano_escolhido',
        eventLabel: plan.title,
      })

      this.$gtag('event', 'checkout_progress', {
        items: [
          {
            id: plan.planId,
            quantity: 1,
            price: plan.price,
          },
        ],
        coupon: '',
      })

      this.setSelected(plan)
      // this.$router.push('/assinar/cadastrar')
      this.$router.push('/assinar/pagamento/' + plan.planId)
    },
    formatMoney(val) {
      val = parseFloat(val)
      return val
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace('.', ',')
    },
  },

  computed: {
    ...mapGetters({
      isSubscribed: 'auth/isSubscribed',
      isLoggedIn: 'auth/isLoggedIn',
    }),

    parsedPlans() {
      const now = new Date()

      return this.plans.map((p) => {
        p.endDateTime = new Date(p.endDateTime)
        return {
          ...p,
          offer: !!p.offer && p.endDateTime.getTime() - now.getTime() > 0,
        }
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.price-opacity
  opacity: 0.8
.strike
  text-decoration: line-through
.pricing
  border-radius: 10px
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)
  position: relative

  margin: 12px 12px
  border: thin solid #9e9e9e !important

  @media screen and (max-width: 960px)
    margin: 12px 0
    h2
      font-size: 19px
  .pricing__vip
    font-size: 10px
    padding: 1px 6px
    font-weight: bold
    letter-spacing: 0.2em
    border-radius: 5px
    text-transform: uppercase
  .pricing__months
    opacity: 0.8
  .pricing__price
    .plan-money
      font-size: 38px
      line-height: 38px
      margin-right: 6px
      margin-left: 2px
      font-weight: 900
    h3
      font-size: 19px
      line-height: 21px
    span
      font-size: 14px
  .pricing__price--before
    opacity: 0.6

    .plan-money
      text-decoration: line-through
      font-size: 28px
      margin-right: 6px
      margin-left: 2px
      font-weight: 900
    h3
      text-decoration: line-through
      font-size: 16px
      line-height: 18px
    span
      text-decoration: line-through
      font-size: 12px
  .features
    list-style-type: none
    li
      margin-bottom: 12px
    .feature__box
      cursor: default
      .feature__dashed
        border-bottom: 1px dashed

      .feature__icon
        margin-right: 7px
        margin-bottom: 6px

      .feature__icon__white
        border-radius: 100%
        width: 26px
        height: 26px

  .pricing__body
    padding: 10px
  .pricing__actions
    .VBtn
      font-weight: 700
.pricing__small
  .pricing__body
    padding: 5px
.pricing__big
  .pricing__body
    padding: 20px

@media (max-width: 900px)
  .pricing__container
    flex-direction: column
    .pricing
      width: 100% !important
  .pricing__big
    .pricing__body
      padding: 820
</style>
