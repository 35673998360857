<template>
  <div class="emptystate">
    <img alt="Sem resultados" :src="image" />
    <h2 v-text="title"></h2>
    <p v-text="subtitle"></p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    image: String,
  },
}
</script>

<style lang="sass" scoped>
.emptystate
  text-align: center
  background-color: white
  border-radius: 10px
  padding: 48px 0
  position: relative
  z-index: 10
  img
    max-height: 190px
    margin-bottom: 12px
</style>
