<template>
  <v-card
    class="item d-flex flex-column"
    outlined
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <VListItem class="" three-line>
      <VListItemContent>
        <div class="overline">{{ title }}</div>
        <h1 v-if="!loading && !$slots.content" class="mb-1">{{ valueStr }}</h1>
        <v-skeleton-loader v-if="loading" type="button"></v-skeleton-loader>
        <VListItem-subtitle v-if="!$slots.content" class="">{{
          subtitle
        }}</VListItem-subtitle>
        <div v-if="!loading">
          <slot name="content"></slot>
        </div>
      </VListItemContent>

      <VListItemAvatar
        v-if="!$slots.content"
        tile
        size="80"
        class="avatar mx-0"
      >
        <img :src="image" />
      </VListItemAvatar>
    </VListItem>

    <VCardActions v-if="!!this.$slots.buttons" class="buttons grey lighten-5">
      <slot name="buttons"> </slot>
    </VCardActions>
  </v-card>
</template>

<script>
// eslint-disable vue/require-default-prop
export default {
  props: {
    image: String,
    title: String,
    subtitle: String,
    value: Number,
    loading: Boolean,
    currency: Boolean,
  },

  computed: {
    valueStr() {
      if (this.currency) {
        return (
          'R$' +
          parseFloat(this.value).toLocaleString({
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        )
      } else {
        return this.value
      }
    },
  },
}
</script>

<style lang="sass">
.item
  height: 100%
  .avatar
    height: 100%
  img
    width: 40px
    height: 40px
  .buttons
    margin-top: auto !important
    align-self: flex-end
</style>
