<template>
  <div class="payment-result">
    <VContainer>
      <div class="d-flex justify-center">
        <div class="payment-success">
          <PaymentPage :payment="payment" @payment="(p) => (payment = p)" />
        </div>
      </div>
    </VContainer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaymentPage from '~/components/checkout/PaymentPage.vue'

export default {
  components: {
    PaymentPage,
  },

  layout(context) {
    console.log(context)
    return context.query.checkout ? 'default' : 'app'
  },

  async asyncData({ app, error, params }) {
    try {
      const { data } = await app.$axios.get('/billing/payment/' + params.id)

      return {
        payment: data,
      }
    } catch (e) {
      return error({
        statusCode: 404,
        message: 'Pagamento não encontrado',
      })
    }
  },

  mounted() {},

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    title() {
      if (!this.currentUser.fullname) return 'Olá!'

      const n = this.currentUser.fullname.split(' ')[0].toLowerCase()

      return 'Olá, ' + n.charAt(0).toUpperCase() + n.substring(1)
    },
  },
}
</script>

<style lang="sass" scoped>
.payment-success
  max-width: 100%
  width: 600px
  padding-bottom: 42px
</style>
