<template>
  <client-only>
    <div class="write">
      <div class="d-flex write__container">
        <textarea
          ref="area"
          v-model="text"
          v-resize="onResize"
          :rows="this.$vuetify.breakpoint.xsOnly ? 90 : 40"
          maxlength="3400"
          class="write__area"
          :readonly="readonly"
          @keyup="onKeyDown($event)"
          @blur="onBlur"
          @click="selectionArea"
          @keydown.prevent.stop.tab="onTab"
          @input="onInput"
        >
        </textarea>
      </div>
    </div>
  </client-only>
</template>

<script>
export default {
  props: {
    value: String,
    readonly: Boolean,
  },

  data() {
    return {
      text: '',
      selectedLine: 1,
      created: false,
    }
  },

  computed: {
    finalText() {
      if (this.readonly) {
        return this.text.replace(/\t/g, '&emsp;')
      } else {
        return this.text
      }
    },

    linesCount() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return [...Array(90)].map((x, i) => {
          const n = i + 1
          return n % 2 !== 0 ? n / 2 + 0.5 : ''
        })
      } else {
        return 40
      }
    },
  },

  created() {
    if (this.value) {
      const lines = this.value.split('\n').length
      this.text = this.value

      for (let i = 0; i < 29 - lines; i++) {
        this.text += '\n'
      }
    } else {
      for (let i = 0; i < 29; i++) {
        this.text += '\n'
      }
    }
    this.created = true
  },

  mounted() {
    this.$nextTick(() => {
      // this.$refs.area.selectionEnd = 0
      // this.$refs.area.focus()
      // this.onResize()
    })
  },

  methods: {
    onBlur() {
      this.selectedLine = null
      this.$emit('blur')
    },

    onResize() {
      this.areaWidth = this.$refs.area.offsetWidth
    },

    onInput() {
      if (!this.created) return

      const lines = this.text.split('\n')

      const selectionEnd = parseInt(this.$refs.area.selectionEnd)

      let tabsAdded = 0
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i]
        if (!!line && !line.startsWith('\t')) {
          lines[i] = '\t' + line
          tabsAdded += 1
        }
      }

      this.$nextTick(() => {
        this.$refs.area.selectionEnd = selectionEnd + tabsAdded
      })

      this.text = lines.join('\n')

      this.selectionArea()
      this.$emit('input', this.text)
    },

    selectionArea() {},

    onTab(ev) {
      const start = parseInt(this.$refs.area.selectionStart)
      this.text = this.text.slice(0, start) + '\t' + this.text.slice(start)
      this.$nextTick(() => {
        this.$refs.area.selectionEnd = start + 1
      })
    },

    onKeyDown(ev) {
      const key = ev.key

      this.$refs.area.scrollTop = 0
      switch (key) {
        case 'ArrowUp':
          this.selectionArea()
          break
        case 'ArrowDown':
          this.selectionArea()
          break
        case 'ArrowLeft':
          this.selectionArea()
          break
        case 'ArrowRight':
          this.selectionArea()
          break
      }
    },
  },
}
</script>
<style lang="sass">
.write
  background-color: white
  border-radius: 10px
  padding: 16px 40px
  position: relative
  .write__container
    background: linear-gradient(transparent, transparent 39px,#949494 39px)
    background-size: 40px 40px
    position: relative
    .write__lines
      padding-right: 5px
      .write__line__mark
        user-select: none
        transition:0.2s ease all
        width: 15px
        margin-right: 6px
        text-align: right
        display: flex
        align-items: center
        justify-content: flex-end
        height: 34px
        margin-top: 6px
        font-weight: bold
        font-size: 22px
      .write__line__slider
        width: 100%
        height: 1px
        background-color: transparent
        position: absolute
        left: 0
        margin-top: 17px
        z-index: -1
        &::before, &::after
          content: ''
          position: absolute
          height: 2px
          width: 0
          transition: 0.2s ease all
          background-color: #0DBC81
        &::before
          left:50%
        &::after
          right: 50%
      .write__line__slider--active
        z-index: 1
        &::before, &::after
          width: 50%
          z-index: 1
    .write__area
      overflow: hidden !important
      overflow-y: hidden !important
      margin-top: 4px
      width: 100%
      text-align: justify !important
      resize: none
      outline: none !important
      font-size: 18px
      line-height: 40px
      -moz-tab-size : 2
      -o-tab-size : 2
      tab-size : 2

@media (max-width: 1260px)
  .write
    padding: 12px 12px
    .write__container
      .write__lines
        .write__line__mark
          font-size: 16px
      .write__area
        font-size: 16px

@media (max-width: 960px)
  .write
    padding: 12px 6px
    .write__container
      .write__area
        font-size: 16px
@media (max-width: 600px)
  .write
    margin: 0 -12px
    padding: 12px
</style>
